import React, { useState, useEffect, useRef } from 'react';
import { useGlobalSelectedId, onSetActiveIndicator, onSetActiveDBModuleId, onSetActiveModuleId, useGlobalActivePage } from '../../data/StatusStore';
import { d2gRound } from '../utilities/Utilities';
import Colors from '../utilities/Colors';
import ListMeta from './ListMeta';

export interface ListChartProps {
  options: any;
  data: any;
  width: number;
  height: number;
  colorClass: string;
}

const MARGIN_X = 0;
const MARGIN_Y = 15;

const List: React.FC<ListChartProps> = ({ options, data, width, height, colorClass }) => {
  const [preppedData, setPreppedData] = useState<any>([]);
  const [preppedLabelData, setPreppedLabelData] = useState<any>({});
  const [hoveredIndicator, setHoveredIndicator] = useState<any>(null);
  const [tooltip, setTooltip] = useState<{ x: number; y: number; obj: any } | null>(null); // Tooltip state
  const [listHeight, setListHeight] = useState<number>(27.5); // Set a fixed height for each list
  const [globalMaxValue, setGlobalMaxValue] = useState<number>(0);
  const selectedId = useGlobalSelectedId();
  const activePage = useGlobalActivePage();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({ width, height });

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        if (!entries || entries.length === 0) return;
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        if (containerRef.current) {
          resizeObserver.unobserve(containerRef.current);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (selectedId) {
      prepData();
    }
  }, [selectedId]);

  useEffect(() => {
    console.log("G011825 options", options);
    console.log("G011825 options.variables[0].sort[Shape Role]", options.variables[0].sort["Shape Role"]);
  }, [options]);

  const prepData = () => {
    if (selectedId) {
      const _filteredData = options.variables.filter((v: any) => !v.sort.Tooltip_only);
      const _tooltipData = options.variables.filter((v: any) => v.sort.Tooltip_only);
      const _globalMaxValue = Math.max(
        ...Object.values(data.dataJSON).flatMap((d: any) => {
          return _filteredData.map((v: any) => {
            const value = d[v.variable];
            return isNaN(value) ? 0 : value; // Filter out non-numeric values
          });
        })
      );
      setGlobalMaxValue(_globalMaxValue);
      const _preppedData = _filteredData.map((v: any) => {
        const rawValue = data.dataJSON[selectedId][v.variable];
        const isNA = rawValue === undefined || rawValue === null || rawValue === "NA";
        const value = !isNA && !isNaN(rawValue) ? rawValue : 'N/A';
        const deviation = !isNA && !isNaN(rawValue) ? data.meta.avg[v.variable] - rawValue : 0;

        let ttVariable = '';
        _tooltipData.forEach((s: any) => {
          if (s.sort.Tooltip_only === v.variable) {
            ttVariable = s.variable;
          }
        });

        const tooltip = data.dataJSON[selectedId][ttVariable];
        const presentableTooltip = tooltip
          ? d2gRound(tooltip, { DESCRIPTOR: data.meta["DESCRIPTOR"][ttVariable] })
          : d2gRound(value, { DESCRIPTOR: data.meta["DESCRIPTOR"][v.variable] });

        const presentableLabel = tooltip
          ? data.meta["DISPLAY_NAME"][ttVariable].split("(")[0]
          : data.meta["DISPLAY_NAME"][v.variable].split("(")[0];

        return {
          name: v.label,
          deviation: isNA ? 0 : deviation,
          value,
          tooltip: presentableTooltip,
          tooltipLabel: presentableLabel,
          avg: data.meta.avg[v.variable],
          variable: v.variable,
          index: data.keyIndexDataArray[v.variable] ? data.keyIndexDataArray[v.variable][selectedId].index : 0,
          na: data.meta.na_count[v.variable],
          len: data.dataArray[v.variable] ? data.dataArray[v.variable].length : 0,
        };
      }).sort((a: any, b: any) => (b.value !== 'N/A' ? b.value : 0) - (a.value !== 'N/A' ? a.value : 0)); // Sort descending by value

      const _preppedLabelData: { [key: string]: any } = {};
      _tooltipData.forEach((v: any) => {
        if (v.sort.Tooltip_only && v.sort.Tooltip_only !== "") {
          _preppedLabelData[v.sort.Tooltip_only] = {
            name: v.sort.DISPLAY_NAME,
            value: data.dataJSON[selectedId][v.variable],
            variable: v.variable,
          };
        }
      });

      setPreppedData(_preppedData);
      setPreppedLabelData(_preppedLabelData);
    }
  };

  useEffect(() => {
    setListHeight(27.5); // Set a fixed height for each bar
  }, [preppedData]);
  
  const maxValue = Math.max(...preppedData.map((d: any) => d.value));

  // Adjust the list scale to use the global max value
  const listScale = (value: number) => {
    //console.log("B112824 preppedData", preppedData);
    //console.log("B112824 maxValue", maxValue);
    //console.log("B112824 globalMaxValue", globalMaxValue);
    const maxWidth = dimensions.width - (2 * MARGIN_X);
    //return ((value / maxValue) * maxWidth) / 2.5;
    return ((value / globalMaxValue) * maxWidth) / 2.5;
  };

  const onClicked = (variable: string) => {
    const hoveredVar = options.variables.find((v: any) => v.variable === variable);
    setHoveredIndicator(hoveredVar);
    if (activePage !== "dashboard") {
      onSetActiveModuleId(options.variables[0].sort.moduleId);
    }else{
      onSetActiveDBModuleId(options.variables[0].sort.moduleId);
    }
    onSetActiveIndicator(variable);
  };

  // Handle tooltip logic when hovering
  const handleMouseEnter = (event: React.MouseEvent<SVGRectElement>, obj: any) => {
    const { nativeEvent } = event;
    const offsetX = nativeEvent.offsetX;
    const offsetY = nativeEvent.offsetY;
    
    setTooltip({
      x: offsetX,
      y: offsetY,
      obj,
    });
  };

  const handleMouseMove = (event: React.MouseEvent<SVGRectElement>) => {
    const { nativeEvent } = event;
    const offsetX = nativeEvent.offsetX;
    const offsetY = nativeEvent.offsetY;

    setTooltip((prev) => prev && {
      ...prev,
      x: offsetX,
      y: offsetY,
    });
  };

  const handleMouseLeave = () => {
    setTooltip(null);
  };

  const fontSize = 12;
  const fontSize2 = 12;
  const labelWidth = 250;
  const chartWidth = `calc(100% - ${(labelWidth * 2 ) - 50}px)`;

  return (
    <>
    <div
      style={{
        fontWeight: '700',
        fontSize: '16px',
        paddingBottom: '10px', 
      }}
    >
      {options.variables[0].sort["Shape Role"]}
    </div>
    <div ref={containerRef} style={{ width: '100%', height: '100%', position: 'relative', display: 'flex', alignItems: 'flex-start', paddingRight: "0px" }}>
      {/* Labels on the left */}

      <div style={{
        marginLeft: '0px', 
        marginRight: '0px', 
        fontSize: fontSize2, 
        lineHeight: `${fontSize2 + 2 }px`,
        width: '100%',//`${labelWidth}px`,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}>
        {preppedData.map((d: any, i: number) => (
        (i < 5)
          ? <div key={`info-${i}`}
            style={{
              fontSize: fontSize2,
              lineHeight: 0.95,
              display: "flex",
              justifyContent: "space-between",
              height: `${listHeight}px`,
              marginRight: "15px",
              cursor: 'pointer',
            }}
            onClick={() => onClicked(d.variable)} // Set hovered indicator on click
          >
            <div style={{ width: "100%", alignContent: "center", paddingRight: "0px", display: "inline-flex", fontSize: '13px', fontWeight: 'bold'}}>
              <div style={{ width: "20px", fontWeight: 'bold'}}>{`${i + 1}.`}&nbsp;&nbsp;</div>
              <div>{`${d.name.split("(")[0].replace("Industry Category: ", "").replace("Occupational Category: ", "").replace(" among adults aged >=18 years", "").replace(" among adults ages >=18 years", "").split("(")[0]}`}
                <span style={{ 
                              fontSize:"12px",
                              fontWeight:"normal",
                              color:"#9E9C9C",
                          }}
                >{d.name.split("(")[0].replace("Industry Category: ", "").replace("Occupational Category: ", "").replace(" among adults aged >=18 years", "").replace(" among adults ages >=18 years", "").split("(")[1] ? `(${d.name.split("(")[0].replace("Industry Category: ", "").replace("Occupational Category: ", "").replace(" among adults aged >=18 years", "").replace(" among adults ages >=18 years", "").split("(")[1]}` : ""}</span></div>
              { data.meta.provenance[d.variable] !== "NA"
                    ? <span
                      style={{ 
                        color:"#F26631",
                      }}
                    > *</span> 
                    : null
                  }
            </div>
          </div>
        : null
        ))}
      </div>

      {/* Bar chart 
      <div style={{
        fontSize,
        lineHeight: `${fontSize + 2}px`,
        display: 'flex',
        width: "50px",
        position: "relative"
      }}>
        <svg width="100%" height={listHeight * preppedData.length} style={{ display: "inline-block" }}>
          <g transform={`translate(${MARGIN_X}, 0)`}>
            {preppedData.map((d: any, i: number) => (
              <g key={`list-${i}`} transform={`translate(0, ${i * listHeight})`}>
                <text
                  x={5}
                  y={listHeight / 2}
                  alignmentBaseline="middle"
                  fill={Colors.processBlack}
                  fontSize={fontSize}
                  pointerEvents="none"
                >
                  {d.value === 'N/A' ? 'N/A' : d2gRound(d.value, { DESCRIPTOR: data.meta["DESCRIPTOR"][d.variable] })}
                </text>
              </g>
            ))}
          </g>
        </svg>
      </div>
      <div style={{
        fontSize,
        lineHeight: `${fontSize + 2}px`,
        display: 'flex',
        width: chartWidth,
        position: "relative"
      }}>
        <svg width="100%" height={listHeight * preppedData.length} style={{ display: "inline-block" }}>
          <g transform={`translate(${MARGIN_X}, 0)`}>
            {preppedData.map((d: any, i: number) => (
              <g key={`list-${i}`} transform={`translate(0, ${i * listHeight})`}>
                <rect
                  x={0}
                  y={0}
                  width={d.value === 'N/A' ? 0 : listScale(d.value)} // Handle N/A by setting width to 0
                  height={listHeight - 2}
                  fill={Colors.getColorQuintile(d.index, d.na, d.len, `${colorClass}_variant`)}
                  onClick={() => onClicked(d.variable)} // Set hovered indicator on click
                  onMouseEnter={(e) => handleMouseEnter(e, d)}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseLeave}
                  style={{ cursor: 'pointer', transition: 'width 0.5s ease, fill 0.5s ease' }}
                />
                <line
                  x1={listScale(d.avg)}
                  y1={0}
                  x2={listScale(d.avg)}
                  y2={listHeight - 2}
                  stroke="#9E9C9C"
                  strokeWidth={1}
                  strokeDasharray="4"
                />
              </g>
            ))}
          </g>
        </svg>

        {tooltip && (
          <div
            style={{
              position: 'absolute',
              top: tooltip.y - 60,
              left: tooltip.x - 75,
              backgroundColor: '#fff',
              border: '2px solid #ccc',
              borderRadius: '6px',
              padding: '8px 12px',
              pointerEvents: 'none',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
              zIndex: 10,
              color: '#333',
              width:'150px',
              height:'50px',
              textAlign: 'left',
              wordBreak: 'break-word', // Ensures long words or strings wrap correctly              
              whiteSpace: 'normal', // Allows text to wrap
              maxWidth: '150px', // Set a max width for the tooltip
              lineHeight: '0.9',
            }}
          >
            <b>{tooltip.obj.tooltip}</b> <span style={{ fontSize: '10px', color: '#666' }}>{tooltip.obj.tooltipLabel}</span>
            <div
              style={{
                position: 'absolute',
                bottom: '-7px',
                left: '45%',
                width: '12px',
                height: '12px',
                backgroundColor: '#fff',
                borderLeft: '2px solid #ccc',
                borderBottom: '2px solid #ccc',
                transform: 'rotate(-45deg)',
                boxShadow: '-1px 1px 2px rgba(0, 0, 0, 0.1)',
              }}
            />
          </div>
        )}
      </div>*/}
    </div>
      <div>
        {options.variables[0].sort["Shape Role"]}
      </div>
    </>
  );
};

export default List;
