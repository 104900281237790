import React, { useState, useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { onHoverHistogram, onClickHistogram, onSetActiveIndicator, useGlobalActiveGeography, onSetActiveDBModuleId, onSetActiveModuleId, useGlobalActivePage } from '../../data/StatusStore';
import { useGlobalSwarmCalibration } from '../../data/GlobalStore';

interface DataItem {
  id: string | null;
  value: number;
  x?: number;
  y?: number;
}

interface MobileSwarmProps {
  data: any;
  dataArray: DataItem[];
  histMax: number;
  histMin: number;
  histNA: number;
  histLength: number;
  chartId: string;
  indicator: any;
  colorClass: string;
  labelOnly: any;
  options: any;
  selectedId: string | null;
}

const getQuintileColor = (value: number, min: number, max: number) => {
  const scale = d3.scaleQuantize<string>()
    .domain([min, max])
    .range(['#FFF2CC', '#F4C75E', '#E39B2F', '#C97815', '#8D4A04']);

  return scale(value);
};

const MobileSwarm: React.FC<MobileSwarmProps> = ({
  dataArray,
  histMax,
  histMin,
  chartId,
  selectedId,
}) => {
  const [nodes, setNodes] = useState<DataItem[]>([]);
  const [hoveredNode, setHoveredNode] = useState<DataItem | null>(null);
  const [clickedNode, setClickedNode] = useState<DataItem | null>({ id: selectedId, value: 0 });
  const containerRef = useRef<HTMLDivElement>(null);
  const [svgSize, setSvgSize] = useState({ width: 350, height: 500 });

  useEffect(() => {
    const updateSize = () => {
      if (containerRef.current) {
        const newWidth = containerRef.current.clientWidth;
        const newHeight = newWidth / 0.7; // Maintains aspect ratio
        setSvgSize({ width: newWidth, height: newHeight });
      }
    };

    updateSize();
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    if (svgSize.width > 0) {
      const swarmData = calculateSwarmPositions([...dataArray], histMin, histMax, svgSize.width, svgSize.height);
      setNodes(swarmData);
    }
  }, [dataArray, svgSize]);

  useEffect(() => {
    if (nodes.length > 0) {
      const selectedNode = nodes.find(node => node.id === selectedId);
      if (selectedNode) {
        setClickedNode(selectedNode);
      }
    }
  }, [nodes]);

  useEffect(() => {
    if (clickedNode) {
      onClickHistogram(clickedNode.id)
    }
  }, [clickedNode]);

  function calculateSwarmPositions(data: DataItem[], min: number, max: number, plotWidth: number, plotHeight: number): DataItem[] {
    const pointRadius = plotWidth * 0.02; // Increased point size slightly
    const spacing = pointRadius * 0.6; // Reduced spacing for denser clusters
    const collisionRadius = pointRadius * 2 + spacing;
    const leftMargin = plotWidth * 0.08; // Reduced left margin to bring points closer to axis
    const rightMargin = plotWidth * 0.15; // Keep some space for tooltips
    const centerX = leftMargin + (plotWidth - leftMargin - rightMargin) / 2;
    const yScale = d3.scaleLinear().domain([min, max]).range([plotHeight - 40, 40]);

    let lastPoint: DataItem | null = null;
    let direction = 1;
    let run = 0;

    return data.map((item) => {
      const y = yScale(item.value);
      let x = centerX;

      if (lastPoint && Math.abs(lastPoint.y! - y) < collisionRadius) {
        x = centerX + (run * collisionRadius) * direction * 1.5; // Wider spread factor
        if (direction === 1) run++;
        direction *= -1;
      } else {
        direction = 1;
        run = 0;
        lastPoint = { ...item, x, y };
      }

      return { ...item, x, y };
    });
  }

  const leftMargin = svgSize.width * 0.08; // Reduced to bring labels closer
  const rightMargin = svgSize.width * 0.15;
  const pointRadius = svgSize.width * 0.02;
  const yScale = d3.scaleLinear().domain([histMin, histMax]).range([svgSize.height - 40, 40]);
  const yTicks = yScale.ticks(5);

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(176, 213, 223, 0.40)' }}>
      <div className="title">
      Human Development Index
      </div>
      <svg
        width="100%"
        height="100%"
        viewBox={`0 0 ${svgSize.width} ${svgSize.height}`}
        preserveAspectRatio="xMidYMid meet"
      >
        {/* Y-Axis */}
        <g>
          {/*<line x1={leftMargin} y1={svgSize.height - 40} x2={leftMargin} y2="40" stroke="black" strokeWidth="1.5" />*/}
          {yTicks.map((tick, index) => (
            <g key={index}>
              <text x={leftMargin - 22.5} y={yScale(tick) + 7.5} fontSize="25px" textAnchor="end">
                {tick}
              </text>
              <line
                x1={leftMargin - 20}
                y1={yScale(tick)}
                x2={leftMargin}
                y2={yScale(tick)}
                stroke="black"
                strokeWidth="1"
              />
            </g>
          ))}
        </g>

        {/* Swarm Dots */}
        {nodes.map((node, i) => (
          <circle
            key={`${node.id}_${chartId}`}
            cx={node.x}
            cy={node.y}
            r={pointRadius}
            fill={getQuintileColor(node.value, histMin, histMax)}
            //fill={hoveredNode?.id === node.id 
            //  ? '#E45756' 
            //  : clickedNode?.id === node.id 
            //    ? '#E45756'
            //    : getQuintileColor(node.value, histMin, histMax)}
            //stroke={hoveredNode?.id === node.id ? 'black' : 'none'}
            stroke={hoveredNode?.id === node.id 
              ? '#E45756' 
              : clickedNode?.id === node.id 
                ? 'black'
                : 'black'}
            //strokeWidth={hoveredNode?.id === node.id ? 2 : 0}
            strokeWidth={hoveredNode?.id === node.id 
              ? 6
              : clickedNode?.id === node.id
                ? 6 
                : 1}
            onMouseEnter={() => setHoveredNode(node)}
            onMouseLeave={() => setHoveredNode(null)}
            onClick={() => setClickedNode(node)}
            style={{ cursor: 'pointer' }}
          />
        ))}

        {/* (center) Axis Line
        <line
          x1={leftMargin + (svgSize.width - leftMargin - rightMargin) / 2}
          y1={svgSize.height - 40}
          x2={leftMargin + (svgSize.width - leftMargin - rightMargin) / 2}
          y2="40"
          stroke="black"
          strokeWidth="1.5"
        /> */}
        {/* Tooltip */}
        {clickedNode && clickedNode.x !== undefined && clickedNode.y !== undefined && (
          <>
            <line
              x1={clickedNode.x}
              y1={clickedNode.y}
              x2={svgSize.width - rightMargin - 20}
              y2={clickedNode.y}
              stroke="black"
              strokeWidth="1"
            />
            <text
              x={svgSize.width - rightMargin - 15}
              y={clickedNode.y + 12.5}
              fontSize="45px"
              fontWeight="bold"
              fill="black"
              style={{ textAnchor: 'start' }}
              //opacity={0.5}
            >
              {clickedNode.value.toFixed(2)} {/*d2gRound(label.tickValue, { DESCRIPTOR: DESCRIPTOR, countDecimalOverride: 0 })*/}
            </text>
          </>
        )}
        {/* Tooltip */}
        {hoveredNode && hoveredNode.x !== undefined && hoveredNode.y !== undefined && (
          <>
            <line
              x1={hoveredNode.x}
              y1={hoveredNode.y}
              x2={svgSize.width - rightMargin - 20}
              y2={hoveredNode.y}
              stroke="#E45756"
              strokeWidth="1"
            />
            <text
              x={svgSize.width - rightMargin - 15}
              y={hoveredNode.y + 12.5}
              fontSize="45px"
              fontWeight="bold"
              fill="#E45756"
              style={{ textAnchor: 'start' }}
            >
              {hoveredNode.value.toFixed(2)} {/*d2gRound(label.tickValue, { DESCRIPTOR: DESCRIPTOR, countDecimalOverride: 0 })*/}
            </text>
          </>
        )}
        
      </svg>
      <div className="description">
      The Human Development Index is a single indicator of well-being based on: <span>Life Expectancy</span>, <span>Educational Attainment</span>, and <span>Income</span>
      </div>
    </div>
  );
};

export default MobileSwarm;
