import React, { useState, useEffect, FC } from 'react';
import { MapContainer, TileLayer, GeoJSON, useMap } from 'react-leaflet';
import L from 'leaflet';
import { feature } from 'topojson-client';
import 'leaflet/dist/leaflet.css';
import { GeoJsonObject, FeatureCollection } from 'geojson';
import { useData } from '../../../data/DataContext';
import Colors from '../../utilities/Colors';
import * as turf from 'turf';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { onHoverHistogram, onClickHistogram, useGlobalHoveredId, useGlobalSelectedId, useGlobalActiveGeography, 
    useGlobalActiveIndicator, useGlobalBivariateIndicator, useGlobalBivariateOverRide, useGlobalSearchLatLng,
    useGlobalDBModuleId, useGlobalModuleId,  useGlobalActiveDBModuleId, useGlobalActiveModuleId, useGlobalActivePage } from '../../../data/StatusStore';

interface DataItem {
    id: string;
    value: any;
}

interface ChoroplethLayerProps {
    _dataArray: any;
    _keyIndexDataArray: any;
    dataJSON: any;
    dataMeta: any;
    activeGeography: string;
    //activeIndicator: string;
    chartId: string;
    mapType: string;
    unGroupedModules?: any;
    // onHover: (e: any) => void;
    // onClick: (e: any) => void;
    // hoveredId: string | null;
    // selectedId: string | null;
}

export const ChoroplethLayer: FC<ChoroplethLayerProps> = (
    { _dataArray, _keyIndexDataArray, dataJSON, dataMeta, activeGeography, /*activeIndicator,*/ chartId, mapType, unGroupedModules
        /*, onHover, onClick, hoveredId, selectedId*/ }
) => {
    const hoveredId = useGlobalHoveredId();
    const selectedId = useGlobalSelectedId();
    const activeIndicator = useGlobalActiveIndicator();
    const bivariateIndicator = useGlobalBivariateIndicator();
    const bivariateOverRide = useGlobalBivariateOverRide();
    const globalDBModuleId = useGlobalDBModuleId();
    const globalSearchLatLng = useGlobalSearchLatLng();
    const globalModuleId = useGlobalModuleId();
    const globalActiveDBModuleId = useGlobalActiveDBModuleId();
    const globalActiveModuleId = useGlobalActiveModuleId();
    const activePage = useGlobalActivePage();
    //const activeGeography = useGlobalActiveGeography();

    const [activeGeoJSON, setActiveGeoJSON] = useState<FeatureCollection | null>(null);
    const [selectedGeoJSON, setSelectedGeoJSON] = useState<FeatureCollection | null>(null);
    const [mapId, setMapId] = useState<number>(0);
    //const [maxValue, setActiveGeoJSON] = useState<FeatureCollection | null>(null);

    //let bivariateIndicator = "age_pyramid_total";

    const {
        geoJsonDataPUMA, geoJsonDataTract, geoJsonDataNTA, geoJsonDataBoro,
        isLoadingTopology, error, dataPoint,
    } = useData();

    useEffect(() => {
        // nothing for now
    }, []);

    useEffect(() => {
        // nothing for now
        console.log("B020425 dataPoint", dataPoint);
    }, [dataPoint]);

    const map = useMap()
    useEffect(() => {
        console.log("A032725 selectedId", selectedId);
        console.log("A032725 activeGeoJSON", activeGeoJSON);
        if (selectedId && activeGeoJSON && activeGeoJSON.features) {
            const feature = activeGeoJSON.features.find((f: any) => f.properties.GEOID20 == selectedId);
            console.log("A032725 feature", feature);
            if (feature && ((map.getZoom() > 11)|| (activeGeography === "Boro"))) {
                const center = turf.center({
                    type: 'FeatureCollection',
                    features: [feature]
                });
                if (map && center) {
                const [lng, lat] = center.geometry.coordinates;
                let zoomTo = (activeGeography === "Boro") ? 10 : map.getZoom()
                map.flyTo([lat, lng], zoomTo);
                }
            }
        }
    }, [selectedId, activeGeoJSON]);

    useEffect(() => {
        console.log("A032825 globalSearchLatLng", globalSearchLatLng);
        console.log("A032825 activeGeoJSON", activeGeoJSON);

        let marker: L.CircleMarker | null = null;
        let label: L.Popup | null = null;

        if (globalSearchLatLng && activeGeoJSON && activeGeoJSON.features) {
            let optimalZoom = 11;
            if (activeGeography === "PUMA") {
                optimalZoom = 14;   
            } else if (activeGeography === "CD") {
                optimalZoom = 14;
            } else if (activeGeography === "NTA") {
                optimalZoom = 14;
            } else if (activeGeography === "Tract") {
                optimalZoom = 13;
            } else if (activeGeography === "Boro") {
                optimalZoom = 10;
            }
            if (map) {
                map.flyTo([globalSearchLatLng.lat, globalSearchLatLng.lng], optimalZoom);
            }
        }

        if (globalSearchLatLng && globalSearchLatLng.lat && globalSearchLatLng.lng) {
            if (!map.getPane('searchPane')) {
                map.createPane('searchPane');
                map.getPane('searchPane')!.style.zIndex = '650'; // Set zIndex for the custom pane
            }
            if (!map.getPane('searchPopPane')) {
                map.createPane('searchPopPane');
                map.getPane('searchPopPane')!.style.zIndex = '1000'; // Set zIndex for the custom pane
            }

            // Remove any existing marker and label before adding a new one
            if (marker) {
                map.removeLayer(marker);
            }
            if (label) {
                map.closePopup(label);
            }

            marker = L.circleMarker([globalSearchLatLng.lat, globalSearchLatLng.lng], {
                pane: 'searchPane', // Add marker to the custom pane
                radius: 8,
                color: '#000',
                fillColor: '#000',
                fillOpacity: 0.9,
            });

            label = L.popup({
                pane: 'searchPopPane', // Add popup to the custom pane
                closeButton: false,
                autoClose: false,
                closeOnClick: false,
                className: 'custom-search-label',
                offset: L.point(0, 0), // Adjust the offset to position the popup above the point
            })
                .setLatLng([globalSearchLatLng.lat, globalSearchLatLng.lng])
                .setContent(`
                    <div style="display: flex; align-items: center; line-height: 20px; justify-content: space-between;">
                        <span>${globalSearchLatLng.label || 'Search Result'}</span>
                        <button style="margin-left: 8px; background: none; border: none; color: #000000; cursor: pointer; font-size:20px; margin-bottom: 5px;" onclick="removeMarker()">x</button>
                    </div>
                `);

            marker.addTo(map);
            label.openOn(map);

            // Function to remove marker and label
            (window as any).removeMarker = () => {
                if (marker) {
                    map.removeLayer(marker);
                    marker = null;
                }
                if (label) {
                    map.closePopup(label);
                    label = null;
                }
            };
        }

        // Cleanup on unmount
        return () => {
            if (marker) {
                map.removeLayer(marker);
            }
            if (label) {
                map.closePopup(label);
            }
        };
    }, [globalSearchLatLng]);

    useEffect(() => {
        /*console.log("B020425 _dataArray", _dataArray);
        console.log("B020425 _keyIndexDataArray", _keyIndexDataArray );
        console.log("B020425 activeIndicator", activeIndicator);
        console.log("B020425 selectedId", selectedId);
        console.log("B020425 dataPoint", dataPoint);
        console.log("B020425 dataMeta", dataMeta);
        console.log("B020425 dataMeta.DISPLAY_NAME[activeIndicator]",dataMeta.DISPLAY_NAME[activeIndicator]);
        if (dataPoint){
            console.log("B020425 dataPoint[dataMeta.DISPLAY_NAME[activeIndicator]]",dataPoint[dataMeta.DISPLAY_NAME[activeIndicator]]);
        }*/
        if (_dataArray && _keyIndexDataArray && dataMeta && unGroupedModules) {
            let intersectedIndexedObject = dataPoint 
            ? dataPoint [dataMeta.DISPLAY_NAME[activeIndicator]] 
                ? dataPoint[dataMeta.DISPLAY_NAME[activeIndicator]].intersected 
                    ? dataPoint[dataMeta.DISPLAY_NAME[activeIndicator]].intersected[activeGeography]
                        ? dataPoint[dataMeta.DISPLAY_NAME[activeIndicator]].intersected[activeGeography]
                        : null
                    : null
                : null
            : null;
            //console.log("B020425 intersectedIndexedObject", intersectedIndexedObject);
            const dataArray = _dataArray[activeIndicator] ? _dataArray[activeIndicator] : intersectedIndexedObject ? intersectedIndexedObject.sortedArray : null;
            const keyIndexDataArray = _keyIndexDataArray[activeIndicator] ? _keyIndexDataArray[activeIndicator] : intersectedIndexedObject ? intersectedIndexedObject.indexedObject : null;
            //console.log("B020425 keyIndexDataArray", keyIndexDataArray);
            let dataArray2:any = null;
            let keyIndexDataArray2:any = null;
            if (mapType === "bivariate - standard" || mapType === "bivariate - inverted") {
                dataArray2 = _dataArray[bivariateIndicator];
                keyIndexDataArray2 = _keyIndexDataArray[bivariateIndicator];
            };

            let tempGeoJSON = geoJsonDataTract;
            if (activeGeography === "PUMA" || activeGeography === "CD") {
                tempGeoJSON = geoJsonDataPUMA;
            } else if (activeGeography === "Tract") {
                tempGeoJSON = geoJsonDataTract;
            } else if (activeGeography === "NTA") {
                tempGeoJSON = geoJsonDataNTA;
            } else if (activeGeography === "Boro") {
                tempGeoJSON = geoJsonDataBoro;
            }

            if (tempGeoJSON && keyIndexDataArray) {
                tempGeoJSON.features.forEach((feature: any) => {
                    //console.log("F112724 feature.properties.GEOID20", feature.properties.GEOID20);
                    //console.log("F112724 keyIndexDataArray", keyIndexDataArray);
                    if (keyIndexDataArray[feature.properties.GEOID20]) {
                        feature.properties["value"] = keyIndexDataArray[feature.properties.GEOID20]?.value;
                        feature.properties["index"] = keyIndexDataArray[feature.properties.GEOID20]?.index;
                        if (mapType === "bivariate - standard") {
                            feature.properties["color"] = Colors.getStandardBivariateColor(
                                feature.properties.GEOID20,//keyIndexDataArray[feature.properties.GEOID20].index,
                                dataMeta.na_count[activeIndicator],
                                dataArray.length,
                                keyIndexDataArray2[feature.properties.GEOID20].index,
                                dataMeta.na_count[bivariateIndicator],
                                dataArray2.length,
                            );
                        } else if (mapType === "bivariate - inverted") {
                            feature.properties["color"] = Colors.getInvertedBivariateColor(
                                "choropleth",
                                feature.properties.GEOID20,
                                keyIndexDataArray[feature.properties.GEOID20].index,
                                dataMeta.na_count[activeIndicator],
                                dataArray.length,
                                keyIndexDataArray2[feature.properties.GEOID20].index,
                                dataMeta.na_count[bivariateIndicator],
                                dataArray2.length,
                                bivariateOverRide, 
                                keyIndexDataArray2[feature.properties.GEOID20].value, 
                                keyIndexDataArray[feature.properties.GEOID20].value 
                            );
                        } else {
                            //console.log("A010825 globalActiveModuleId", globalActiveModuleId);
                            //console.log("A010825 globalActiveDBModuleId", globalActiveDBModuleId);
                            //console.log("A010825 unGroupedModules", unGroupedModules);
                            //console.log('C020425 unGroupedModules[activePage === "explorer" ? globalActiveModuleId : globalActiveDBModuleId][0].Sort.Color', unGroupedModules[activePage === "explorer" ? globalActiveModuleId : globalActiveDBModuleId][0].Sort.Color);
                            //console.log('C020425 keyIndexDataArray[feature.properties.GEOID20].index', keyIndexDataArray[feature.properties.GEOID20].index);
                            //console.log('C020425 dataMeta.na_count[activeIndicator]', dataMeta.na_count[activeIndicator]);
                            //console.log('C020425 dataArray.length', dataArray.length);
                            
                            feature.properties["color"] = Colors.getColorQuintile(
                                keyIndexDataArray[feature.properties.GEOID20].index, 
                                dataMeta.na_count[activeIndicator] ? dataMeta.na_count[activeIndicator] : 0, 
                                dataArray.length, 
                                //dataMeta["Module Color"][activeIndicator]
                                unGroupedModules[activePage !== "dashboard" ? globalActiveModuleId : globalActiveDBModuleId][0].Sort.Color
                            );
                        }
                    }
                });
            }

            

            if (selectedId) {
                console.log("B112524 selectedId", selectedId);
                const selectedFeature = tempGeoJSON.features.find((feature: any) => feature.properties.GEOID20.toString() === selectedId.toString());
                console.log("B112524 selectedFeature", selectedFeature);
                if (selectedFeature) {
                    setSelectedGeoJSON({
                        type: 'FeatureCollection',
                        features: [selectedFeature]
                    });
                }
            }
             
            setActiveGeoJSON(tempGeoJSON);
            setMapId(mapId + 1);
        }
    }, [activeIndicator, bivariateIndicator, bivariateOverRide, activeGeography, _keyIndexDataArray, mapType, _dataArray, selectedId, unGroupedModules, dataPoint]);

    /*useEffect(() => {
        setMapId(mapId + 1);
    }, [selectedGeoJSON]);*/

    const onEachFeature = (feature: any, layer: L.Layer) => {
        /*if (feature.properties.GEOID20 === selectedId) {
            // Bring the selected polygon to the front
            (layer as L.Polygon).bringToFront();
        } else {
            // Bring the other polygons to the back to avoid overlap
            (layer as L.Polygon).bringToBack();
        }*/
        layer.on('mouseover', () => {
            //console.log("B093024 feature?.properties", feature?.properties)
            onHoverHistogram(feature?.properties.GEOID20 ? feature.properties.GEOID20 : null);
        });
        layer.on('mouseout', () => {
            //console.log("B093024 feature?.properties", feature?.properties)
            onHoverHistogram(null);
        });
        layer.on('click', () => {
            //console.log("B093024 feature?.properties", feature?.properties)
            //console.log("101224 feature", feature)
            onClickHistogram(feature?.properties.GEOID20 ? feature.properties.GEOID20 : null);
        });
    };


    const style = (feature: any) => {
        //let color = getColorQuintile()
        //console.log("C093024 feature?.properties", feature?.properties)
        //console.log("C093024 hoveredId", hoveredId)
        //console.log("C093024 selectedId", selectedId)
        return {
            //color: '#ffffff',
            //weight: 0.15,
            weight: 0.15,
            color: '#ffffff',
            fillColor: feature.properties.GEOID20 == hoveredId 
                ? Colors.highlightHover 
                : feature.properties.color,
            fillOpacity: 0.75,
            smoothFactor:0,
        };
    };

    const selectedStyle = (feature: any) => {
        return {
            weight: 3, // Thicker border for selected geometry
            color: '#000000', // Black stroke
            fillColor: 'transparent', // Transparent fill
            fillOpacity: 0.75,
        };
    };

    return (
        <>
            {activeGeoJSON && (
                <GeoJSON
                    key={`map${mapId}`}
                    data={activeGeoJSON}
                    style={style}
                    onEachFeature={onEachFeature}
                />
            )}
            {selectedGeoJSON && (
                <GeoJSON
                    key={`selected${mapId}`}
                    data={selectedGeoJSON}
                    style={selectedStyle}
                    onEachFeature={onEachFeature}
                />
            )}
        </>
    );
};
