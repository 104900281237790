import React, { useState }from 'react';
import Colors from '../utilities/Colors';
import { onHoverHistogram, useGlobalHoveredId, useGlobalSelectedId, useGlobalActiveGeography, useGlobalActiveIndicator, useGlobalBivariateIndicator } from '../../data/StatusStore';
import ExportModal from '../inputs/ExportModal';

interface DataItem {
    id: string;
    value: any; // Consider specifying a more detailed type here
}

export interface BivariateLegendProps {
  dataGeo: any;
  histMax: number;
  histNA: number;
  histLength: number; 
  chartId: string;
  height: string;
  width: string;
  mapType: string;
  activeIndicator: string;
  bivariateIndicator: string;
}

export const BivariateLegend: React.FC<BivariateLegendProps> = ({ 
    dataGeo, histMax, histNA, histLength, chartId, height, width, mapType, activeIndicator, bivariateIndicator,
}) => {

    const [isModalVisible, setModalVisible] = useState(false);
    const openModal = () => setModalVisible(true);
    const closeModal = () => setModalVisible(false);

    //let bivariateIndicator = "age_pyramid_total";

    //const activeIndicator = useGlobalActiveIndicator();
    //const bivariateIndicator = useGlobalBivariateIndicator();
    //const activeGeography = useGlobalActiveGeography();
    //console.log("E041824 BivariateLegend activeIndicator", activeIndicator);
    //console.log("E041824 BivariateLegend bivariateIndicator", bivariateIndicator);
    //console.log("E041824 BivariateLegend dataGeo?.meta.DISPLAY_NAME", dataGeo?.meta.DISPLAY_NAME);
    return (
        <div>            
            <div className="bivariate-legend-meta">
                <div className="bivariate-legend-meta-title">Bivariate Maps</div>
                {/*<div className="bivariate-legend-meta-text">The above map show the relationship between TWO variables. Redder areas show areas with higher <b>{dataGeo?.meta.DISPLAY_NAME[activeIndicator] ? dataGeo?.meta.DISPLAY_NAME[activeIndicator].split("(")[0].trim() : ""}</b> Bluer areas show areas of higher <b>{dataGeo?.meta.DISPLAY_NAME[bivariateIndicator] ? dataGeo?.meta.DISPLAY_NAME[bivariateIndicator].split("(")[0].trim() : ""}</b>, purple areas show areas that are high in both. The default coloring aims to make equal numbers of each of the 9 quadrants.</div>*/}
                <div className="bivariate-legend-meta-text">The key to the right shows the coloring and relationship between the variables. Areas with a high share of both variables appear as a blended purple color, while areas with strong contrasts (high in one variable, low in the other) show more intense red or blue.</div>
            </div>
            <div className="bivariate-legend-y">
                <div className="bivariate-legend-y-text">{dataGeo?.meta.DISPLAY_NAME[bivariateIndicator] ? dataGeo?.meta.DISPLAY_NAME[bivariateIndicator].split("(")[0] : ""}</div>
                <div className="bivariate-legend-y-arrow">
                    <div className={mapType === "bivariate - standard" ? "triangle-down" : "triangle-up"}></div>
                </div>
            </div>
            <div className="bivariate-legend-x">
                <div className="bivariate-legend-x-text">{dataGeo?.meta.DISPLAY_NAME[activeIndicator] ? dataGeo?.meta.DISPLAY_NAME[activeIndicator].split("(")[0] : ""}</div>
                <div className="bivariate-legend-x-arrow">
                    <div className={"triangle-right"/*mapType === "bivariate - standard" ? "triangle-right" : "triangle-left"*/}></div>
                </div>
            </div>
            <div className="bivariate-legend-export" id='legendExport'>
                <div>
                    <div style={{
                            width: "100%",
                            minWidth: "77px",
                            fontSize: "12px",
                            paddingRight:"2.5px",
                            paddingLeft:"2.5px",
                            lineHeight:"16px",
                            height:"20px",
                            cursor: "pointer",
                            zIndex:10,
                        }}
                        className='compare-button'
                        onClick={() => {
                            //alert(`this will begin an export`)
                            openModal();
                        }}
                    >export/share</div>
                    <ExportModal 
                        title={`${dataGeo?.meta.DISPLAY_NAME[activeIndicator] 
                            ? dataGeo?.meta.DISPLAY_NAME[activeIndicator].split("(")[0] 
                            : ""} & ${dataGeo?.meta.DISPLAY_NAME[bivariateIndicator] 
                            ? dataGeo?.meta.DISPLAY_NAME[bivariateIndicator].split("(")[0] 
                            : ""}`} 
                        displayName={dataGeo?.meta.DISPLAY_NAME[bivariateIndicator]}
                        year={dataGeo?.meta.YEAR[bivariateIndicator]}
                        isVisible={isModalVisible} 
                        onClose={closeModal} 
                        dataGeo={dataGeo}
                        activeIndicator={activeIndicator}
                        bivariateIndicator={bivariateIndicator}
                    />
                </div>
            </div>
            <div className="bivariate-legend">
            {Colors[mapType === "bivariate - standard" ? "bivariateColorSchemeStandard" : "bivariateColorScheme"].map((row, rowIndex) => (
                <div key={`row-${rowIndex}`} className="legend-row">
                {row.map((color, colorIndex) => (
                    <div
                    key={`color-${rowIndex}-${colorIndex}`}
                    className="legend-color"
                    style={{ backgroundColor: color }}
                    ></div>
                ))}
                </div>
            ))}
            </div>
        </div>
    );
};
