import React, { useState, useEffect } from 'react';
import Fuse from 'fuse.js';
import { useGlobalActiveGeography, onSetModuleId, onSetDBModuleId, useGlobalModuleId, onSetActiveModuleId, onSetActiveDBModuleId, onSetActiveIndicator, onSetSearchLatLng, useGlobalActivePage } from '../../data/StatusStore';

// 🔹 Import Stadia Maps client components from the package
import { GeocodingApi, AutocompleteRequest, Configuration } from '@stadiamaps/api';
import { active } from 'd3';

// 🔹 Synonyms Mapping (Bidirectional)
const synonymMap: { [key: string]: string[] } = {
  // "<user input>": ["<d2g synonym1>", "<d2g synonym2>", ...]
  "male": ["female", "gender"],
  "female": ["male", "gender"],
  "sex": ["gender", "male", "female"],
  "dementia": ["alzheimers"],
  "memory loss": ["alzheimers"],
  "memory": ["alzheimers"],
  "ahdi": ["american human development index"],
};

// 🔹 Expands Search Query to Include Synonyms (Case-Insensitive)
const expandQuery = (query: string): string[] => {
  // Always lowercase the user input
  const words = query.toLowerCase().split(" ");
  const expandedQueries = new Set<string>();

  // ✅ Handle multi-word synonyms first (also check the map in lowercase)
  const multiWordKey = query.toLowerCase();
  if (synonymMap[multiWordKey]) {
    synonymMap[multiWordKey].forEach(syn => expandedQueries.add(syn.toLowerCase()));
  }

  // ✅ Handle individual words for synonyms (also check each word in lowercase)
  words.forEach(word => {
    expandedQueries.add(word); // already lowercase
    if (synonymMap[word]) {
      synonymMap[word].forEach(syn => expandedQueries.add(syn.toLowerCase()));
    }
  });

  return Array.from(expandedQueries);
};

// 🔹 Preprocess Search Data into a Safe Format
const preprocessSearchData = (searchData: any) => {
  if (!searchData || typeof searchData !== 'object') return []; // Prevent errors

  return Object.keys(searchData).map((key) => ({
    key,
    directive: searchData[key]?.directive || "",
    data: searchData[key]?.data || "",
  }));
};

// 🔹 Create Stadia Maps Geocoding API Instance
// Using domain-based authorization. If needed, you can pass an API key in the Configuration.
const config = new Configuration({
  // apiKey: '', // Not required if your domain is whitelisted
});
const geocodingApi = new GeocodingApi(config);

// 🔹 Stadia Geocoding Integration using the Autocomplete endpoint
// This function appends ", New York City, NY" to the user's query, adds a bounding box to the request,
// and then filters the returned results to only include features within NYC. This extra filtering
// checks both the coordinates and, if available, a "city" or "locality" property.
const fetchStadiaGeocode = async (query: string): Promise<{ key: string; data: string; lat?: number; lng?: number; city?: string }[]> => {
  // Append "New York City, NY" to the query to further isolate results to NYC.
  const appendedQuery = `${query}, New York City, NY`;

  // Define NYC bounding box coordinates.
  const minLon = -74.25909;
  const minLat = 40.477399;
  const maxLon = -73.700181;
  const maxLat = 40.917577;

  // Build a request object that includes both the appended query and bounding box.
  // Note: We cast the object as "any" to allow extra properties since AutocompleteRequest
  // doesn't formally include "boundary".
  const request = {
    text: appendedQuery,
    boundary: {
      rect: { minLon, minLat, maxLon, maxLat }
    }
  } as any as AutocompleteRequest;

  try {
    // Make the autocomplete request via the Geocoding API instance.
    const response = await geocodingApi.autocomplete(request);
    // The response is expected to be a GeoJSON-like object with a "features" array.
    if (response && response.features && Array.isArray(response.features)) {
      const mappedResults = response.features.map((feature: any) => {
        const props = feature.properties || {};
        const geometry = feature.geometry || {};
        // Expect GeoJSON: coordinates are in [lon, lat] order.
        const coordinates = geometry.coordinates || [];
        const lng = coordinates[0] || null;
        const lat = coordinates[1] || null;
        // Extract a city/locality if available.
        const city = (props.city || props.locality || "").toLowerCase();
        return {
          key: props.label || props.name || "Unknown",
          data: props.postcode || "",
          lat,
          lng,
          city,
        };
      });
      // Filter out any results that:
      // 1. Do not have valid lat/lng,
      // 2. Fall outside the NYC bounding box,
      // 3. And if a city/locality is provided, it must include "new york".
      return mappedResults.filter(r =>
        r.lat !== null &&
        r.lng !== null &&
        r.lat >= minLat &&
        r.lat <= maxLat &&
        r.lng >= minLon &&
        r.lng <= maxLon &&
        (!r.city || r.city.includes("new york"))
      );
    }
    return [];
  } catch (error) {
    console.error("Error fetching geocoding data:", error);
    return [];
  }
};

type ResultItem = { key: string; data: string; lat?: number; lng?: number };

const SearchPopup = ({ 
  isOpen, 
  onClose,
  searchDataAllPages = {}, 
  groupedModules,
  allData,
  setModuleIdIncrement,
  moduleIdIncrement,
}: { 
  isOpen: boolean;
  onClose: () => void;
  searchDataAllPages: any;
  groupedModules: any;
  allData: any;
  setModuleIdIncrement: Function;
  moduleIdIncrement: number;
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredResults, setFilteredResults] = useState<ResultItem[]>([]);
  const [outstandingSearch, setOutstandingSearch] = useState<boolean>(false);
  const [searchData, setSearchData] = useState<any>(null);
  const moduleId = useGlobalModuleId();
  const activePage = useGlobalActivePage();
  const activeGeography = useGlobalActiveGeography();
  
  useEffect(() => {
    console.log("C032825 searchDataAllPages", searchDataAllPages);
    if (searchDataAllPages && searchDataAllPages[activePage] && activePage !== "compare") {
      setSearchData(searchDataAllPages[activePage]);
    }
  }, [searchDataAllPages, activePage]);

  useEffect(() => {
    console.log("D032825 searchDataAllPages", searchDataAllPages);
    if (activePage === "compare" && allData && allData[activeGeography] && allData[activeGeography].meta) {
      console.log("D032825 allData[activeGeography].meta", allData[activeGeography].meta);  
      /*arr.forEach(item => {
        console.log("C032825 Array item:", item);
        let _item = { directive: "compare", data: item["VARIABLE_NAME"], directive_data: item["moduleId"], category: item.Sort.Category_ }
        _searchDataDB[`${item.Sort.Category_} - ${item["DISPLAY_NAME"]}`] 
            ? _searchDataDB[`${item.Sort.Category_} - ${item["DISPLAY_NAME"]}`].push(_item)
            : _searchDataDB[`${item.Sort.Category_} - ${item["DISPLAY_NAME"]}`] = [_item]
      });*/
      let options = allData[activeGeography].meta;
      let _searchDataCompare: any = { };
      Object.keys(options.DISPLAY_NAME).forEach(key => {
        if (options[activeGeography][key] == "X"){
          if (options.Comparisons[key] == "X"){
            console.log("D032825 options.DISPLAY_NAME[key]", options.DISPLAY_NAME[key]); 
            console.log("D032825 options.VARIABLE_NAME[key]", options.VARIABLE_NAME[key]); 
            console.log("D032825 options.VARIABLE_NAME[key]", options.VARIABLE_NAME[key]); 
            let _item = { directive: "compare", data: options.VARIABLE_NAME[key], directive_data: options.VARIABLE_NAME[key], category: "" }
            _searchDataCompare[`${options.DISPLAY_NAME[key]}`] 
                ? _searchDataCompare[`${options.DISPLAY_NAME[key]}`].push(_item)
                : _searchDataCompare[`${options.DISPLAY_NAME[key]}`] = [_item]
          }
        }
      });
      setSearchData(_searchDataCompare);
    }
  }, [searchDataAllPages, activePage, allData, activeGeography]);

  useEffect(() => {
    console.log("C032825 searchData", searchData);
  }, [searchData]);

  useEffect(() => {
    if (outstandingSearch) {
      setModuleIdIncrement(moduleIdIncrement + 1);
      setOutstandingSearch(false);
    }
  }, [moduleId]);

  // ✅ Prevent errors by ensuring searchData is always an object
  const safeSearchData = searchData && typeof searchData === 'object' ? searchData : {};

  // 🔹 Preparing Data for Search Index
  const searchIndex = preprocessSearchData(safeSearchData);

  // 🔹 Fuse.js Configuration
  /*const fuseOptions = {
    keys: ['key', 'data'],
    threshold: 0.3,  // Adjusted to reduce false positives
    includeScore: false,
    minMatchCharLength: 2, // Reduced from 3 to 2 for better prefix matching
    findAllMatches: true,  // ✅ Allows partial word matches
    ignoreLocation: true,  // ✅ Ensures matches can be anywhere
    isCaseSensitive: false, // ✅ Ensures synonyms & data are matched ignoring case
  };*/
  const fuseOptions = {
    keys: ['key', 'data'],
    
    // Lower threshold => stricter matching => fewer false positives.
    // e.g., 0.2 means the text has to be more closely matched to the query.
    threshold: 0.2,
  
    // Minimum number of characters that must match => helps reduce partial matches.
    // e.g., 3 means you need at least 3 matching characters for a match to count.
    minMatchCharLength: 3,
  
    isCaseSensitive: false,
    //   => When false, 'ABC' will match 'abc'.
  
    // distance: 100,
    //   => Determines how far the search term can be from the expected location in the text.
  
    // location: 0,
    //   => The approximate position in the text where the pattern is expected to be found.
  
    // findAllMatches: false,
    //   => When false, it stops after finding the best match (less “noisy” results).
    //   => If true, it finds all matches that satisfy the threshold.
  
    // ignoreLocation: false,
    //   => If true, the distance setting is ignored and matches can be anywhere.
  
    shouldSort: true,
    //   => Sorts the result list by best match (lowest score).
  
    // includeScore: false,
    //   => If true, each match result includes the matching score.
  
    useExtendedSearch: false,
    //   => If true, allows more advanced search patterns like exact match (=) or fuzzy match (~).
  };
  

  const fuse = new Fuse(searchIndex, fuseOptions);

  // 🔹 Handle Search Input
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawQuery = e.target.value;
    setSearchTerm(rawQuery);

    if (rawQuery.length > 1) {
      // Expand the query with synonyms (all in lowercase now)
      const expandedQueries = expandQuery(rawQuery);
      console.log("🔵 Expanded Queries:", expandedQueries);

      let allResults: ResultItem[] = [];

      expandedQueries.forEach(query => {
        const results = fuse.search(query).map(r => r.item);
        allResults = [...allResults, ...results];
      });

      // Remove duplicates from Fuse search results
      const uniqueResults = Array.from(new Map(allResults.map(item => [item.key, item])).values());

      console.log("🔴 Final Results (Fuse only):", uniqueResults);

      // 🔹 Integrate Stadia Maps Geocoding
      (async () => {
        const geoResults = await fetchStadiaGeocode(rawQuery);

        // ✅ Reorder so geocode results appear first
        const combinedResults = [...geoResults, ...uniqueResults];
        //const combinedResults = [ ...uniqueResults, ...geoResults];

        // Remove duplicates from combined results
        const finalResults = Array.from(new Map(combinedResults.map(item => [item.key, item])).values());
        console.log("🔴 Combined Results (Geocode first):", finalResults);

        setFilteredResults(finalResults);
      })();
    } else {
      setFilteredResults([]);
    }
  };

  // 🔹 Handle Selection
  /*const handleSelect = (itemKey: string) => {
    console.log(`✅ Selected: ${itemKey}`);
    setOutstandingSearch(true);
    onSetModuleId(safeSearchData[itemKey]?.directive || "");
    onSetActiveIndicator(safeSearchData[itemKey]?.data || "");
    onClose();
  };*/
  const handleSelect = (result: ResultItem) => {
    console.log(`A032825 You selected: ${result.key}`);
    console.log("A032825 Full result", result);
    if (result.lat && result.lng) { // If the result has coordinates, use them
      console.log(`A032825 Coordinates: lat=${result.lat}, lng=${result.lng}`);
      onSetSearchLatLng({"lat": result.lat, "lng": result.lng, "label": result.key});
    } else {
      // If this result matches one of your existing searchData items, use that directive.
      if (searchData[result.key] && searchData[result.key][0]) {
        console.log("A032825 searchData[result.key]", searchData[result.key]);
        console.log("A032825 searchData", searchData);
        if (activePage === "explorer") {
          onSetModuleId(searchData[result.key][0].directive_data);
          onSetActiveModuleId(searchData[result.key][0].directive_data);
          onSetActiveIndicator(searchData[result.key][0].data);
          setModuleIdIncrement(moduleIdIncrement + 1);
        } else if (activePage === "stories") {
          // TBD
          onSetModuleId(searchData[result.key][0].directive_data);
          onSetActiveModuleId(searchData[result.key][0].directive_data);
          onSetActiveIndicator(searchData[result.key][0].data);
          setModuleIdIncrement(moduleIdIncrement + 1);
        } else if (activePage === "dashboard") {
          console.log("A032825 DASHBOARD SEARCH HAPPENED searchData[result.key]", searchData[result.key]);
          onSetDBModuleId(searchData[result.key][0].directive_data);
          onSetActiveDBModuleId(searchData[result.key][0].directive_data);
          onSetActiveIndicator(searchData[result.key][0].data);
          setModuleIdIncrement(moduleIdIncrement + 1);
        } else if (activePage === "compare") {
          console.log("A032825 DASHBOARD SEARCH HAPPENED searchData[result.key]", searchData[result.key]);
          onSetActiveIndicator(searchData[result.key][0].data);
        }
      }
      // Logic to handle specific selection can go here
      // Example: navigate to a specific page or filter data based on selection
    }
    setOutstandingSearch(true);
    onClose(); // Close the popup after selection
  };

  if (!isOpen) return null;

  return (
    <div style={{
        position: 'fixed',
        top: '50px',
        left: 250,
        width: '100vw',
        height: 'calc(100% - 50px)',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1001,
      }}>
      <div style={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          cursor: 'pointer',
          fontSize: '24px',
        }} onClick={onClose}>
        X
      </div>
      <div style={{ textAlign: 'left', width: '80%', maxWidth: '600px' }}>
        <div style={{ position: 'relative', width: '100%', marginBottom: '20px' }}>
          <input
            type="text"
            value={searchTerm}
            onChange={handleInputChange}
            placeholder="Search..."
            style={{
              width: '100%',
              padding: '10px',
              fontSize: '2rem',
              border: 'none',
              borderBottom: '2px solid #333',
              outline: 'none',
              textAlign: 'left',
              color: '#666',
            }}
          />
          <div
            style={{
              position: 'absolute',
              right: '10px',
              top: '50%',
              transform: 'translateY(-50%)',
              cursor: 'pointer',
            }}
          >
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="18.3337" cy="11.6667" r="9.91667" fill="none" stroke="black" strokeWidth="3.5" />
              <path d="M2 28.0001L11.3333 18.6667" stroke="black" strokeWidth="3.5" strokeLinecap="round" />
            </svg>
          </div>
        </div>

        {/* 🔹 Typeahead Suggestions */}
        <ul style={{ listStyleType: 'none', padding: 0, margin: 0, width: '100%', maxHeight: '200px', overflowY: 'auto', textAlign: 'left' }}>
          {filteredResults.length > 0 ? (
            filteredResults.map(result => (
              <li
                key={result.key}
                style={{ padding: '10px', cursor: 'pointer', backgroundColor: '#f5f5f5', marginBottom: '5px' }}
                onClick={() => handleSelect(result)}
              >
                {result.key}
              </li>
            ))
          ) : (
            <li style={{ padding: '10px', fontStyle: 'italic' }}>No results found</li>
          )}
        </ul>

        {/* 🔹 Restored Instructions Below Input Box */}
        <div style={{ marginTop: '30px', fontSize: '1.2rem' }}>
          <p>Type anything you want into this search bar - including areas, zip codes, population groups, statistics, or even combinations of statistics you’d like to see.</p>
          <p><b>Examples:</b></p>
          <ul style={{ listStyleType: 'none' }}>
            <li>Total Population</li>
            <li>Families</li>
            {/*<li>Alzheimer's Disease</li>
            <li>Gender Statistics</li>*/}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SearchPopup;
