import { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';
import { encodeString, decodeString } from '../components/utilities/Utilities';
//import { encode, decode } from 'base64-url';

type Subscriber<T> = (value: T) => void;

class StatusStore {
  // INSTANTIATE VARIABLES
  private hoveredId: string | null = null;
  private selectedId: string | null = null;
  private hoveredSubscribers: Subscriber<string | null>[] = [];
  private selectedSubscribers: Subscriber<string | null>[] = [];
  private searchLatLng: any = null; // Default value set
  private activeGeography: string = new URLSearchParams(window.location.search).get('activeGeography') || "CD"; // Default value set
  private activeIndicator: string = new URLSearchParams(window.location.search).get('activeIndicator') 
    ? decodeString(new URLSearchParams(window.location.search).get('activeIndicator') || '') 
    : "ahdi";
  private cachedActiveIndicators: any = {};
  private cachedSelectedIds: any = {
    Boro: "1",
    CD: "101",
    PUMA: "",
    NTA: "MN0802",
    Tract: "36047028600"
  };
  private bivariateIndicator: string = new URLSearchParams(window.location.search).get('bivariateIndicator')
    ? decodeString(new URLSearchParams(window.location.search).get('bivariateIndicator'))
    : "food_insecure";
  private bivariateOverRide: { x: number[], y: number[] } | null = null;
  private moduleId: string = new URLSearchParams(window.location.search).get('moduleId') || 'ahdi';
  private activeModuleId: string = new URLSearchParams(window.location.search).get('activeModuleId') || 'ahdi';
  private activePointStatus: string = new URLSearchParams(window.location.search).get('activePointStatus') || '';
  private customDB: string = new URLSearchParams(window.location.search).get('customDB') || '';
  private DBModuleId: string = new URLSearchParams(window.location.search).get('DBModuleId') || 'hdi';
  private activeDBModuleId: string = new URLSearchParams(window.location.search).get('activeDBModuleId') || 'hdi';
  private activePage: string = new URLSearchParams(window.location.search).get('page') || 'explorer';
  private activeCategory: string = "Core Stats";
  private selectedPoint: any = null;
  private selectedPointFeature: any = null;
  private activeSubCategory: string = "American Human Development Index";
  private categoryChangeComplete: boolean = false;
  private activeGeographySubscribers: Subscriber<string>[] = [];
  private searchLatLngSubscribers: Subscriber<string>[] = [];
  private activeIndicatorSubscribers: Subscriber<string>[] = [];
  private cachedActiveIndicatorsSubscribers: Subscriber<any>[] = [];
  private cachedSelectedIdsSubscribers: Subscriber<any>[] = [];
  private bivariateIndicatorSubscribers: Subscriber<string>[] = [];
  private bivariateOverRideSubscribers: Subscriber<{ x: number[], y: number[] } | null>[] = [];
  private activeCategorySubscribers: Subscriber<string>[] = [];
  private selectedPointSubscribers: Subscriber<any>[] = [];
  private selectedPointFeatureSubscribers: Subscriber<any>[] = [];
  private moduleIdSubscribers: Subscriber<string>[] = [];
  private DBModuleIdSubscribers: Subscriber<string>[] = [];
  private activeModuleIdSubscribers: Subscriber<string>[] = [];
  private activePointStatusSubscribers: Subscriber<string>[] = [];
  private customDBSubscribers: Subscriber<string>[] = [];
  private activeDBModuleIdSubscribers: Subscriber<string>[] = [];
  private activePageSubscribers: Subscriber<string>[] = [];
  private activeSubCategorySubscribers: Subscriber<string>[] = [];
  private categoryChangeCompleteSubscribers: Subscriber<boolean>[] = [];

  private navigate: (url: string, options?: { replace?: boolean }) => void;
  private location: ReturnType<typeof useLocation>;

  constructor() {
    this.navigate = () => {};
    this.location = { search: '' } as any;
  }

  // Initialize the store with navigation and location context from React Router
  initializeNavigation(
    navigate: (url: string, options?: { replace?: boolean }) => void,
    location: ReturnType<typeof useLocation>
  ) {
    this.navigate = navigate;
    this.location = location;

    // Initialize from URL
    const searchParams = new URLSearchParams(location.search);
    this.activeGeography = searchParams.get('activeGeography') || this.activeGeography;
    this.activeGeographySubscribers.forEach((sub) => sub(this.activeGeography));
    //console.log("E112724 this.cachedSelectedIds", this.cachedSelectedIds);
    this.selectedId = searchParams.get('selectedId') || this.cachedSelectedIds[this.activeGeography] || this.selectedId;
    this.selectedSubscribers.forEach((sub) => sub(this.selectedId));

    //this.activeIndicator = searchParams.get('activeIndicator') || this.activeIndicator || this.cachedActiveIndicators[this.activeGeography].active;
    this.activeIndicator = decodeString(searchParams.get('activeIndicator')) || this.activeIndicator || this.cachedActiveIndicators[this.activeGeography].active;
    //this.activeIndicator = decode(searchParams.get('activeIndicator') || encode(this.activeIndicator || this.cachedActiveIndicators[this.activeGeography].active));

    this.activeIndicatorSubscribers.forEach((sub) => sub(this.activeIndicator));
    
  }

  // Utility function to create ordered query string
  /*private getOrderedQueryString(_searchParams:URLSearchParams) {
    console.log("091624 getOrderedQueryString() _searchParams");
    const paramsOrder = ['page', 'lat', 'lng', 'zoom', 'selectedId', 'activeGeography'];
    const searchParams = _searchParams;//new URLSearchParams(this.location.search);
    const orderedParams = new URLSearchParams();

    paramsOrder.forEach(param => {
      console.log("091624 param", param);
      const value = searchParams.get(param);
      if (value) orderedParams.set(param, value);
    });

    // Add any remaining parameters not in the default order
    Array.from(searchParams.keys()).forEach(key => {
      if (!paramsOrder.includes(key)) {
        console.log("B091624 key", key);
        const value = searchParams.get(key);
        console.log("B091624 value", value);
        if (value) orderedParams.set(key, value);
      }
    });
    console.log("B091624 orderedParams", orderedParams);
    return orderedParams.toString();
  }*/
 
  // Update URL parameters in specified order
  private updateUrlParams(caller?: string) {
    const searchParams = new URLSearchParams(this.location.search);
    // Ensure all parameters are updated correctly
    searchParams.set('page', searchParams.get('page') || 'explorer'); // Always set page
    //console.log("EE112724 this.cachedSelectedIds", this.cachedSelectedIds);
    //console.log("EE112724 this.selectedId", this.selectedId);
    if (this.selectedId !== null) searchParams.set('selectedId', this.selectedId); 
    if (this.activeGeography) searchParams.set('activeGeography', this.activeGeography);
    if (this.moduleId) searchParams.set('moduleId', this.moduleId.toString());
    if (this.DBModuleId) searchParams.set('DBModuleId', this.DBModuleId.toString());
    if (this.activeModuleId) searchParams.set('activeModuleId', this.activeModuleId.toString());
    
    if (this.activePointStatus) {
      searchParams.set('activePointStatus', this.activePointStatus.toString());
    } else {
      searchParams.delete('activePointStatus');
    }
    if (this.customDB || this.customDB !== "") {
      searchParams.set('customDB', this.customDB.toString());
    } else {
      searchParams.delete('customDB');
    }

    if (this.activeDBModuleId) searchParams.set('activeDBModuleId', this.activeDBModuleId.toString());
    if (this.activePage) searchParams.set('page', this.activePage.toString());
    //console.log("D101624 caller", caller);
    //console.log("D101624 this.activeIndicator", this.activeIndicator);
    //console.log("D101624 this.bivariateIndicator", this.bivariateIndicator);
    if (encodeString(this.activeIndicator)) searchParams.set('activeIndicator', encodeString(this.activeIndicator));
    if (encodeString(this.bivariateIndicator)) searchParams.set('bivariateIndicator', encodeString(this.bivariateIndicator));
    
    const lat = searchParams.get('lat');
    const lng = searchParams.get('lng');
    const zoom = searchParams.get('zoom');

    if (lat) searchParams.set('lat', lat);
    if (lng) searchParams.set('lng', lng);
    if (zoom) searchParams.set('zoom', zoom);

    // Construct the ordered query string and navigate
    const orderedQueryString = getOrderedQueryString(searchParams);
    this.navigate(`/?${orderedQueryString}`, { replace: true });
  }

  // DEFINE METHODS
  setHoveredId = (id: string | null) => {
    this.hoveredId = id;
    this.hoveredSubscribers.forEach((sub) => sub(this.hoveredId));
  };

  setSelectedId = (id: string | null) => {
    //console.log("EEE112724 setSelectedId id", id);
    this.selectedId = id;
    this.selectedSubscribers.forEach((sub) => sub(this.selectedId));
    this.updateUrlParams("setSelectedId"); // Update URL on change
  };

  setActiveGeography = (geography: string) => {
    console.log("060524 this.activeGeography", this.activeGeography);
    console.log("E112724 this.cachedSelectedIds", this.cachedSelectedIds);
    console.log("060524 this.cachedActiveIndicators", this.cachedActiveIndicators);
    console.log("060524 this.cachedSelectedIds[geography]", this.cachedSelectedIds[geography]);

    if (this.activeGeography && this.selectedId !== null) {
      console.log("E112724 this.selectedId", this.selectedId);
      this.cachedSelectedIds[this.activeGeography] = this.selectedId;
    }

    this.activeGeography = geography;
    this.activeGeographySubscribers.forEach((sub) => sub(this.activeGeography));

    const newSelectedId = this.cachedSelectedIds[geography] || null;
    console.log("E112724 newSelectedId", newSelectedId);
    this.setSelectedId(newSelectedId);

    this.updateUrlParams("setActiveGeography"); // Update URL on change
  };

  setSearchLatLng = (latLng: any) => {
    this.searchLatLng = latLng;
    this.searchLatLngSubscribers.forEach((sub) => sub(this.searchLatLng));
  };

  setActiveCategory = (category: string) => {
    this.activeCategory = category;
    this.activeCategorySubscribers.forEach((sub) => sub(this.activeCategory));
  };

  setSelectedPoint = (point: any) => {
    this.selectedPoint = point;
    this.selectedPointSubscribers.forEach((sub) => sub(this.selectedPoint));
  };

  setSelectedPointFeature = (point: any) => {
    this.selectedPointFeature = point;
    this.selectedPointFeatureSubscribers.forEach((sub) => sub(this.selectedPointFeature));
  };

  setActiveModuleId = (id: string) => {
    this.activeModuleId = id;
    this.activeModuleIdSubscribers.forEach((sub) => sub(this.activeModuleId));
    this.updateUrlParams("setActiveModuleId"); 
  };

  setActivePointStatus = (id: string) => {
    this.activePointStatus = id;
    this.activePointStatusSubscribers.forEach((sub) => sub(this.activePointStatus));
    this.updateUrlParams("setActivePointStatus"); 
  };

  setCustomDB = (config: string) => {
    this.customDB = config;
    this.customDBSubscribers.forEach((sub) => sub(this.customDB));
    this.updateUrlParams("setCustomDB"); 
  };

  setActiveDBModuleId = (id: string) => {
    this.activeDBModuleId = id;
    this.activeDBModuleIdSubscribers.forEach((sub) => sub(this.activeDBModuleId));
    this.updateUrlParams("setActiveDBModuleId"); 
  };

  setModuleId = (id: string) => {
    this.moduleId = id;
    this.moduleIdSubscribers.forEach((sub) => sub(this.moduleId));
    this.updateUrlParams("setModuleId"); 
  };

  setDBModuleId = (id: string) => {
    this.DBModuleId = id;
    this.DBModuleIdSubscribers.forEach((sub) => sub(this.DBModuleId));
    this.updateUrlParams("setDBModuleId"); 
  };

  setActivePage = (p: string) => {
    this.activePage = p;
    this.activePageSubscribers.forEach((sub) => sub(this.activePage));
    this.updateUrlParams("setPctivePage"); 
  };

  setActiveSubCategory = (subCategory: string) => {
    this.activeSubCategory = subCategory;
    this.activeSubCategorySubscribers.forEach((sub) => sub(this.activeSubCategory));
  };

  setCategoryChangeComplete = (setting: boolean) => {
    this.categoryChangeComplete = setting;
    this.categoryChangeCompleteSubscribers.forEach((sub) =>
      sub(this.categoryChangeComplete)
    );
  };

  setActiveIndicator = (indicator: string) => {
    this.activeIndicator = indicator;
    this.activeIndicatorSubscribers.forEach((sub) => sub(this.activeIndicator));
    this.updateUrlParams("setActiveIndicator"); // Update URL on change
  };

  setCachedActiveIndicators = (indicators: any) => {
    this.cachedActiveIndicators = indicators;
    this.cachedActiveIndicatorsSubscribers.forEach((sub: any) =>
      sub(this.cachedActiveIndicators)
    );
  };

  setCachedSelectedIds = (selectedIds: any) => {
    console.log("E112724 selectedIds", selectedIds);
    this.cachedSelectedIds = selectedIds;
    this.cachedSelectedIdsSubscribers.forEach((sub: any) =>
      sub(this.cachedSelectedIds)
    );
  };

  setBivariateIndicator = (indicator: string) => {
    this.bivariateIndicator = indicator;
    this.bivariateIndicatorSubscribers.forEach((sub) => sub(this.bivariateIndicator));
    this.updateUrlParams("setBivariateIndicator");
  };

  setBivariateOverRide = (override: { x: number[], y: number[] } | null) => {
    this.bivariateOverRide = override;
    this.bivariateOverRideSubscribers.forEach((sub) => sub(this.bivariateOverRide));
  };

  // SUBSCRIBE TO CHANGES
  subscribeToHoveredId = (subscriber: Subscriber<string | null>) => {
    this.hoveredSubscribers.push(subscriber);
    return () => {
      this.hoveredSubscribers = this.hoveredSubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToSelectedId = (subscriber: Subscriber<string | null>) => {
    this.selectedSubscribers.push(subscriber);
    return () => {
      this.selectedSubscribers = this.selectedSubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToActiveGeography = (subscriber: Subscriber<string>) => {
    this.activeGeographySubscribers.push(subscriber);
    return () => {
      this.activeGeographySubscribers = this.activeGeographySubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToSearchLatLng = (subscriber: Subscriber<any>) => {
    this.searchLatLngSubscribers.push(subscriber);
    return () => {
      this.searchLatLngSubscribers = this.searchLatLngSubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToActiveCategory = (subscriber: Subscriber<string>) => {
    this.activeCategorySubscribers.push(subscriber);
    return () => {
      this.activeCategorySubscribers = this.activeCategorySubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToSelectedPoint = (subscriber: Subscriber<any>) => {
    this.selectedPointSubscribers.push(subscriber);
    return () => {
      this.selectedPointSubscribers = this.selectedPointSubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToSelectedPointFeature = (subscriber: Subscriber<any>) => {
    this.selectedPointFeatureSubscribers.push(subscriber);
    return () => {
      this.selectedPointFeatureSubscribers = this.selectedPointFeatureSubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToActiveModuleId = (subscriber: Subscriber<string>) => {
    this.activeModuleIdSubscribers.push(subscriber);
    return () => {
      this.activeModuleIdSubscribers = this.activeModuleIdSubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToActivePointStatus = (subscriber: Subscriber<string>) => {
    this.activePointStatusSubscribers.push(subscriber);
    return () => {
      this.activePointStatusSubscribers = this.activePointStatusSubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToCustomDB = (subscriber: Subscriber<string>) => {
    this.customDBSubscribers.push(subscriber);
    return () => {
      this.customDBSubscribers = this.customDBSubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToActiveDBModuleId = (subscriber: Subscriber<string>) => {
    this.activeDBModuleIdSubscribers.push(subscriber);
    return () => {
      this.activeDBModuleIdSubscribers = this.activeDBModuleIdSubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToModuleId = (subscriber: Subscriber<string>) => {
    this.moduleIdSubscribers.push(subscriber);
    return () => {
      this.moduleIdSubscribers = this.moduleIdSubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToDBModuleId = (subscriber: Subscriber<string>) => {
    this.DBModuleIdSubscribers.push(subscriber);
    return () => {
      this.DBModuleIdSubscribers = this.DBModuleIdSubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToActivePage = (subscriber: Subscriber<string>) => {
    this.activePageSubscribers.push(subscriber);
    return () => {
      this.activePageSubscribers = this.activePageSubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToActiveSubCategory = (subscriber: Subscriber<string>) => {
    this.activeSubCategorySubscribers.push(subscriber);
    return () => {
      this.activeSubCategorySubscribers = this.activeSubCategorySubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToCategoryChangeComplete = (subscriber: Subscriber<boolean>) => {
    this.categoryChangeCompleteSubscribers.push(subscriber);
    return () => {
      this.categoryChangeCompleteSubscribers =
        this.categoryChangeCompleteSubscribers.filter((sub) => sub !== subscriber);
    };
  };

  subscribeToActiveIndicator = (subscriber: Subscriber<string>) => {
    this.activeIndicatorSubscribers.push(subscriber);
    return () => {
      this.activeIndicatorSubscribers = this.activeIndicatorSubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToCachedActiveIndicators = (subscriber: Subscriber<string>) => {
    this.cachedActiveIndicatorsSubscribers.push(subscriber);
    return () => {
      this.cachedActiveIndicatorsSubscribers =
        this.cachedActiveIndicatorsSubscribers.filter((sub: any) => sub !== subscriber);
    };
  };

  subscribeToCachedSelectedIds = (subscriber: Subscriber<string>) => {
    console.log("E112724 subscriber", subscriber);
    this.cachedSelectedIdsSubscribers.push(subscriber);
    return () => {
      this.cachedSelectedIdsSubscribers = this.cachedSelectedIdsSubscribers.filter(
        (sub: any) => sub !== subscriber
      );
    };
  };

  subscribeToBivariateIndicator = (subscriber: Subscriber<string>) => {
    this.bivariateIndicatorSubscribers.push(subscriber);
    return () => {
      this.bivariateIndicatorSubscribers = this.bivariateIndicatorSubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  subscribeToBivariateOverRide = (subscriber: Subscriber<{ x: number[], y: number[] } | null>) => {
    this.bivariateOverRideSubscribers.push(subscriber);
    return () => {
      this.bivariateOverRideSubscribers = this.bivariateOverRideSubscribers.filter(
        (sub) => sub !== subscriber
      );
    };
  };

  // GETTERS
  getOnHoverHistogram = () =>
    debounce((id: string | null) => {
      this.setHoveredId(id);
    }, 10);

  getOnClickHistogram = () => (id: string | null) => {
    //console.log("EEEE112724 id", id);
    this.setSelectedId(id);
  };

  getOnChangeActiveGeography = () => (geography: string) => {
    console.log("060524 this.activeGeography", this.activeGeography);
    console.log("E112724 this.cachedSelectedIds", this.cachedSelectedIds);
    console.log("060524 this.cachedActiveIndicators", this.cachedActiveIndicators);
    console.log("060524 this.cachedSelectedIds[geography]", this.cachedSelectedIds[geography]);
    this.setActiveGeography(geography);
  };

  getOnChangeSearchLatLng = () => (latLng: any) => {
    this.setSearchLatLng(latLng);
  };

  getOnChangeActiveIndicator = () =>
    debounce((indicator: string) => {
      this.setActiveIndicator(indicator);
    }, 50);

  getOnChangeActiveCategory = () =>
    debounce((category: string) => {
      this.setActiveCategory(category);
    }, 50);

  getOnChangeSelectedPoint = () =>
    debounce((point: any) => {
      this.setSelectedPoint(point);
    }, 50);

  getOnChangeSelectedPointFeature = () =>
    debounce((point: any) => {
      this.setSelectedPointFeature(point);
    }, 50);

  getOnChangeActiveModuleId = () =>
    debounce((id: string) => {
      this.setActiveModuleId(id);
    }, 50);

  getOnChangeActivePointStatus = () =>
    debounce((id: string) => {
      //console.log("E121624 id", id);
      this.setActivePointStatus(id);
    }, 50);

  getOnChangeCustomDB = () =>
    debounce((config: string) => {
      console.log("011225 config", config);
      this.setCustomDB(config);
    }, 50);

  getOnChangeActiveDBModuleId = () =>
    debounce((id: string) => {
      this.setActiveDBModuleId(id);
    }, 50);

  getOnChangeModuleId = () =>
    debounce((id: string) => {
      this.setModuleId(id);
    }, 50);

  getOnChangeDBModuleId = () =>
    debounce((id: string) => {
      this.setDBModuleId(id);
    }, 50);

  getOnChangeActivePage = () =>
    debounce((p: string) => {
      this.setActivePage(p);
    }, 0);

  getOnChangeActiveSubCategory = () =>
    debounce((subCategory: string) => {
      this.setActiveSubCategory(subCategory);
    }, 50);

  getOnChangeCategoryChangeComplete = () =>
    debounce((setting: boolean) => {
      this.setCategoryChangeComplete(setting);
    }, 50);

  getOnChangeCachedActiveIndicators = () =>
    debounce((indicators: any) => {
      this.setCachedActiveIndicators(indicators);
    }, 50);

  getOnChangeCachedSelectedIds = () =>
    debounce((ids: any) => {
      console.log("E112724 ids", ids);
      this.setCachedSelectedIds(ids);
    }, 50);

  getOnChangeBivariateIndicator = () =>
    debounce((indicator: string) => {
      this.setBivariateIndicator(indicator);
    }, 50);

  getOnChangeBivariateOverRide = () =>
    debounce((override: { x: number[], y: number[] } | null) => {
      this.setBivariateOverRide(override);
    }, 50);

  getOnSetSelectedId = () => (id: string | null) => {
    //console.log("EEE112724 ------------> getOnSetSelectedId id", id);
    this.setSelectedId(id);
  };
}

// INSTANTIATE THE STORE
const statusStore = new StatusStore();

// Utility function to create ordered query string
export const getOrderedQueryString = (_searchParams: URLSearchParams) => {
  //console.log("091624 getOrderedQueryString() _searchParams");
  const paramsOrder = ['page', 'lat', 'lng', 'zoom', 'selectedId', 'activeGeography'];
  const searchParams = _searchParams;
  const orderedParams = new URLSearchParams();

  paramsOrder.forEach(param => {
    //console.log("091624 param", param);
    const value = searchParams.get(param);
    if (value) orderedParams.set(param, value);
  });

  // Add any remaining parameters not in the default order
  Array.from(searchParams.keys()).forEach(key => {
    if (!paramsOrder.includes(key)) {
      //console.log("B091624 key", key);
      const value = searchParams.get(key);
      //console.log("B091624 value", value);
      if (value) orderedParams.set(key, value);
    }
  });
  //console.log("B091624 orderedParams", orderedParams);
  return orderedParams.toString();
};

// EXPORT THE STORE
export const useGlobalHoveredId = () => {
  const [hoveredId, setHoveredId] = useState<string | null>(null);
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToHoveredId(setHoveredId);
    return unsubscribe;
  }, []);

  return hoveredId;
};

export const useGlobalSelectedId = () => {
  const [selectedId, setSelectedId] = useState<string | null>(null);
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToSelectedId(setSelectedId);
    return unsubscribe;
  }, []);

  return selectedId;
};

export const useGlobalActiveGeography = () => {
  const [activeGeography, setActiveGeography] = useState<string>(new URLSearchParams(window.location.search).get('activeGeography') || "CD");
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToActiveGeography(setActiveGeography);
    return unsubscribe;
  }, []);

  return activeGeography;
};

export const useGlobalSearchLatLng = () => {
  const [searchLatLng, setSearchLatLng] = useState<any>(null);
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToSearchLatLng(setSearchLatLng);
    return unsubscribe;
  }, []);

  return searchLatLng;
};

export const useGlobalActiveIndicator = () => {
  const [activeIndicator, setActiveIndicator] = useState<string>(
    new URLSearchParams(window.location.search).get('activeIndicator')
      ? decodeString(new URLSearchParams(window.location.search).get('activeIndicator') || '')
      : "ahdi"
  );
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToActiveIndicator(setActiveIndicator);
    return unsubscribe;
  }, []);

  return activeIndicator;
};

export const useGlobalActiveCategory = () => {
  const [activeCategory, setActiveCategory] = useState<string>("Core Stats");
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToActiveCategory(setActiveCategory);
    return unsubscribe;
  }, []);

  return activeCategory;
};

export const useGlobalSelectedPoint = () => {
  const [selectedPoint, setSelectedPoint] = useState<any>(null);
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToSelectedPoint(setSelectedPoint);
    return unsubscribe;
  }, []);

  return selectedPoint;
};

export const useGlobalSelectedPointFeature = () => {
  const [selectedPointFeature, setSelectedPointFeature] = useState<any>(null);
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToSelectedPointFeature(setSelectedPointFeature);
    return unsubscribe;
  }, []);

  return selectedPointFeature;
};

export const useGlobalActiveModuleId = () => {
  const [activeModuleId, setActiveModuleId] = useState<string>(new URLSearchParams(window.location.search).get('activeModuleId') || 'ahdi');
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToActiveModuleId(setActiveModuleId);
    return unsubscribe;
  }, []);

  return activeModuleId;
};

export const useGlobalActivePointStatus = () => {
  const [activePointStatus, setActivePointStatus] = useState<string>(new URLSearchParams(window.location.search).get('activePointStatus') || '');
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToActivePointStatus(setActivePointStatus);
    return unsubscribe;
  }, []);

  return activePointStatus;
};

export const useGlobalCustomDB = () => {
  const [customDB, setCustomDB] = useState<string>(new URLSearchParams(window.location.search).get('customDB') || '');
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToCustomDB(setCustomDB);
    return unsubscribe;
  }, []);

  return customDB;
};

export const useGlobalActiveDBModuleId = () => {
  const [activeDBModuleId, setActiveDBModuleId] = useState<string>(new URLSearchParams(window.location.search).get('activeDBModuleId') || 'hdi');
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToActiveDBModuleId(setActiveDBModuleId);
    return unsubscribe;
  }, []);

  return activeDBModuleId;
};

export const useGlobalModuleId = () => {
  const [moduleId, setModuleId] = useState<string>(new URLSearchParams(window.location.search).get('moduleId') || 'ahdi');
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToModuleId(setModuleId);
    return unsubscribe;
  }, []);

  return moduleId;
};

export const useGlobalDBModuleId = () => {
  const [DBModuleId, setDBModuleId] = useState<string>(new URLSearchParams(window.location.search).get('DBModuleId') || 'hdi');
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToDBModuleId(setDBModuleId);
    return unsubscribe;
  }, []);

  return DBModuleId;
};

export const useGlobalActivePage = () => {
  const [activePage, setActivePage] = useState<string>(new URLSearchParams(window.location.search).get('page') || 'explorer');
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToActivePage(setActivePage);
    return unsubscribe;
  }, []);

  return activePage;
};

export const useGlobalActiveSubCategory = () => {
  const [activeSubCategory, setActiveSubCategory] = useState<string>(
    "American Human Development Index"
  );
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToActiveSubCategory(setActiveSubCategory);
    return unsubscribe;
  }, []);

  return activeSubCategory;
};

export const useCategoryChangeComplete = () => {
  const [categoryChangeComplete, setCategoryChangeComplete] = useState<boolean>(false);
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToCategoryChangeComplete(
      setCategoryChangeComplete
    );
    return unsubscribe;
  }, []);

  return categoryChangeComplete;
};

export const useGlobalCachedActiveIndicators = () => {
  const [cachedActiveIndicators, setCachedActiveIndicators] = useState<any>({
    Tract: {
      active: "ahdi",
      bivariate: "food_insecure"
    },
    CD: {
      active: "ahdi",
      bivariate: "food_insecure"
    },
    NTA: {
      active: "ahdi",
      bivariate: "food_insecure"
    },
    Boro: {
      active: "ahdi",
      bivariate: "food_insecure"
    }
  });
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToCachedActiveIndicators(
      setCachedActiveIndicators
    );
    return unsubscribe;
  }, []);

  return cachedActiveIndicators;
};

export const useGlobalCachedSelectedIds = () => {
  const [cachedSelectedIds, setCachedSelectedIds] = useState<any>({
    Boro: "1",
    CD: "101",
    PUMA: "",
    NTA: "MN0802",
    Tract: "36047029200"
  });
  console.log("E112724 cachedSelectedIds", cachedSelectedIds);
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToCachedSelectedIds(setCachedSelectedIds);
    return unsubscribe;
  }, []);

  return cachedSelectedIds;
};

export const useGlobalBivariateIndicator = () => {
  const [bivariateIndicator, setBivariateIndicator] = useState<string>(new URLSearchParams(window.location.search).get('bivariateIndicator')
    ? decodeString(new URLSearchParams(window.location.search).get('bivariateIndicator')) 
    : "food_insecure");
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToBivariateIndicator(setBivariateIndicator);
    return unsubscribe;
  }, []);

  return bivariateIndicator;
};

export const useGlobalBivariateOverRide = () => {
  const [bivariateOverRide, setBivariateOverRide] = useState<{ x: number[]; y: number[] } | null>(
    null
  );
  useEffect(() => {
    const unsubscribe = statusStore.subscribeToBivariateOverRide(setBivariateOverRide);
    return unsubscribe;
  }, []);

  return bivariateOverRide;
};

// EXPORT THE STORE METHODS
export const onHoverHistogram = statusStore.getOnHoverHistogram();
export const onClickHistogram = statusStore.getOnClickHistogram();
export const onSetActiveGeography = statusStore.getOnChangeActiveGeography();
export const onSetSearchLatLng = statusStore.getOnChangeSearchLatLng();
export const onSetActiveIndicator = statusStore.getOnChangeActiveIndicator();
export const onSetActiveCategory = statusStore.getOnChangeActiveCategory();
export const onSetSelectedPoint = statusStore.getOnChangeSelectedPoint();
export const onSetSelectedPointFeature = statusStore.getOnChangeSelectedPointFeature();
export const onSetModuleId = statusStore.getOnChangeModuleId();
export const onSetDBModuleId = statusStore.getOnChangeDBModuleId();
export const onSetActiveModuleId = statusStore.getOnChangeActiveModuleId();
export const onSetActivePointStatus = statusStore.getOnChangeActivePointStatus();
export const onSetCustomDB = statusStore.getOnChangeCustomDB();
export const onSetActiveDBModuleId = statusStore.getOnChangeActiveDBModuleId();
export const onSetActivePage = statusStore.getOnChangeActivePage();
export const onSetActiveSubCategory = statusStore.getOnChangeActiveSubCategory();
export const onSetCategoryChangeComplete = statusStore.getOnChangeCategoryChangeComplete();
export const onSetCachedActiveIndicators = statusStore.getOnChangeCachedActiveIndicators();
export const onSetCachedSelectedIds = statusStore.getOnChangeCachedSelectedIds();
export const onSetBivariateIndicator = statusStore.getOnChangeBivariateIndicator();
export const onSetBivariateOverRide = statusStore.getOnChangeBivariateOverRide();
export const onSetSelectedId = statusStore.getOnSetSelectedId();

// EXPORT THE HOOK TO INITIALIZE NAVIGATION CONTEXT
export const useStatusStoreNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    statusStore.initializeNavigation(navigate, location);
  }, [navigate, location]);
};
