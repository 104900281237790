import React, { useEffect, useState } from 'react';
import { useMap, useMapEvent } from 'react-leaflet';
import L from 'leaflet';
import { onSetActiveGeography, useGlobalActiveGeography, /*onSetCachedActiveIndicators,  onSetCachedSelectedIds, useGlobalCachedActiveIndicators, useGlobalCachedSelectedIds, onSetActiveIndicator, onSetBivariateIndicator*/ } from '../../data/StatusStore';
import { useData } from '../../data/DataContext';

interface MapClearPointsControlProps {
  onClickButton: () => void;
}

const MapClearPointsControl: React.FC<MapClearPointsControlProps> = ({ onClickButton }) => {
  const map = useMap();
  const {
      geoJsonDataPUMA, geoJsonDataTract, 
      geoJsonDataNTA, geoJsonDataBoro,
      isLoadingTopology, error, dataPoint, 
      setDataPoint, pointDataList, pointModules
  } = useData();
  const activeGeography = useGlobalActiveGeography();
  //const cachedActiveIndicators = useGlobalCachedActiveIndicators();
  //const cachedSelectedIds = useGlobalCachedSelectedIds();
  const [selectedValue, setSelectedValue] = useState<string>(activeGeography);

  useEffect(() => {
    // Create a Leaflet Control to position the radio buttons on the map
    const controlContainer = L.DomUtil.create('div', 'leaflet-control leaflet-bar');
    const customControl = new L.Control({ position: 'bottomleft' }); // You can adjust the position

    customControl.onAdd = function() {
      L.DomEvent.disableClickPropagation(controlContainer);
      return controlContainer;
    };

    customControl.addTo(map);

    return () => {
      customControl.remove();
    };
  }, [map]);

  useMapEvent('click', () => {
    // Optional: Respond to map clicks or other events
  });

  const clearDataPointActiveStatus = () => {
    if (dataPoint) {
      const updatedDataPoint = { ...dataPoint };
      Object.keys(updatedDataPoint).forEach((key) => {
        if (updatedDataPoint[key] && typeof updatedDataPoint[key] === 'object') {
          updatedDataPoint[key].active = false;
        }
      });
      setDataPoint(updatedDataPoint);
      /*setTimeout(() => {
        setDataPoint(updatedDataPoint);
      }, 500);*/
    }
  };

  return (
    <div className="leaflet-control-container">
      <div className="leaflet-bottom leaflet-left">
        <div className="leaflet-control leaflet-control-custom"
          id='mapResetZoom'
          style={{
            paddingBottom: "3px",
            paddingTop: "3px",
            paddingRight: "10px",
            paddingLeft: "10px",
            cursor: "pointer",
          }}
          onClick={clearDataPointActiveStatus}
        >
            clear points
        </div>
      </div>
    </div>
  );
};

export default MapClearPointsControl;
