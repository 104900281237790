import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  useGlobalSelectedId,
  useGlobalActivePage,
  useGlobalActiveGeography,
  onSetActiveIndicator,
  onSetActiveModuleId,
  onSetActiveDBModuleId,
} from '../../data/StatusStore';
import { cleanString } from '../utilities/Utilities';
import { useData } from '../../data/DataContext';
import * as turf from 'turf';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';

export interface DBCountOnlyChartProps {
  options: any;
  data: any;
  width: number;
  height: number;
  colorClass: string;
}

const DBCountOnly: React.FC<DBCountOnlyChartProps> = ({ options, data, width, height }) => {
  const [preppedData, setPreppedData] = useState<any>([]);
  const selectedId = useGlobalSelectedId();
  const activePage = useGlobalActivePage();
  const activeGeography = useGlobalActiveGeography();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [hoveredIndicator, setHoveredIndicator] = useState<any>(null);

  const {
    geoJsonDataTract,
    geoJsonDataPUMA,
    geoJsonDataNTA,
    geoJsonDataBoro,
    dataPoint,
    pointMeta,
  } = useData();

  useEffect(() => {
    if (selectedId) {
      prepData();
    }
  }, [selectedId]);

  const onClicked = (variable: string, v:any) => {
    //console.log("A020425 v", v);
    //console.log("A020425 variable", variable);
    //console.log("A020425 options", options);

    const hoveredVar = options.variables.find((v: any) => v.variable === variable);
    setHoveredIndicator(hoveredVar);
    if (activePage !== "dashboard") {
      onSetActiveModuleId(options.variables[0].sort.moduleId);
    } else {
      onSetActiveDBModuleId(options.variables[0].sort.moduleId);
    }
    onSetActiveIndicator(variable);
  };

  const prepData = () => {
    if (selectedId) {
      const uniqueDisplayNames = new Set();
      const _filteredData = options.variables.filter((v: any) => {
        if (!v.sort.Tooltip_only && !uniqueDisplayNames.has(v.sort.DISPLAY_NAME)) {
          uniqueDisplayNames.add(v.sort.DISPLAY_NAME);
          return true;
        }
        return false;
      });

      let tempGeoJSON = geoJsonDataTract;
      if (activeGeography === 'PUMA' || activeGeography === 'CD') {
        tempGeoJSON = geoJsonDataPUMA;
      } else if (activeGeography === 'NTA') {
        tempGeoJSON = geoJsonDataNTA;
      } else if (activeGeography === 'Boro') {
        tempGeoJSON = geoJsonDataBoro;
      }

      const selectedFeature = tempGeoJSON.features.find(
        (feature: any) => feature.properties.GEOID20.toString() === selectedId.toString()
      );

      const _preppedData = _filteredData.map((v: any) => {
        const rawValue = data.dataJSON[selectedId][v.variable];
        const isNA = rawValue === undefined || rawValue === null || rawValue === 'NA';
        const value = !isNA && !isNaN(rawValue) ? rawValue : 'N/A';

        let _pts = dataPoint[v.label];
        let _poly = selectedFeature;

        let pointsInside = 0;
        if (_pts.data.features){
          //this is a polygon
          let polygon: any;
          if (_poly.geometry.type === 'Polygon') {
            polygon = turf.polygon(_poly.geometry.coordinates);
          } else {
            polygon = turf.multiPolygon(_poly.geometry.coordinates);
          }
          _pts.data.features.forEach((feature: any) => {
            const centroid = turf.centroid(feature);
            const [centroidLng, centroidLat] = centroid.geometry.coordinates;
            if (booleanPointInPolygon(centroid, polygon)) {
              pointsInside++;
            }
          });
        }else{
          if (_pts && _poly) {
            let polygon: any;
            if (_poly.geometry.type === 'Polygon') {
              polygon = turf.polygon(_poly.geometry.coordinates);
            } else {
              polygon = turf.multiPolygon(_poly.geometry.coordinates);
            }
            _pts.data.forEach((point: any) => {
              const _point = turf.point([Number(point.latitude), Number(point.longitude)]);
              if (booleanPointInPolygon(_point, polygon)) {
                pointsInside++;
              }
            });
          }
        }

        const itemColor = pointMeta?.[v.label]?.META?.['OLD D2G COLOR'] || 'red';

        return {
          name: v.sort.DISPLAY_NAME,
          value,
          variable: v.variable,
          color: itemColor,
          count: pointsInside,
        };
      }).sort((a: any, b: any) => (b.value !== 'N/A' ? b.value : 0) - (a.value !== 'N/A' ? a.value : 0));

      setPreppedData(_preppedData);
    }
  };

  const fontSize = 12;

  // Group preppedData into chunks of 2
  const groupedData = useMemo(() => {
    const result = [];
    for (let i = 0; i < preppedData.length; i += 2) {
      result.push(preppedData.slice(i, i + 2));
    }
    return result;
  }, [preppedData]);

  return (
    <div
      ref={containerRef}
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      {groupedData.map((group, groupIndex) => (
        <div
          key={`group-${groupIndex}`}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '10px',
            width: '100%',
          }}
        >
          {group.map((d: any, i: number) => (
            <div
              key={`info-${groupIndex}-${i}`}
              onClick={() => onClicked(d.variable,d)}
              style={{
                width: '50%',
                height: '100%',
                position: 'relative',
                display: 'flex',
                alignItems: 'flex-start',
                paddingRight: '0px',
                cursor: 'pointer',
              }}
            >
              <div
                style={{
                  marginLeft: '0px',
                  fontSize,
                  lineHeight: `${fontSize + 2}px`,
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: '10px',
                }}
              >
                {/* First Line: Count */}
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    fontWeight: 'bold',
                    textAlign: 'left',
                  }}
                >
                  {d.count}
                </div>

                {/* Second Line: Name */}
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    textAlign: 'left',
                    fontSize: '13px',
                    fontWeight: 'bold',
                  }}
                >
                  {d.name.split("(")[0]}{ d.name.split("(")[1] 
                  ? <span
                      style={{
                        color:"#9E9C9C",
                        fontSize: '12px',
                        fontWeight: 'normal',
                      }}
                    >{`(${d.name.split("(")[1]}`}</span>
                  : null}
                </div>
              </div>
              <div
                style={{
                  marginLeft: '0px',
                  marginRight: '0px',
                  fontSize,
                  lineHeight: `${fontSize + 2}px`,
                  width: `calc(100% - 305px)`,
                  position: 'relative',
                }}
              ></div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default DBCountOnly;
