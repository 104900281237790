// DataContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import Papa, { ParseResult } from 'papaparse';
import { feature } from 'topojson-client';
import { FeatureCollection, GeoJsonObject } from 'geojson';
import { useGlobalActiveGeography } from '../data/StatusStore';
import { crosswalkPUMAtoCD, crosswalkNTAtoCD } from '../components/utilities/Utilities';
import { active, cross } from 'd3';

// Define the type for a row in your CSV data
// Update this interface according to the actual structure of your CSV data
export interface FlatFileCsvData {
  meta: any;
  dataJSON: any;
  dataArray: any;
  keyIndexDataArray: any;
}

// Define the type for your context data
interface DataContextType {
  dataActive: FlatFileCsvData | null;
  dataTract: FlatFileCsvData | null;
  dataNTA: FlatFileCsvData | null;
  dataPUMA: FlatFileCsvData | null;
  dataNYC: FlatFileCsvData | null;
  dataCD: FlatFileCsvData | null;
  dataBoro: FlatFileCsvData | null;
  dataSorter: any | null;
  dataSorterOrder: any | null;
  dataSorterOrderStories: any | null;
  dataSorterDashboard: any | null;
  dataSorterOrderDashboard: any | null;
  geoJsonDataGlobe: FeatureCollection | null;
  geoJsonDataTract: any;
  geoJsonDataNTA: any;
  geoJsonDataBoro: any;
  geoJsonDataPUMA: FeatureCollection | null;
  isLoadingTract: boolean;
  isLoadingNTA: boolean;
  isLoadingPUMA: boolean;
  isLoadingNYC: boolean;
  isLoadingCD: boolean;
  isLoadingBoro: boolean;
  isLoadingAllTabularData: boolean;
  isLoadingSorter: boolean;
  isLoadingTopology: boolean;
  isLoadingGlobeTopology: boolean;
  error: string | null;
  pointDataList: string[];
  setPointDataList: Function;
  dataPoint: any;
  pointModules: any;
  pointMeta: any;
  isLoadingPoint: boolean;
  setDataPoint: Function;
  setPointModules: Function;
}

interface DataProviderProps {
  children?: any; // ReactNode;
  title: string; // An example of an additional prop
}

// Create the context with initial default value
const DataContext = createContext<DataContextType>({ 
  dataActive: null, 
  dataTract: null, 
  dataNTA: null, 
  dataPUMA: null, 
  dataNYC: null, 
  dataCD: null, 
  dataBoro: null, 
  dataSorter: null, 
  dataSorterOrder: null, 
  dataSorterOrderStories: null, 
  dataSorterDashboard: null, 
  dataSorterOrderDashboard: null, 
  geoJsonDataTract: null, 
  geoJsonDataNTA: null, 
  geoJsonDataBoro: null, 
  geoJsonDataGlobe: null, 
  geoJsonDataPUMA: null, 
  isLoadingTract: true, 
  isLoadingNTA: true, 
  isLoadingPUMA: true, 
  isLoadingNYC: true, 
  isLoadingCD: true, 
  isLoadingBoro: true, 
  isLoadingAllTabularData: true, 
  isLoadingSorter: true, 
  isLoadingTopology: true, 
  isLoadingGlobeTopology: true, 
  error: null,
  pointDataList:[],
  setPointDataList: () => {},
  setDataPoint: () => {},
  setPointModules: () => {},
  dataPoint: null,
  pointModules: null,
  pointMeta: null,
  isLoadingPoint: true
});

// Create a provider component
export const DataProvider: React.FC<DataProviderProps> = ({ children, title }) => {
  const [dataActive, setDataActive] = useState<FlatFileCsvData | null>(null);
  const [dataTract, setDataTract] = useState<FlatFileCsvData | null>(null);
  const [dataNTA, setDataNTA] = useState<FlatFileCsvData | null>(null);
  const [dataPUMA, setDataPUMA] = useState<FlatFileCsvData | null>(null);
  const [dataCD, setDataCD] = useState<FlatFileCsvData | null>(null);
  const [dataNYC, setDataNYC] = useState<FlatFileCsvData | null>(null);
  const [dataBoro, setDataBoro] = useState<FlatFileCsvData | null>(null);
  const [dataPoint, setDataPoint] = useState<any>(null);
  const [pointModules, setPointModules] = useState<any>(null);
  const [pointMeta, setPointMeta] = useState<any>(null);
  const [dataSorter, setDataSorter] = useState<any | null>(null);
  const [dataSorterDashboard, setDataSorterDashboard] = useState<any | null>(null);
  const [dataSorterMeta, setDataSorterMeta] = useState<any | null>(null);
  const [dataSorterMetaDashboard, setDataSorterMetaDashboard] = useState<any | null>(null);
  const [dataSorterOrder, setDataSorterOrder] = useState<any | null>(null);
  const [dataSorterOrderStories, setDataSorterOrderStories] = useState<any | null>(null);
  const [dataSorterOrderDashboard, setDataSorterOrderDashboard] = useState<any | null>(null);
  const [geoJsonDataGlobe, setGeoJsonDataGlobe] = useState<any>(null);
  const [geoJsonDataTract, setGeoJsonDataTract] = useState<FeatureCollection  | null>(null);
  const [geoJsonDataNTA, setGeoJsonDataNTA] = useState<FeatureCollection  | null>(null);
  const [geoJsonDataPUMA, setGeoJsonDataPUMA] = useState<FeatureCollection  | null>(null);
  const [geoJsonDataBoro, setGeoJsonDataBoro] = useState<FeatureCollection  | null>(null);
  const [crosswalkedPUMAData, setCrosswalkedPUMAData] = useState<any>(null);
  const [pointDataList, setPointDataList] = useState<string[]>([]);
  const [crosswalkedNTAData, setCrosswalkedNTAData] = useState<any>(null);
  const [federatedVariables, setFederatedVariables] = useState<string[]>([]);
  const [federatedGeos, setFederatedGeos] = useState<string[]>([]);
  const [federatedIncrement, setFederatedIncrement] = useState<number>(0);
  const [rawCDData, setRawCDData] = useState<any>(null);
  const [rawConcatPUMA_CD, setRawConcatPUMA_CD] = useState<any>(null);
  const [isLoadingTract, setIsLoadingTract] = useState<boolean>(true);
  const [isLoadingNTA, setIsLoadingNTA] = useState<boolean>(true);
  const [isLoadingPUMA, setIsLoadingPUMA] = useState<boolean>(true);
  const [isLoadingNYC, setIsLoadingNYC] = useState<boolean>(true);
  const [isLoadingCD, setIsLoadingCD] = useState<boolean>(true);
  const [isLoadingBoro, setIsLoadingBoro] = useState<boolean>(true);
  const [isLoadingPoint, setIsLoadingPoint] = useState<boolean>(true);
  const [isLoadingAllTabularData, setIsLoadingAllTabularData] = useState<boolean>(true);
  const [isLoadingSorter, setIsLoadingSorter] = useState<boolean>(true);
  const [isLoadingSorterDashboard, setIsLoadingSorterDashboard] = useState<boolean>(true);
  const [isLoadingTopology, setIsLoadingTopology] = useState<boolean>(true);
  const [isLoadingGlobeTopology, setIsLoadingGlobeTopology] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const activeGeography = useGlobalActiveGeography();
  const lookupNTARedact = [
    'QN0151',
    'BK0261',
    'BK1061',
    'BX1161',
    'MN0661',
    'QN0161',
    'QN0261',
    'QN0761',
    'SI9561',
    'BK0471',
    'BK0571',
    'BK0771',
    'BK1771',
    'BX1071',
    'BX1271',
    'QN0171',
    'QN0271',
    'QN0571',
    'QN0572',
    'QN0573',
    'QN0574',
    'QN0871',
    'QN1371',
    'QN8081',
    'QN8381',
    'BK0891',
    'BK1091',
    'BK1391',
    'BK1891',
    'BK1892',
    'BK1893',
    'BK5591',
    'BK5691',
    'BK5692',
    'BK5693',
    'BX0291',
    'BX0391',
    'BX0491',
    'BX0492',
    'BX0991',
    'BX1091',
    'BX2691',
    'BX2791',
    'BX2891',
    'MN0191',
    'MN1191',
    'MN1291',
    'MN1292',
    'MN6491',
    'QN0191',
    'QN0791',
    'QN0891',
    'QN1091',
    'QN1191',
    'QN1491',
    'QN8191',
    'QN8291',
    'QN8491',
    'QN8492',
    'SI0191',
    'SI0291',
    'SI0391',
    'SI9591',
    'SI9592',
    'SI9593',
  ];
  function setActiveGeography() {
    console.log('Active geography:', activeGeography);
    switch (activeGeography) {
      case 'Tract':
        setDataActive(dataTract);
        break;
      case 'NTA':
        setDataActive(dataNTA);
        break;
      case 'PUMA':
        //setDataActive(dataPUMA); // PUMA data is now crosswalked to CD
        setDataActive(dataCD);
        break;
      case 'NYC':
        setDataActive(dataNYC);
        break;
      case 'CD':
        setDataActive(dataCD);
        break;
      case 'Boro':
        setDataActive(dataBoro);
        break;
      default:
        setDataActive(null);
        break;
    }
    
  }
 
  useEffect(() => {
    
    console.log("D112024 federatedGeos", federatedGeos);
    console.log("D112024 federatedIncrement", federatedIncrement);
    if (federatedGeos.length > 4){
      console.log("D112024 federatedVariables DONE!", federatedVariables);
    }
  //}, [federatedVariables, federatedIncrement, federatedGeos]);
  }, [federatedIncrement]);

  useEffect(() => {
    setActiveGeography();
  }, [activeGeography]);

  useEffect(() => {
    if (!isLoadingBoro && !isLoadingCD && !isLoadingNYC && !isLoadingPUMA && !isLoadingTract && !isLoadingNTA && !isLoadingSorter){
      console.log('All data loaded');
      console.log('D041824 activeGeography', activeGeography);
      setActiveGeography();
      setIsLoadingAllTabularData(false);
    }
  }, [isLoadingPUMA, isLoadingCD, isLoadingBoro, isLoadingNYC, isLoadingTract, isLoadingNTA]);

  useEffect(() => {
    console.log('G111124 crosswalkedPUMAData:', crosswalkedPUMAData);
    console.log('G111124 rawCDData:', rawCDData);
    if (crosswalkedPUMAData && rawCDData && rawCDData.length > 0) {
        const concatPUMA_CD = JSON.parse(JSON.stringify(rawCDData)).map((row: any, indexCD:number) => {
          //console.log('B100924 row:', row);
          let _pumaRow = crosswalkedPUMAData.filter((crosswalkedRow: any, index:number) => {
            if (row.GEO_ID !== ""){
              return crosswalkedRow.GEO_ID === row.GEO_ID;
            }else{
              return indexCD === index;
            }
          })[0];
          //console.log('B100924 _pumaRow:', _pumaRow);
          if (_pumaRow){
            Object.keys(_pumaRow).forEach((key: string) => {
                //console.log('D100924 key', key);  
                let cleanedKey = key.replace("_puma", "_cd"); 
                //console.log('D100924 Object.keys(row)', Object.keys(row));  
                if (!Object.keys(row).includes(cleanedKey)){
                  //console.log('G111124 key', key);
                  row[cleanedKey] = _pumaRow[key];
                  //row[key] = _pumaRow[key];
                }
            });
          };
          return row;
        });
        //const concatPUMA_CD = rawCDData; //rawCDData.concat(crosswalkedPUMAData);
        console.log('C112024 concatPUMA_CD:', concatPUMA_CD);
        setRawConcatPUMA_CD(concatPUMA_CD);
        const transformedPUMA_CD = transformData(concatPUMA_CD, "_cd");
        console.log('E100924 transformedPUMA_CD:', transformedPUMA_CD);
        setDataPUMA(transformedPUMA_CD);
        setDataCD(transformedPUMA_CD);
        setDataActive(transformedPUMA_CD);
        setIsLoadingCD(false);
        setIsLoadingPUMA(false);
    }
  }, [rawCDData, crosswalkedPUMAData]);

  useEffect(() => {
    console.log('C112024 crosswalkedNTAData:', crosswalkedNTAData);
    console.log('C112024 rawCDData:', rawCDData);
    console.log('C112024 rawConcatPUMA_CD:', rawConcatPUMA_CD);
    const _rawCDData = JSON.parse(JSON.stringify(rawConcatPUMA_CD));
  const allNAVariables: string[] = [];
  const allNAVarsClean: string[] = [];
  if (crosswalkedNTAData && crosswalkedNTAData.length > 0 && rawConcatPUMA_CD && rawConcatPUMA_CD.length > 0) {
    const firstRow = crosswalkedNTAData[0];
    Object.keys(firstRow).forEach((key) => {
      const allNA = crosswalkedNTAData.every((row:any) => row[key] === "NA");
      if (allNA) {
        allNAVariables.push(key);
        allNAVarsClean.push(key.replace("_nta", ""));
      }
    });
  }
  console.log('D111124 All NA variables:', allNAVariables);

    //console.log('D111124 All NA variables:', allNAVariables);
    const _crosswalkedNTAData = JSON.parse(JSON.stringify(crosswalkedNTAData));
    if (_crosswalkedNTAData && rawConcatPUMA_CD && rawConcatPUMA_CD.length > 0) {
        const concatNTA_CD = JSON.parse(JSON.stringify(_crosswalkedNTAData)).map((row: any, indexCD:number) => {
          console.log('B111124 row:', row);
          let _cdRow = _rawCDData.filter((crosswalkedRow: any, index:number) => {
            if (row.GEO_ID_CD !== ""){
              return crosswalkedRow.GEO_ID == row.GEO_ID_CD;
            }
          })[0];
          //console.log('E111124 _cdRow:', _cdRow);
          //console.log('C111124 row:', row);
          if (_cdRow){
            Object.keys(_cdRow).forEach((key: string) => {
                //console.log('D100924 key', key);  
                let cleanedKey = key.replace("_cd", "_nta"); 
                //console.log('D100924 Object.keys(row)', Object.keys(row));  
                //if (row[cleanedKey] === "NA"){
                //if (!Object.keys(row).includes(cleanedKey)){
                  //console.log('EE100924 key', key);
                  //row[cleanedKey] = _cdRow[key];
                  if (allNAVariables.includes(cleanedKey)){
                    //console.log('EE111124 key', key);
                    //console.log('EE111124 cleanedKey', cleanedKey);
                    //console.log('EE111124 _cdRow[key]', _cdRow[key]);
                    //row[key] = _cdRow[key];
                    row[cleanedKey] = _cdRow[key];
                  }
                //}
            });
          };
          return row;
        });

        console.log('E111124 concatNTA_CD:', concatNTA_CD);
        const transformedNTA_CD = transformData(concatNTA_CD, "_nta", allNAVarsClean);
        console.log('C112024 transformedNTA_CD:', transformedNTA_CD);
        setDataNTA(transformedNTA_CD);
        setIsLoadingNTA(false);
    }
  }, [rawCDData, crosswalkedNTAData, rawConcatPUMA_CD]);


  useEffect(() => {
    //console.log('121524 pointDataList', pointDataList);
    //console.log('121524 pointDataList.length', pointDataList.length);
    if(pointDataList.length > 0){
      console.log('A022625 ----- pointDataList', pointDataList);
      pointDataList.forEach((pointData) => {
        console.log(`A022625 -- ${pointData}`);
        if (pointData === "NYCHA Developments" || pointData === "Parks"){ // POLYGON DATA
          const filePath = `/data/geo/foi/${pointData}.json`;
          fetch(filePath)
          .then(response => response.json())
          .then((polyTOPO: any) => {
            console.log(`A022625 Topojson ${pointData} data loaded:`, polyTOPO);
            const geoJson: FeatureCollection = feature(polyTOPO, polyTOPO.objects.collection) as any;
            setDataPoint((prevData: any) => ({
              ...prevData,
              [pointData]: {
                data: geoJson,
                active: false
              }
            }));
            //const geoJsonGlobe: FeatureCollection = feature(topojsonGlobeData, topojsonGlobeData.objects.collection) as any;
            //setGeoJsonDataGlobe(geoJsonGlobe);
            //setIsLoadingGlobeTopology(false);
          })
          .catch((err) => {
            console.error(`A121524 Error loading data for ${pointData}:`, err.message);
            //setError(err.message);
            //setIsLoadingGlobeTopology(false);
          });
        }else{
          const filePath = `/data/geo/foi/${pointData}.csv`;
          Papa.parse<any>(filePath, {
            download: true,
            header: true,
            complete: (result: ParseResult<any>) => {
              console.log(`CC012825 Data for ${pointData}:`, result.data);
              setDataPoint((prevData: any) => ({
                ...prevData,
                [pointData]: {
                  data: result.data,
                  active: false
                }
              }));
              // Process the data as needed
            },
            error: (err) => {
              console.error(`A121524 Error loading data for ${pointData}:`, err.message);
            }
          });
        }
      });
      Papa.parse<any>('/data/tabular/D2G_Point_Meta.csv', {
        download: true,
        header: true,
        complete: (result: ParseResult<any>) => {
          console.log(`H121524 Data for point Metadata:`, result.data);
          let transformedPointMeta =  transformPointMeta(result.data);
          console.log(`H121524 transformedPointMeta`, transformedPointMeta);
          setPointMeta(transformedPointMeta);
        },
        error: (err) => {
          console.error(`H121524 Error loading data for point Metadata:`, err.message);
        }
      });
      setIsLoadingPoint(false);
    }
  }, [pointDataList]);

  useEffect(() => {
    console.log("CC121524 dataPoint", dataPoint);
  }, [dataPoint]);

  useEffect(() => {
    console.log("GG121624 pointModules", pointModules);
  }, [pointModules]);

  useEffect(() => {
    console.log("GG121624 pointMeta", pointMeta);
  }, [pointMeta]);

  useEffect(() => {
    Papa.parse<any>("/data/tabular/ORDER_Stat_Explorer.csv", {
      download: true,
      header: true,
      complete: (_result: ParseResult<any>) => {
        //const transformedData = transformData(result.data);
        console.log("Z031025 Stat Explorer _result", _result);  
        //const data = result.data;
        if (_result.data) {
          const result: Record<string, any> = {};
          const data = _result.data;
        
          let lastCategory = '';    // Store last non-empty CATEGORY
          let lastSubCategory = ''; // Store last non-empty SubCategory
        
          data.forEach((item) => {
            let { CATEGORY, SubCategory, "Current Module ID": CurrentModuleID, "Unique Module ID": UniqueModuleID } = item;
        
            // If CATEGORY is empty, use the previous CATEGORY
            if (CATEGORY) {
              lastCategory = CATEGORY;
            } else {
              CATEGORY = lastCategory;
            }
        
            // Ensure CATEGORY level exists
            if (!result[CATEGORY]) {
              result[CATEGORY] = {};
            }
        
            // If SubCategory is empty, use the previous SubCategory
            if (SubCategory) {
              lastSubCategory = SubCategory;
            } else {
              SubCategory = lastSubCategory;
            }
        
            // Ensure SubCategory level exists under CATEGORY
            if (!result[CATEGORY][SubCategory]) {
              result[CATEGORY][SubCategory] = {};
            }
        
            // Handle multiple instances of Current Module ID under SubCategory
            /*if (CurrentModuleID) {
              result[CATEGORY][SubCategory][CurrentModuleID] = item;
            }*/
            if (UniqueModuleID) {
              result[CATEGORY][SubCategory][UniqueModuleID] = item;
            }
          });
        
          console.log("A111424 result", result);

          const desiredOrder = ["Core Stats", "Health", "Education", "Work + Wealth", "Safety", "Civics", "Physical World"];

          // Rearrange the object keys
          const reorderedResult = Object.fromEntries(
            desiredOrder.map(key => [key, result[key]])
          );
          setDataSorterOrder(reorderedResult);
        }
        

      },
      error: (err) => {
        setError(err.message);
        setIsLoadingSorter(false);
      }
    });
  }, []);

  useEffect(() => {
    Papa.parse<any>("/data/tabular/ORDER_Data_Stories.csv", {
      download: true,
      header: true,
      complete: (_result: ParseResult<any>) => {
        //const transformedData = transformData(result.data);
        console.log("Z031025 _result", _result);  
        //const data = result.data;
        if (_result.data) {
          const result: Record<string, any> = {};
          const data = _result.data;
        
          let lastCategory = '';    // Store last non-empty CATEGORY
          let lastSubCategory = ''; // Store last non-empty SubCategory
        
          data.forEach((item) => {
            let { CATEGORY, SubCategory, "Current Module ID": CurrentModuleID, "Unique Module ID": UniqueModuleID } = item;
        
            // If CATEGORY is empty, use the previous CATEGORY
            if (CATEGORY) {
              lastCategory = CATEGORY;
            } else {
              CATEGORY = lastCategory;
            }
        
            // Ensure CATEGORY level exists
            if (!result[CATEGORY]) {
              result[CATEGORY] = {};
            }
        
            // If SubCategory is empty, use the previous SubCategory
            if (SubCategory) {
              lastSubCategory = SubCategory;
            } else {
              SubCategory = lastSubCategory;
            }
        
            // Ensure SubCategory level exists under CATEGORY
            if (!result[CATEGORY][SubCategory]) {
              result[CATEGORY][SubCategory] = {};
            }
        
            // Handle multiple instances of Current Module ID under SubCategory
            /*if (CurrentModuleID) {
              result[CATEGORY][SubCategory][CurrentModuleID] = item;
            }*/
            if (UniqueModuleID) {
              result[CATEGORY][SubCategory][UniqueModuleID] = item;
            }
          });
        
          console.log("A031125 result", result);

          const desiredOrder = ["Core Stats", "Health", "Education", "Work + Wealth", "Safety", "Civics", "Physical World"];

          // Rearrange the object keys
          //const reorderedResult = Object.fromEntries(
          //  desiredOrder.map(key => [key, result[key]])
          //);
          setDataSorterOrderStories(result);
          //setDataSorterOrderStories(reorderedResult);
        }
        

      },
      error: (err) => {
        setError(err.message);
        setIsLoadingSorter(false);
      }
    });
  }, []);

  useEffect(() => {
    Papa.parse<any>("/data/tabular/ORDER_Dashboard.csv", {
      download: true,
      header: true,
      complete: (_result: ParseResult<any>) => {
        //const transformedData = transformData(result.data);
        console.log("A110424 _result", _result);  
        //const data = result.data;
        if (_result.data) {
          const result: Record<string, any> = {};
          const data = _result.data;
        
          let lastCategory = '';    // Store last non-empty CATEGORY
          let lastSubCategory = ''; // Store last non-empty SubCategory
        
          data.forEach((item) => {
            let { CATEGORY, "Current Module ID": CurrentModuleID, "Unique Module ID": UniqueModuleID,  "Order of appearance": OrderOfAppearance, Icon, Style} = item;
            let SubCategory = "remove me";
            // If CATEGORY is empty, use the previous CATEGORY
            if (CATEGORY) {
              lastCategory = CATEGORY;
            } else {
              CATEGORY = lastCategory;
            }
        
            // Ensure CATEGORY level exists
            if (!result[CATEGORY]) {
              result[CATEGORY] = {};
            }
        
            // If SubCategory is empty, use the previous SubCategory
            /*if (SubCategory) {
              lastSubCategory = SubCategory;
            } else {
              SubCategory = lastSubCategory;
            }*/
        
            // Ensure SubCategory level exists under CATEGORY
            if (!result[CATEGORY][SubCategory]) {
              result[CATEGORY][SubCategory] = {};
            }
        
            // Handle multiple instances of Current Module ID under SubCategory
            /*if (CurrentModuleID) {
              result[CATEGORY][SubCategory][CurrentModuleID] = item;
            }*/
            if (UniqueModuleID) {
              result[CATEGORY][SubCategory][UniqueModuleID] = item;
            }
          });
        
          console.log("A111424 result", result);
          console.log("A011125 result", result)
          const desiredOrder = ["Core Stats", "Health", "Education", "Work + Wealth", "Safety", "Civics", "Physical World", "Custom"];

          // Rearrange the object keys
          const reorderedResult = Object.fromEntries(
            desiredOrder.map(key => [key, result[key]])
          );
          console.log("A011125 reorderedResult", reorderedResult)
          setDataSorterOrderDashboard(reorderedResult);
        }
        

      },
      error: (err) => {
        setError(err.message);
        setIsLoadingSorterDashboard(false);
      }
    });
  }, []);

  useEffect(() => {
    Papa.parse<FlatFileCsvData>("/data/tabular/NYCDATA2GO_sorter.csv", {
      download: true,
      header: true,
      complete: (result: ParseResult<FlatFileCsvData>) => {
        //const transformedData = transformData(result.data);
        
        const data = result.data;
        //if (result.data){
          let __meta: { [key: string]: any } = {};
          result.data.forEach((row:any, i:number) => {
            //if (i < 10){
              //console.log("B092424 row", row);  
              Object.keys(row).forEach((item:any, ii:number) => {
                //console.log("B092424 item", item);
                //console.log("B092424 row[item]", row[item]);
                //console.log("B092424 row[VARIABLE_NAME]", row["VARIABLE_NAME"]);
                let _key = item;//row["VARIABLE_NAME"];
                let _obj = { [row["VARIABLE_NAME"]]: row[item] };
                //console.log("B092424 _obj", _obj);
                //let _key = item; //row["VARIABLE_NAME"];
                if (__meta[_key]) {
                    __meta[_key] = { ...__meta[_key], ..._obj };
                } else {
                  __meta[_key] = _obj;
                }
              });
            //}
          });
          console.log("B110424 __meta", __meta);
          setDataSorterMeta(__meta);
        //}   
        // Function to dynamically transform each row of data
        //const transformData = (data: any) => {
          // Create a new object to store the transformed data
          //const transformedObject: any = {};
    
          // Loop through each key-value pair in the current row
          /*data.forEach((line: any) => {
            console.log('B092324 line', line);
            if (meta["VARIABLE_NAME"]) {
              meta["VARIABLE_NAME"] = { ...meta["VARIABLE_NAME"], ...row };
            } else {
              meta["VARIABLE_NAME"] = row;
            }
          });*/
    
          //return transformedObject;
        //};
    
        // Apply the transformation to all rows in the dataset
        //const transformedData = transformData(data); //data.map(row => transformData(row));
    
        // Output or further process the transformed data as required
        //console.log(transformedData);
    
        console.log('B110424 data', data); // Now the data is transposed
        //console.log('B092324 transformedData', transformedData); // Now the data is transposed
        //console.log('B092324 dataSorterMeta', dataSorterMeta);
        setDataSorter(result.data);
        setIsLoadingSorter(false);
      },
      error: (err) => {
        setError(err.message);
        setIsLoadingSorter(false);
      }
    });
  }, []);

  useEffect(() => {
    Papa.parse<FlatFileCsvData>("/data/tabular/NYCDATA2GO_sorter_dashboard.csv", {
      download: true,
      header: true,
      complete: (result: ParseResult<FlatFileCsvData>) => {
        //const transformedData = transformData(result.data);
        
        const data = result.data;
        //if (result.data){
          let __meta: { [key: string]: any } = {};
          result.data.forEach((row:any, i:number) => {
            //if (i < 10){
              //console.log("B092424 row", row);  
              Object.keys(row).forEach((item:any, ii:number) => {
                //console.log("B092424 item", item);
                //console.log("B092424 row[item]", row[item]);
                //console.log("B092424 row[VARIABLE_NAME]", row["VARIABLE_NAME"]);
                let _key = item;//row["VARIABLE_NAME"];
                let _obj = { [row["VARIABLE_NAME"]]: row[item] };
                //console.log("B092424 _obj", _obj);
                //let _key = item; //row["VARIABLE_NAME"];
                if (__meta[_key]) {
                    __meta[_key] = { ...__meta[_key], ..._obj };
                } else {
                  __meta[_key] = _obj;
                }
              });
            //}
          });
          console.log("C110424 __meta", __meta);
          setDataSorterMetaDashboard(__meta);
        //}   
        // Function to dynamically transform each row of data
        //const transformData = (data: any) => {
          // Create a new object to store the transformed data
          //const transformedObject: any = {};
    
          // Loop through each key-value pair in the current row
          /*data.forEach((line: any) => {
            console.log('B092324 line', line);
            if (meta["VARIABLE_NAME"]) {
              meta["VARIABLE_NAME"] = { ...meta["VARIABLE_NAME"], ...row };
            } else {
              meta["VARIABLE_NAME"] = row;
            }
          });*/
    
          //return transformedObject;
        //};
    
        // Apply the transformation to all rows in the dataset
        //const transformedData = transformData(data); //data.map(row => transformData(row));
    
        // Output or further process the transformed data as required
        //console.log(transformedData);
    
        console.log('C110424 data', data); // Now the data is transposed
        //console.log('B092324 transformedData', transformedData); // Now the data is transposed
        //console.log('B092324 dataSorterMeta', dataSorterMeta);
        setDataSorterDashboard(result.data);
        setIsLoadingSorterDashboard(false);
      },
      error: (err) => {
        setError(err.message);
        setIsLoadingSorterDashboard(false);
      }
    });
  }, []);

  useEffect(() => {
    if (!isLoadingSorter && !isLoadingSorterDashboard && dataSorterMeta) {
    // Example CSV file loading and parsing
    // Ensure to replace "path/to/your/data.csv" with the actual path to your CSV file
    Papa.parse<FlatFileCsvData>("/data/tabular/D2G_Flatfile_Tract.csv", {
    //Papa.parse<FlatFileCsvData>("/data/tabular/nyc_data2go_flatfile_master_2_1_21.xlsx - Tract.csv", {
      download: true,
      header: true,
      complete: (result: ParseResult<FlatFileCsvData>) => {
        federateVariables(result.data, "_tract");
        const transformedData = transformData(result.data, "_tract");
        //console.log('C092524 Tract data loaded:', transformedData);
        //setDataActive(transformedData); // Tract is default upon load
        setDataTract(transformedData);
        setIsLoadingTract(false);
      },
      error: (err) => {
        setError(err.message);
        setIsLoadingTract(false);
      }
    });
    Papa.parse<FlatFileCsvData>("/data/tabular/D2G_Flatfile_NTA.csv", {
      download: true,
      header: true,
      complete: (result: ParseResult<FlatFileCsvData>) => {
        //let culled = cullUnwantedNTAs(result.data);
        federateVariables(result.data, "_nta");
        const _crosswalkedNTAData = crosswalkNTA(result.data); // adds the GEO_ID for CD to the NTA data
        console.log('A111124 _crosswalkedNTAData', _crosswalkedNTAData);
        setCrosswalkedNTAData(_crosswalkedNTAData);
        //const transformedData = transformData(result.data, "_nta");
        //console.log('B100524 NTA data loaded:', transformedData);
        //setDataActive(transformedData); // Tract is default upon load
        //setDataNTA(transformedData);
        //setIsLoadingNTA(false);
      },
      error: (err) => {
        setError(err.message);
        setIsLoadingNTA(false);
      }
    });
    Papa.parse<FlatFileCsvData>("/data/tabular/D2G_Flatfile_Puma.csv", {
      download: true,
      header: true,
      complete: (result: ParseResult<FlatFileCsvData>) => {
        console.log('D112024 PUMA data raw:', result.data);
        federateVariables(result.data, "_puma");
        const _crosswalkedPUMAData = crosswalkPUMA(result.data);
        console.log('A111124 _crosswalkedPUMAData', _crosswalkedPUMAData);
        setCrosswalkedPUMAData(_crosswalkedPUMAData);
        //const transformedData = transformData(_crosswalkedPUMAData, "_puma");
        //console.log('C081624 PUMA data loaded:', transformedData);
        //setDataPUMA(transformedData);
        //setIsLoadingPUMA(false);
      },
      error: (err) => {
        setError(err.message);
        setIsLoadingPUMA(false);
      }
    });
    //Papa.parse<FlatFileCsvData>("/data/tabular/nyc_data2go_flatfile_master_2_1_21.xlsx - NYC.csv", {
    Papa.parse<FlatFileCsvData>("/data/tabular/D2G_Flatfile_NYC.csv", {
      download: true,
      header: true,
      complete: (result: ParseResult<FlatFileCsvData>) => {
        federateVariables(result.data, "_nyc");
        const transformedData = transformData(result.data, "_nyc");
        setDataNYC(transformedData);
        setIsLoadingNYC(false);
      },
      error: (err) => {
        setError(err.message);
        setIsLoadingNYC(false);
      }
    });
    //Papa.parse<FlatFileCsvData>("/data/tabular/nyc_data2go_flatfile_master_2_1_21.xlsx - CD.csv", {
    Papa.parse<FlatFileCsvData>("/data/tabular/D2G_Flatfile_CD.csv", {
      download: true,
      header: true,
      complete: (result: ParseResult<FlatFileCsvData>) => {
        federateVariables(result.data, "_cd");
        setRawCDData(result.data);
        //const transformedData = transformData(result.data, "_cd");
        //console.log('C081624 CD data loaded:', transformedData);
        //console.log('D041824 activeGeography', activeGeography);
        //setDataActive(transformedData);
        //setDataCD(transformedData);
        //setIsLoadingCD(false);
      },
      error: (err) => {
        setError(err.message);
        setIsLoadingCD(false);
      }
    });
    //Papa.parse<FlatFileCsvData>("/data/tabular/nyc_data2go_flatfile_master_2_1_21.xlsx - Boro.csv", {
    Papa.parse<FlatFileCsvData>("/data/tabular/D2G_Flatfile_Boro.csv", {
      download: true,
      header: true,
      complete: (result: ParseResult<FlatFileCsvData>) => {
        federateVariables(result.data, "_boro");
        console.log('C100524 Boro data result:', result);
        const transformedData = transformData(result.data, "_boro");
        console.log('C100524 Boro data loaded:', transformedData);
        setDataBoro(transformedData);
        setIsLoadingBoro(false);
      },
      error: (err) => {
        setError(err.message);
        setIsLoadingBoro(false);
      }
    });
    fetch('/data/geo/ALL_GEO_2020.json')
    .then(response => response.json())
    .then((topojsonData: any) => {
      console.log('Topojson data loaded:', topojsonData);
      //const geoJsonTract: FeatureCollection = feature(topojsonData, topojsonData.objects.TRACT_2010) as any;
      const geoJsonTract: FeatureCollection = feature(topojsonData, topojsonData.objects.Tract) as any;
      //const geoJsonPUMA: FeatureCollection = feature(topojsonData, topojsonData.objects.PUMA_2010) as any;
      let lookup: any = {
        MN01:101,
        MN02:102,
        MN03:103,
        MN04:104,
        MN05:105,
        MN06:106,
        MN07:107,
        MN08:108,
        MN09:109,
        MN10:110,
        MN11:111,
        MN12:112,
        BX01:201,
        BX02:202,
        BX03:203,
        BX04:204,
        BX05:205,
        BX06:206,
        BX07:207,
        BX08:208,
        BX09:209,
        BX10:210,
        BX11:211,
        BX12:212,
        BK01:301,
        BK02:302,
        BK03:303,
        BK04:304,
        BK05:305,
        BK06:306,
        BK07:307,
        BK08:308,
        BK09:309,
        BK10:310,
        BK11:311,
        BK12:312,
        BK13:313,
        BK14:314,
        BK15:315,
        BK16:316,
        BK17:317,
        BK18:318,
        QN01:401,
        QN02:402,
        QN03:403,
        QN04:404,
        QN05:405,
        QN06:406,
        QN07:407,
        QN08:408,
        QN09:409,
        QN10:410,
        QN11:411,
        QN12:412,
        QN13:413,
        QN14:414,
        SI01:501,
        SI02:502,
        SI03:503
      };
      //console.log('C093024 topojsonData.objects.CD', topojsonData.objects.CD);
      let newGeometries:any = [];
      topojsonData.objects.CD.geometries.forEach((geometry: any) => {
        const originalGEOID = geometry.properties.GEOID20;
        if (lookup[originalGEOID]) {
          //console.log('C093024', originalGEOID);
          geometry.properties.GEOID20 = lookup[originalGEOID];
          newGeometries.push(geometry);
        }else{
          //console.log('C093024 Removing geometry:', originalGEOID);
          topojsonData.objects.CD.geometries = topojsonData.objects.CD.geometries.filter((g: any) => g.properties.GEOID20 !== originalGEOID);
          //console.log('C093024 CD data lookup failed:', originalGEOID);
        }
      });

      topojsonData.objects.NTA.geometries.forEach((geometry: any) => {
        const originalNTAGEOID = geometry.properties.GEOID20;
        if (lookupNTARedact.includes(originalNTAGEOID)) {
          topojsonData.objects.NTA.geometries = topojsonData.objects.NTA.geometries.filter((g: any) => g.properties.GEOID20 !== originalNTAGEOID);
        }
      });
      console.log('112524 topojsonData.objects.NTA', topojsonData.objects.NTA);
      console.log('C093024 topojsonData.objects.CD', topojsonData.objects.CD);

      console.log('F092424 CD data lookup:', lookup);
      const geoJsonPUMA: FeatureCollection = feature(topojsonData, topojsonData.objects.CD) as any;
      const geoJsonNTA: FeatureCollection = feature(topojsonData, topojsonData.objects.NTA) as any;
      const geoJsonBoro: FeatureCollection = feature(topojsonData, topojsonData.objects.Boro) as any;
      setGeoJsonDataTract(geoJsonTract);
      console.log("B100524 geoJsonNTA:", geoJsonNTA);
      console.log("C100524 geoJsonBoro:", geoJsonBoro);
      setGeoJsonDataNTA(geoJsonNTA);
      setGeoJsonDataBoro(geoJsonBoro);
      setGeoJsonDataPUMA(geoJsonPUMA);
      setIsLoadingTopology(false);
    })
    .catch((err) => {
      console.error('Error loading the topojson data:', err)
      setError(err.message);
      setIsLoadingTopology(false);
    });
    fetch('/data/geo/worldCleanGeoJSONCleaned.json')
    .then(response => response.json())
    .then((topojsonGlobeData: any) => {
      console.log('061024 Topojson globe data loaded:', topojsonGlobeData);
      const geoJsonGlobe: FeatureCollection = feature(topojsonGlobeData, topojsonGlobeData.objects.collection) as any;
      setGeoJsonDataGlobe(geoJsonGlobe);
      setIsLoadingGlobeTopology(false);
    })
    .catch((err) => {
      console.error('Error loading the global topojson data:', err)
      setError(err.message);
      setIsLoadingGlobeTopology(false);
    });
  }
  }, [isLoadingSorter, isLoadingSorterDashboard]);

  function crosswalkPUMA(rawData: any[]): any {
    //console.log("G092524, rawData", rawData);
    let crossWalkedData = rawData.flatMap((row: any, i: number) => {
      row["GEO_ID_PUMA"] = row["GEO_ID"];
      if (row.GEO_ID === "") {
        //console.log("081624, row", row);
        return row;  // Will be flattened to a single element
      } else {
        //console.log("B092524, row", row);
        let _CDs = crosswalkPUMAtoCD(row["GEO_ID"]);
        //console.log("G092524, _CDs", _CDs);
        //console.log("A081624, row", row);
        if (_CDs?.length > 1) {
          const copies = _CDs.map((cd: string) => {
            //console.log("A081624, cd", cd);
            const copy = { ...row };
            //console.log("A081624, copy", copy);
            copy["GEO_ID"] = cd;
            return copy;
          });
          return copies;  // Array of copies will be flattened into the result
        } else {
          row["GEO_ID"] = _CDs[0];
          return row;  // Will be flattened to a single element
        }
      }
    });
  
    //console.log("G092524, crossWalkedData", crossWalkedData);
    return crossWalkedData;
  }
 
  function crosswalkNTA(rawData: any[]): any {
    //console.log("G092524, rawData", rawData);
    let crossWalkedData = rawData.flatMap((row: any, i: number) => {
      if (lookupNTARedact.includes(row.GEO_ID)) {
        return []; // Remove this row by returning an empty array
      }
      row["GEO_ID_CD"] = "";//row["GEO_ID"];
      if (row.GEO_ID === "") {
        //console.log("081624, row", row);
        return row;  // Will be flattened to a single element
      } else {
        //console.log("B092524, row", row);
        let CD = crosswalkNTAtoCD(row["GEO_ID"]);
        //console.log("111124, CD", CD);
        row["GEO_ID_CD"] = CD;
        return row;  // Will be flattened to a single element
      }
    });
  
    //console.log("111124, NTA crossWalkedData", crossWalkedData);
    return crossWalkedData;
  }

  function federateVariables(rawData: any, strip:string = "") {
    /* Turning off for now, this would be a way of dynamically adding variables to the federatedVariables state for utility in adding/crosswalking variables to other geos.
    const uniqueKeys:string[] =  federatedVariables;
    const uniqueGeos:string[] =  federatedGeos;
    Object.keys(rawData[0]).forEach(key => {
      if (strip !== ""){ 
        key = key.replace(new RegExp(strip + '$'), '');
      }
      if (!uniqueKeys.includes(key)) {
        uniqueKeys.push(key);
      }
    });
    console.log('D112024 Unique keys:', uniqueKeys);
    uniqueGeos.push(strip);
    setFederatedGeos(uniqueGeos);
    setFederatedVariables(uniqueKeys);
    setFederatedIncrement(federatedIncrement + 1);*/
  }

  function transformPointMeta(rawData: any[]): any {
      const transformedData: { [key: string]: any } = {};
      rawData.forEach((row) => {
        const sourceTab = row.SOURCE_TAB;
        const subIndicatorVariableName = row.SUBINDICATOR_VARIABLE_NAME === "" ? "META" : row.SUBINDICATOR_VARIABLE_NAME;

        if (!transformedData[sourceTab]) {
          transformedData[sourceTab] = {};
        }

        transformedData[sourceTab][subIndicatorVariableName] = row;
      });

      return transformedData;
  }
  function transformData(rawData: any[], caller:string, allNAVars?: any): FlatFileCsvData {
    //let __meta: { [key: string]: any } = dataSorter ? dataSorter[0] : null;
    allNAVars = allNAVars ? allNAVars : [];

    let meta: { [key: string]: any } = { ...dataSorterMeta };
    let dataJSON: { [key: string]: any } = {};
    let dataArray: { [key: string]: any[] } = {};
    let keyIndexDataArray: { [key: string]: any[] } = {};

    console.log("D012425 allNAVars", allNAVars);
    console.log("A011925 rawData", rawData);
    console.log("III092424 caller", caller);
    console.log("A011925 dataSorterMeta", dataSorterMeta);
    console.log("A011925 meta", meta);
    rawData.forEach((row:any, i:number) => {
      if (row.GEO_ID === ""){
        const _key = !(row["Base Variable"] === "") ? row["Base Variable"] : `Row_${i}`;
        //console.log("D092324 _key", _key);
        //console.log("D092324 row", row);
        /*if (meta[_key]) {
          meta[_key] = { ...meta[_key], ...row };
        } else {
          meta[_key] = row;
        }*/
      }else{
        Object.keys(row).forEach(key => {
          // Check if the value is a number by trying to parse it
          let skipThis = false;
          let newKey = key.replace(new RegExp(caller + '$'), '');
          if (caller === "_cd"){
            newKey = newKey.replace(new RegExp("_puma" + '$'), '');
          }
          if (caller === "_nta"){
            newKey = newKey.replace(new RegExp("_cd" + '$'), '');
            if (key.slice(-3) === "_cd") {
              skipThis=true;
            }
            if (key.slice(-4) === "_nta") {
              skipThis=true;
            }
          }
          //if (!skipThis){
            let _val = row[key];
            if (key !== "GEO_ID"){
              //console.log(key);
              const parsedValue = parseFloat(_val);
              // If the parsed value is a number and is not NaN, use it; otherwise, keep the original value
              _val = !isNaN(parsedValue) ? parsedValue : row[key];
              row[newKey] = _val;
            }
            dataArray[newKey] ? dataArray[newKey].push({ id: row["GEO_ID"], value: _val }) : dataArray[newKey] = [{ id: row["GEO_ID"], value: _val }];
          //}
        });
        dataJSON[row.GEO_ID] = row;
      }
    });
    console.log("B111124 dataArray", dataArray);
    //console.log("A092424 rawData", rawData);
    //console.log("A092424 dataSorter", dataSorter);
    //console.log("B092424 meta", meta);
    Object.keys(dataArray).forEach(key => {
      dataArray[key].sort((a, b) => {
        let _a = a.value;
        let _b = b.value;
        if (a.value === "NA" ){
          _a = -999999999
        }
        if (b.value === "NA" ){
          _b = -999999999
        }
        return _a - _b
      });
      let histMax = -Infinity; // Start with the lowest possible number
      let histMin = Infinity; // Start with the highest possible number
      let histNA = 0; // Count of "NA" values
      let histLength = 0; // Count of "NA" values
      let total = 0; // Count of "NA" values
      const keyIndex = dataArray[key].reduce((accumulator:any, currentValue:any, index:number) => {
        if (currentValue.value === "NA") {
          histNA += 1;
        } else {
          const value = parseFloat(currentValue.value);
          total += value ? value : 0;
          if (!isNaN(value) && value > histMax) {
        histMax = value;
          }
          if (!isNaN(value) && value < histMin) {
        histMin = value;
          }
        }
        accumulator[currentValue.id] = {value: currentValue.value, index: index};
        return accumulator;
      }, {});

      keyIndexDataArray[key] = keyIndex;
      //console.log("III092424 meta['length']", meta["length"]);
      //console.log("III092424 dataArray", dataArray);
      if (!meta["max_value"]) { meta["max_value"] = {} };
      if (!meta["min_value"]) { meta["min_value"] = {} };
      if (!meta["na_count"]) { meta["na_count"] = {} };
      if (!meta["length"]) { meta["length"] = {} };
      if (!meta["provenance"]) { meta["provenance"] = {} };
      if (!meta["avg"]) { meta["avg"] = {} };
      meta["max_value"][key] = histMax;
      meta["min_value"][key] = histMin;
      meta["na_count"][key] = histNA;
      meta["length"][key] = dataArray[key].length;
      meta["provenance"][key] = allNAVars.includes(key) ? caller : "NA";
      //console.log("A011925 key", key);
      //console.log("A011925 dataArray[key]", dataArray[key]);
      const avg = total / dataArray[key].length;
      //console.log("A011925 avg", avg);
      meta["avg"][key] = avg;
    });
    return {"meta": meta, "dataJSON": dataJSON, "dataArray": dataArray, "keyIndexDataArray": keyIndexDataArray};
  }
  
  return (
    <DataContext.Provider value={{ 
      dataActive, dataTract, dataNTA, dataPUMA, dataNYC, dataCD, dataBoro, dataSorter, dataSorterOrder, dataSorterOrderStories, dataSorterDashboard, dataSorterOrderDashboard, geoJsonDataPUMA, geoJsonDataTract, geoJsonDataNTA, geoJsonDataBoro, geoJsonDataGlobe,
      isLoadingTract, isLoadingNTA, isLoadingPUMA, isLoadingNYC, isLoadingCD, isLoadingBoro, isLoadingAllTabularData, isLoadingSorter, isLoadingTopology, isLoadingGlobeTopology, error, pointDataList, setPointDataList, dataPoint, isLoadingPoint, setDataPoint, setPointModules, pointModules, pointMeta }}>
      {children}
    </DataContext.Provider>
  );
};

// Custom hook to use the context
export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};
