import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useGlobalSelectedId, onSetActiveIndicator, onSetActiveDBModuleId, onSetActiveModuleId, useGlobalActivePage } from '../../data/StatusStore';
import { d2gRound } from '../utilities/Utilities';
import Colors from '../utilities/Colors';
//import DBWaffleMeta from './DBWaffleMeta';

export interface DBWaffleChartProps {
  options: any;
  data: any;
  width: number;
  height: number;
  colorClass:string;
}
const colors = Colors.moaReds;
//const colors = Colors.wafflePieColors;

const DBWaffle: React.FC<DBWaffleChartProps> = ({ options, data, width, height, colorClass }) => {
  const [preppedData, setPreppedData] = useState<any>([]);
  const [hoveredIndicator, setHoveredIndicator] = useState<any>(null);
  const [tooltip, setTooltip] = useState<{ x: number; y: number; obj: any } | null>(null); // Tooltip state
  const selectedId = useGlobalSelectedId();
  const activePage = useGlobalActivePage();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({ width, height });
  //console.log("H011825 DBWaffle options", options);
  //console.log("H011825 DBWaffle data", data);
  //console.log("H011825 DBWaffle selectedId", selectedId);

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        console.log("J012325 entries", entries);
        if (!entries || entries.length === 0) {
          return;
        }
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        if (containerRef.current) {
          resizeObserver.unobserve(containerRef.current);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (selectedId) {
      prepData();
    }
  }, [selectedId]);

  useEffect(() => {
    if (selectedId) {
      prepData();
    }
  }, []);

  const prepData = () => {
    if (selectedId) {
      const _filteredData = options.variables.filter((v: any) => {
        //if ((v.sort.Tooltip_only === "")) {
        if ((v.sort.Tooltip_only === "") && (v.sort["Shape Role"] !== "bar") && (v.sort["Shape Role"] !== "one col") && (v.sort["Shape Role"] !== "two col")) {
          return { v };
        }
      });
      const _tooltipData = options.variables.filter((v: any) => v.sort.Tooltip_only !== "");
      
      const _preppedData = _filteredData.map((v: any) => {
          const ttVariable = _tooltipData.find((t: any) => t.sort.Tooltip_only === v.variable);
          const tooltip = ttVariable ? data.dataJSON[selectedId][ttVariable.variable] : null;
          const value = data.dataJSON[selectedId][v.variable]
          const presentableTooltip = tooltip 
            ? d2gRound(tooltip, { DESCRIPTOR: data.meta["DESCRIPTOR"][ttVariable] }) 
            : d2gRound(value, { DESCRIPTOR: data.meta["DESCRIPTOR"][v.variable] }) 
  
          console.log("111824 presentableTooltip", presentableTooltip)
          console.log("111824 data.meta[DISPLAY_NAME]",  data.meta["DISPLAY_NAME"])
          console.log("111824 ttVariable",  ttVariable)
          const presentableLabel = tooltip 
            ? data.meta["DISPLAY_NAME"][ttVariable.variable] ? data.meta["DISPLAY_NAME"][ttVariable.variable].split("(")[0] : ""
            : data.meta["DISPLAY_NAME"][v.variable] ? data.meta["DISPLAY_NAME"][v.variable].split("(")[0] : ""
          
        return { 
          name: v.label, 
          value: data.dataJSON[selectedId][v.variable], 
          variable: v.variable, 
          tooltip: presentableTooltip,
          tooltipLabel: presentableLabel,
        };
      });

      setPreppedData(_preppedData);
    }
  };

  // Keep gridSize the same as original
  const gridSize = 10; // 10x10 grid

  // CHANGED: Use ONLY the width to determine chartSize, ignoring the height
  // Original: const chartSize = Math.min(dimensions.width / 2, dimensions.height / 2);
  const chartSize = dimensions.width / 2;  // Rely on parent width only

  const cellSize = chartSize / gridSize;

  const dataBlocks = preppedData.map((d: any, i: number) => ({
    ...d,
    blocks: Math.round(d.value),
    roundingDifference: d.value - Math.round(d.value),
    //color: colors[i % colors.length],
    color:  Colors.getPieWaffleColorByClass(colorClass,preppedData.length)[i],
  }));

  const total = preppedData.reduce((acc: number, d: any) => acc + d.value, 0);

  const totalBlocks = dataBlocks.reduce((acc: number, d: any) => acc + d.blocks, 0);
  //console.log("D012525 Total number of dataBlocks.blocks:", totalBlocks);
  //console.log("D012525 Waffle dataBlocks", dataBlocks);
  let ammendBlocks = totalBlocks - 100;
  //console.log("D012525 ammendBlocks", ammendBlocks);
  if (ammendBlocks > 0 && dataBlocks.length > 1) {
    // Sort by largest rounding difference
    const sortedByRoundingDifference = [...dataBlocks].sort((a, b) => b.roundingDifference - a.roundingDifference);
    for (let i = 0; i < ammendBlocks; i++) {
      sortedByRoundingDifference[i % sortedByRoundingDifference.length].blocks--;
    }
  } else if (ammendBlocks < 0 && dataBlocks.length > 1) {
    // Sort by smallest rounding difference
    const sortedByRoundingDifference = [...dataBlocks].sort((a, b) => a.roundingDifference - b.roundingDifference);
    for (let i = 0; i < Math.abs(ammendBlocks); i++) {
      sortedByRoundingDifference[i % sortedByRoundingDifference.length].blocks++;
    }
  }
  let cumulativeBlocks = 0;
  const grid: { row: number; col: number; color: string; stroke: string; variable: string; tooltip:string; tooltipLabel:string; }[] = [];
  dataBlocks.forEach((item: any, index: number) => {
    for (let b = 0; b < item.blocks; b++) {
      const row = Math.floor(cumulativeBlocks / gridSize);
      const col = cumulativeBlocks % gridSize;
      grid.push({ 
        row, col, 
        color: item.color, 
        stroke: "0px solid white", 
        variable: item.variable,
        tooltip: item.tooltip,
        tooltipLabel: item.tooltipLabel,
      });
      cumulativeBlocks++;
    }
  });

  // Fill any remaining blocks with transparent color
  while (cumulativeBlocks < 100) {
    const row = Math.floor(cumulativeBlocks / gridSize);
    const col = cumulativeBlocks % gridSize;
    grid.push({ 
      row, col, 
      color: 'transparent',
      stroke: '1px solid #ccc',
      variable: '',
      tooltip: '',
      tooltipLabel: '', 
     });
    cumulativeBlocks++;
  }

  const onClicked = (variable: any) => {
    if (activePage !== "dashboard") {
      onSetActiveModuleId(options.variables[0].sort.moduleId);
    } else {
      onSetActiveDBModuleId(options.variables[0].sort.moduleId);
    }
    onSetActiveIndicator(variable);
  };

  const onHovered = (variable: any) => {
    options.variables.map((v: any) => {
      if (v.variable === variable) {
        setHoveredIndicator(v);
      }
    });
  };


  const onLeave = (variable: any) => {
    //setHoveredIndicator(null); // uncomment to reset to first listed indicator on mouse leave
  };

    const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>, obj: any) => {
      const rect = containerRef.current?.getBoundingClientRect();
      const { clientX, clientY } = event;
    
      setTooltip({
        x: clientX - (rect?.left ? rect?.left + 20 : 0 || 0),
        y: clientY - (rect?.top ? rect?.top + 60 : 0 || 0),
        obj,
      });
    };
    
    const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
      const rect = containerRef.current?.getBoundingClientRect();
      const { clientX, clientY } = event;
    
      setTooltip((prev) => prev && {
        ...prev,
        x: clientX - (rect?.left ? rect?.left + 20 : 0 || 0),
        y: clientY - (rect?.top ? rect?.top + 60 : 0 || 0),
      });
    };
    
    const handleMouseLeave = () => {
      setTooltip(null);
    };

  const fontSize = 12; // For dynamic heights --> // Math.min(12, Math.max(8, dimensions.width / 50));
  const fontSize2 = 12; // For dynamic heights --> // Math.min(10, Math.max(8, dimensions.width / 50));
  const valueWidth = 120; // Width allocated for the value text
  const labelWidth = dimensions.width - chartSize - valueWidth - 30;

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%', position: 'relative', display: 'flex', alignItems: 'flex-start', paddingRight:"0px" }}>
      {/*<div style={{ 
          marginLeft: '0px', 
          marginRight: '0px', 
          fontSize:fontSize2, 
          lineHeight: `${fontSize2 + 2 }px`,//'1', 
          width: "250px" //`calc(40% - ${(chartSize + 10) / 2}px)` 
        }}>
        <DBWaffleMeta
            dataArray={data.dataArray[hoveredIndicator ? hoveredIndicator : options.variables[0]]}
            data={data}
            indicator={hoveredIndicator ? hoveredIndicator : options.variables[0]}
          />
      </div>*/}
      <div 
        style={{ 
          marginLeft: '0px', 
          fontSize, 
          lineHeight: `${fontSize + 2 }px`, //'1', 
          display: 'flex', 
          flexDirection: 'column', 
          width: '50%', //`calc(100% - ${(chartSize + 10)}px - 250px)`
        }}>
        {preppedData.map((d: any, i: number) => {
          const name = `${d.name}`;// ${Math.round((d.value / total) * 100) === Math.round(d.value) ? "" : `(Error ${Math.round((d.value / total) * 100)}%)`}`;

          return (
            <div key={i} style={{ marginBottom: '5px', cursor: 'pointer', display: 'flex', paddingBottom: '12px' }} onClick={() => onClicked(d.variable)}>
              <div style={{ width: `50px`, fontWeight: 'bold', color: Colors.black, textAlign: 'left', paddingLeft: '0px' }}>
                {d2gRound(d.value)}%
              </div>
              <div 
                style={{ 
                  width: "calc(100% - 50px)", //`${labelWidth}px`, 
                  color: Colors.black, 
                  textAlign: 'left', 
                  whiteSpace: 'normal', 
                  overflowWrap: 'break-word', 
                  textDecoration: `underline ${Colors.getPieWaffleColorByClass(colorClass,preppedData.length)[i]} 2px`,
                  // Use separate properties for Safari compatibility
                  textDecorationLine: 'underline',
                  textDecorationColor: Colors.getPieWaffleColorByClass(colorClass,preppedData.length)[i],
                  textDecorationThickness: '2px',
                  fontWeight: 'bold',
                  fontSize: '13px',
                  // Safari prefixes for older versions
                  WebkitTextDecorationLine: 'underline',
                  WebkitTextDecorationColor: Colors.getPieWaffleColorByClass(colorClass,preppedData.length)[i],
                  //WebkitTextDecorationThickness: '2px',
                }}>
                {name.split("(")[0].replace("Occupational Category: ", "")}
                { data.meta.provenance[d.variable] !== "NA"
                    ? <span
                      style={{ 
                        color:"#F26631",
                      }}
                    > *</span> 
                    : null
                  }
              </div>
            </div>
          );
        })}
      </div>
      <div style={{ 
          display: 'grid', 
          gridTemplateColumns: `repeat(${gridSize}, ${cellSize}px)`, 
          gridTemplateRows: `repeat(${gridSize}, ${cellSize}px)`, 
          gap: '1px', 
          width: '50%', //chartSize + 10, 
          height: chartSize + 10 
        }}>
        {grid.map((cell, i) => (
          <div
            key={i}
            style={{
              width: cellSize,
              height: cellSize,
              backgroundColor: cell.color,
              border: `${cell.stroke}`,
              cursor: 'pointer'
            }}
            onClick={() => onClicked(cell.variable)}
            //onMouseEnter={() => onHovered(cell.variable)}
            //onMouseLeave={() => onLeave(cell.variable)}
            onMouseEnter={(e) => handleMouseEnter(e, cell)}
            onMouseMove={(e) => handleMouseMove(e)}
            onMouseLeave={handleMouseLeave}
          />
        ))}
      </div>
      <div 
        style={{ 
          marginLeft: '0px', 
          fontSize, 
          lineHeight: `${fontSize + 2 }px`, //'1', 
          display: 'flex', 
          flexDirection: 'column', 
          width: `calc(100% - ${(chartSize + 10)}px - 250px)`
        }}>
      </div>
      {tooltip && (
              <div
                style={{
                  position: 'absolute',
                  top: tooltip.y - 20,
                  left: tooltip.x - 42.5,
                  backgroundColor: '#fff',
                  border: '2px solid #ccc',
                  borderRadius: '6px',
                  padding: '8px 12px',
                  pointerEvents: 'none',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
                  fontSize: '12px',
                  zIndex: 10,
                  maxWidth: '150px',
                  width:'120px',
                  wordBreak: 'break-word',
                }}
              >
                <b>{tooltip.obj.tooltip}</b> <span style={{ fontSize: '10px'}}>{tooltip.obj.tooltipLabel}</span>
                <div
                  style={{
                    position: 'absolute',
                    bottom: '-7px',
                    left: '45%',
                    width: '12px',
                    height: '12px',
                    backgroundColor: '#fff',
                    borderLeft: '2px solid #ccc',
                    borderBottom: '2px solid #ccc',
                    transform: 'rotate(-45deg)',
                    boxShadow: '-1px 1px 2px rgba(0, 0, 0, 0.1)',
                  }}
                />
              </div>
            )}
    </div>
  ); 
};

export default DBWaffle;
