import React from 'react';
import * as ss from 'simple-statistics';
import RSlider from './RSliderChart';
import { jStat } from 'jstat';

// Function to calculate t-distribution CDF
function tDistributionCdf(t: number, df: number): number {
  // API DOC --> https://jstat.github.io/all.html#jStat.studentt.cdf
  return jStat.studentt.cdf(t, df);
}

// Helper function to calculate Pearson correlation coefficient and p-value
function pearsonrPval(x: number[], y: number[]): { r: number; p: number } {
  // API DOC --> https://simple-statistics.github.io/docs/#samplecorrelation
  const r = ss.sampleCorrelation(x, y);
  console.log("MOA KEEP R&P pearsonrPval r", r);
  const n = x.length;
  console.log("MOA KEEP R&P pearsonrPval n", n);
  const t = Math.abs(r) * Math.sqrt((n - 2) / (1 - r ** 2));
  console.log("MOA KEEP R&P pearsonrPval t", t);
  const p = 2 * (1 - tDistributionCdf(t, n - 2));
  console.log("MOA KEEP R&P pearsonrPval p", p);
  return { r, p };
}

interface CorrelationCardProps {
  dataArrayX: Record<number, { value: number | null; index: number }>; // Allow null for missing values
  dataArrayY: Record<number, { value: number | null; index: number }>;
  xLabel: string;
  yLabel: string;
  activeIndicator: string;
  bivariateIndicator: string;
}

const CorrelationCard: React.FC<CorrelationCardProps> = ({ dataArrayX, dataArrayY, xLabel, yLabel, activeIndicator, bivariateIndicator}) => {
  // Filter out entries where either x or y is null or NaN
  console.log("MOA KEEP R&P bivariateIndicator", bivariateIndicator);
  console.log("MOA KEEP R&P dataArrayX", dataArrayX);
  console.log("010224 dataArrayX", dataArrayX);
  console.log("010224 Object.values(dataArrayX)", Object.values(dataArrayX));
  console.log("010224 dataArrayY", dataArrayY);
  console.log("010224 Object.values(dataArrayY)", Object.values(dataArrayY));
  console.log("MOA KEEP R&P activeIndicator", activeIndicator);
  console.log("MOA KEEP R&P dataArrayY", dataArrayY);
  const validData = Object.keys(dataArrayX)
    .map((key:any) => ({
      x: dataArrayX[key].value,
      y: dataArrayY[key].value,
    }))
    .filter(({ x, y }) => x !== null && y !== null && !isNaN(x) && !isNaN(y));
  console.log("MOA KEEP R&P validData", validData);
  const xValues = validData.map((item) => item.x as number);
  const yValues = validData.map((item) => item.y as number);
  console.log("MOA KEEP R&P xValues", xValues);
  console.log("MOA KEEP R&P yValues", yValues);
  if (xValues.length !== yValues.length || xValues.length === 0) {
    return <p>Error: Both datasets must have the same length and contain valid data points.</p>;
  }

  const { r, p } = pearsonrPval(xValues, yValues);
  console.log("MOA KEEP R&P r", r);
  console.log("MOA KEEP R&P p", p);
  // Determine strength of correlation
  const strength =
    Math.abs(r) < 0.3
      ? 'weak'
      : Math.abs(r) < 0.6
      ? 'moderate'
      : 'strong';
  console.log("MOA KEEP R&P strength", strength);
  // Reliability checks
  const rReliable = Math.abs(r) >= 0.25 ? 'ok' : 'not significant';
  const pReliable = p < 0.05 ? 'ok' : 'not significant';

  // Direction of correlation
  const correlationDirection = r < 0 ? 'negative' : 'positive';
  const direction = r < 0 ? 'decrease' : 'increase';

  // Summary sentence
  const summary =
  rReliable === 'ok' && pReliable === 'ok'
    ? `There is a ${strength} <b>${correlationDirection}</b> correlation between <b>${xLabel}</b> and <b>${yLabel}</b>. As <b>${xLabel}</b> increases, <b>${yLabel}</b> tends to ${direction}.`
    : `The linear relationship between these two variables is not statistically significant.`;


  return (
    <div style={{ marginLeft:"30px", marginRight:"30px"}}>
      {/* DO NOT DISPLAY P-VALUE
      <p>
        <strong>P-Value (p):</strong> {p.toFixed(8)}
      </p>
      */}
      {/* OLD TEXT
      <p style={{ 
        fontSize:"16px",
        fontWeight:"400",
      }}>
      {
      rReliable === 'ok' && pReliable === 'ok'
        ? <span>As <span style={{fontWeight: 700}}>{xLabel.split('(')[0]}</span> increases, <span style={{fontWeight: 700}}>{yLabel.split('(')[0]}</span> {direction}. There is a {strength} linear relationship between these two variables.</span>
        : <span>The linear relationship between these two variables is not statistically significant.</span>
      }
      </p>*/}
      <p style={{ fontSize:"16px", fontWeight:"400", paddingBottom:"25px" }}>
        {
          rReliable === 'ok' && pReliable === 'ok'
            ? <span>
                There is a <b>{strength}</b> <b>{correlationDirection}</b> correlation between <b>{xLabel}</b> and <b>{yLabel}</b>. 
                As <b>{xLabel}</b> increases, <b>{yLabel}</b> tends to {direction}.<br/><br/>
                <span style={{fontSize: '12px', lineHeight: '1.3'}}>
                  This correlation measures the linear relationship between the two variables you have chosen. A correlation can be strong, moderate, weak, or nonexistent; it can be either positive or negative. It is important to keep in mind that correlation is not causation. 
                  <a style={{ color: 'black' }} href="/correlation_causation.pdf" target="_blank">Read more here.</a>
                </span>
              </span>
            : <span>The linear relationship between these two variables is not statistically significant.</span>
        }
      </p>
      <div>
        <RSlider rValue={r} />
      </div>
    </div>
  );
};

export default CorrelationCard;
