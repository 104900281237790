// StatExplorer.tsx
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useData, FlatFileCsvData} from '../data/DataContext';
//import { useStatus } from '../data/StatusContext';
import { MainHistogram } from '../components/structure/MainHistogram';
import { MainMap } from '../components/maps/MainMap';
import debounce from 'lodash.debounce';
import ChartList from '../components/structure/ChartList';
import MenuBar from '../components/structure/MenuBar';
import { Selector } from '../components/inputs/Selector';
import { on } from 'events';
import { useGlobalActiveIndicator, useGlobalBivariateIndicator, useGlobalActiveGeography, useGlobalSelectedId, onClickHistogram } from '../data/StatusStore';
import { useGlobalSwarmCalibration, onSetSwarmCalibration } from '../data/GlobalStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import './views.scss';
import { BivariateLegend } from '../components/meta/BivariateLegend';
import _ from 'lodash';
import NumberPicker from "react-widgets/NumberPicker";
import "react-widgets/scss/styles.scss";
import { Combobox } from 'react-widgets/cjs';
import MobileSwarm from '../components/charts/MobileSwarm';
import { DynamicImage } from '../components/utilities/DynamicImage';
//const turf = require('@turf/turf');
import * as turf from 'turf';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';

export interface StatExplorerProps {
   indicatorOptions: any | null,
   groupedModules: any | null,
   unGroupedModules: any | null,
   allData: any | null,
   handleChildReady: Function,
   //activeGeography: string,
   moduleIdIncrement: number,
   updateDashboardModules: Function,
   validateCustomConfig: Function,
   setModuleIdIncrement: Function,
}

const StatExplorer: React.FC<StatExplorerProps> = ({   
    indicatorOptions,
    groupedModules,
    unGroupedModules,
    allData,
    handleChildReady,
    //activeGeography,
    moduleIdIncrement,
    updateDashboardModules,
    validateCustomConfig,
    setModuleIdIncrement,
}) => {

const { 
    dataActive, dataTract, dataNTA, dataPUMA, dataNYC, dataCD, dataBoro, isLoadingSorter,
    isLoadingTract, isLoadingNTA, isLoadingPUMA, isLoadingNYC, isLoadingCD, isLoadingBoro, dataSorter,
    error, geoJsonDataPUMA } = useData();

    const [options, setOptions] = useState<any | null>(null);
    //const [indicatorOptions, setIndicatorOptions] = useState<any | null>(null);
    //const [groupedModules, setGroupedModules] = useState<any | null>(null);
    //const [allData, setAllData] = useState<any | null>(null);
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const [mapType, setMapType] = useState<string>("choropleth");

    const activeIndicator ="ahdi"; // useGlobalActiveIndicator(); // "air_qual"; // Uncomment this to have a dynamic indicator
    //const bivariateIndicator = useGlobalBivariateIndicator();
    const activeGeography = "CD"; //useGlobalActiveGeography(); // Uncomment this to have a dynamic geography
    const selectedId = useGlobalSelectedId();
    const swarmCalibration = useGlobalSwarmCalibration();
    const [userLocation, setUserLocation] = useState<{ lat: number, long: number } | null>(null);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setUserLocation({
                    lat: position.coords.latitude,
                    long: position.coords.longitude
                });
            }, (error) => {
                console.error("Error getting user location: ", error);
            });
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    }, []);

    useEffect(() => {
        console.log("J022725 userLocation", userLocation);
        console.log("J022725 geoJsonDataPUMA", geoJsonDataPUMA);
        if (userLocation && geoJsonDataPUMA) {
            const userPoint = turf.point([userLocation.long, userLocation.lat]);

            const containingFeature = geoJsonDataPUMA.features.find((feature: any) => {
            return booleanPointInPolygon(userPoint, feature);
            });

            if (containingFeature) {
            console.log("J022725 User is located in feature: ", containingFeature);
            if (containingFeature && containingFeature.properties && containingFeature.properties.GEOID20) {
                onClickHistogram(containingFeature.properties.GEOID20);
            }
            } else {
            console.log("J022725 User location is not within any feature.");
            }
        }
    }, [userLocation]);

    useEffect(() => {
        console.log("J022725 indicatorOptions", indicatorOptions);
    }, [indicatorOptions]);

    useEffect(() => {
        console.log("J022725 groupedModules", groupedModules);
    }, [groupedModules]);

    useEffect(() => {
        console.log("J022725 activeGeography", activeGeography);
    }, [activeGeography]);

    useEffect(() => {
        if (activeIndicator && allData && activeGeography && unGroupedModules) {
            console.log("JJ022725 activeIndicator", activeIndicator);
            console.log("JJ022725 activeGeography", activeGeography);
            console.log("JJ022725 allData", allData);
            console.log("JJ022725 allData[activeGeography]", allData[activeGeography]);
            console.log("JJ022725 unGroupedModules", unGroupedModules);
            let matchingSorts:any = [];
            Object.keys(unGroupedModules).forEach((key) => {
                unGroupedModules[key].forEach((module: any) => {
                    if (module.VARIABLE_NAME === activeIndicator){
                        console.log("JJ022725 Module: ", module);
                        matchingSorts.push(module);
                    }
                });
            });

            console.log("JJ022725 matchingSorts", matchingSorts);
            if (matchingSorts.length > 0) {
                let test = {
                    allDataPresent: true,
                    variables: [
                    {
                        label: allData[activeGeography].meta.DISPLAY_NAME[activeIndicator],
                        variable: activeIndicator,
                        type: "beeswarm",
                        sort: matchingSorts[0],
                        index: 0,
                        geo: activeGeography,
                        category: "MOBILE; Core Stats"
                    }
                    ],
                    type: "beeswarm",
                    breadcrumb: "MOBILE; Core Stats > Demographics > Density > Ungrouped > beeswarm",
                    category: "MOBILE; Core Stats",
                    subCategory: "MOBILE; Demographics"
                }
                setOptions(test);
            }
        }
    }, [activeIndicator, allData, activeGeography, unGroupedModules]);

    useEffect(() => {
        console.log("J022725 allData", allData);
    }, [allData]);

    useEffect(() => {
        console.log("J022725 options", options);
    }, [options]);

    const memoizedSwarm = useMemo(() => {
        if (!options || !allData || !activeGeography) {
            return null;
        }
        //console.log("BB111624 allData", allData);
        //console.log("A111624 chartIndicator", chartIndicator);
        //console.log("A111624 options.variables", options.variables);
        const _filteredData = options.variables.filter((v: any) => {
          if (v.sort.Tooltip_only === "") {
            return { v };
          }
        });

        const _tooltipData = options.variables.filter((v: any) => {
          if (v.sort.Tooltip_only !== "") {
            return { v };
          }
        });
    
        //console.log("J112624 Swarm _filteredData", _filteredData);
        //console.log("J112624 Swarm _tooltipData", _tooltipData);
        //console.log("J112624 Swarm allData[activeGeography]", allData[activeGeography])
        return(
        (_filteredData && _filteredData.length > 0 && options) 
          ? _filteredData.map((subIndicator: any, index: number) => (
            subIndicator && allData[activeGeography]?.dataArray[subIndicator?.variable]
              ?<div key={`swarm_${_filteredData}_${index}`} ref={containerRef} style={{ 
                width: '100%', height: '100%', position: 'relative', display: 'flex',
                 alignItems: 'flex-start', paddingRight:"0px" }}>     
                <MobileSwarm
                  key={`swarm_${subIndicator.variable}_${index}`} 
                  data={allData[activeGeography]}
                  dataArray={allData[activeGeography]?.dataArray[subIndicator.variable]}
                  histMax={allData[activeGeography]?.meta?.max_value[subIndicator.variable]}
                  histMin={allData[activeGeography]?.meta?.min_value[subIndicator.variable]}
                  histNA={allData[activeGeography]?.meta?.na_count[subIndicator.variable]} 
                  histLength={allData[activeGeography]?.meta?.length[subIndicator.variable]} 
                  chartId={subIndicator?.variable}
                  indicator={subIndicator}
                  colorClass={_filteredData[0].sort.Sort.Color}
                  //colorClass={allData[activeGeography].meta["Module Color"][_filteredData[0].variable]}
                  labelOnly={_tooltipData}
                  options={options}
                  selectedId={selectedId}
                />
              </div>
              : null
          ))
        : null)
      }, [/*chartIndicator,*/ allData, activeGeography, swarmCalibration, options]);

    if (isLoadingTract || isLoadingNTA || isLoadingPUMA || isLoadingNYC || isLoadingCD || isLoadingBoro || !allData) {
        return <div className='main-container-loading'>
                <div className="loading-data">
                    Loading data.
                    <div className="loading-spinner">
                        <FontAwesomeIcon icon={faSpinner} spin />
                    </div>
                </div>
            </div>
    }
    if (error) return <p>Error loading map data: {error}</p>;

    return (
        <div className='main-container-mobile'>
            {/*<ChartList
                key={`chart-list-${activeGeography}`}
                indicatorOptions={indicatorOptions}
                groupedModules={groupedModules}
                allData={allData}
                chartId={`id-chart-list-${activeGeography}`}
                handleChildReady={handleChildReady}
                moduleIdIncrement={moduleIdIncrement}
                updateDashboardModules={updateDashboardModules}
                validateCustomConfig={validateCustomConfig}
            />*/}
            {memoizedSwarm}
            <div className='mobile-header-section'>
                <div className='mobile-header'>
                    {/*CD10 - Brooklyn*/}
                    {activeGeography && selectedId 
                    ? allData[activeGeography].dataJSON[selectedId].GEO_LABEL
                    : ""}
                </div>
                <div className='mobile-image'>
                    <DynamicImage 
                        selectedId={ selectedId }
                        activeGeography={ activeGeography }
                        allData={allData}
                    />
                </div>
                <div className='mobile-paragraph'>
                    <p className='p1'>Community District 10, located in the southwest corner of Brooklyn along New York Bay, is relatively isolated from the rest of the city.</p>
                    <p className='p2'>Almost three-fifths of District 10's residents are white, and one-quarter are Asian.</p>
                    <p className='p3'>This area is relatively affluent, and has an above average Human Development Index: 6.92 out of 10.</p>
                    <p className='p4'>It has an unusually low birth rate, high obesity, and number of rat complaints, relative to other places with similar demographics.</p>
                </div>
            </div>
        </div>
    );
};

export default StatExplorer;
