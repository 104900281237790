import React, { useState, useEffect, FC, useMemo } from 'react';
import { Histogram } from '../../components/charts/Histogram';
import Swarm from '../../components/charts/Swarm';
import Pie from '../../components/charts/Pie';
import Waffle from '../../components/charts/Waffle';
import DBWaffle from '../../components/charts/DBWaffle';
import Time from '../../components/charts/Time';
import List from '../../components/charts/List';
import Count from '../../components/charts/Count';
import DBCountOnly from '../../components/charts/DBCountOnly';
import Pyramid from '../../components/charts/Pyramid';
import StackedHistograms from '../../components/charts/StackedHistograms';
import StackedSwarms from '../../components/charts/StackedSwarms';
import DBStackedStats from '../../components/charts/DBStackedStats';
import GlobeMap from '../../components/charts/GlobeMap';
import SwarmMeta from '../../components/charts/SwarmMeta';
import HistogramMeta from '../../components/charts/HistogramMeta';
import DeviationFromAverage from '../../components/charts/DeviationFromAverage';
import DBStatList from '../../components/charts/DBStatList';
import DBRankedStatList from '../../components/charts/DBRankedStatList';
import DBOneOrTwoColStats from '../../components/charts/DBOneOrTwoColStats';
import DeviationFromAverageInline from '../../components/charts/DeviationFromAverageInline';
import Bar from '../../components/charts/Bar';
import DBBar from '../../components/charts/DBBar';
import ModuleSearch from '../../components/inputs/ModuleSearch';
import SvgIcon from '../../assets/SvgIcon';
import { Button } from 'react-bootstrap';
import { useGlobalActiveGeography, useGlobalActiveIndicator, useGlobalBivariateIndicator, useGlobalSelectedId, useGlobalActivePage, useGlobalCustomDB } from '../../data/StatusStore'; // Adjust the import path as necessary
import { onSetActiveIndicator, onSetBivariateIndicator, onSetSelectedId } from '../../data/StatusStore';
import { useGlobalSwarmCalibration } from '../../data/GlobalStore';
import Colors from '../utilities/Colors';
import { useData } from '../../data/DataContext';
interface DataItem {
  id: string;
  value: any;
}

export interface ChartCardProps {
  chartIndicator: any;
  chartIndicatorName: string;
  allData: any |null;
  chartId: string;
  height: string;
  width: string;
  index: number;
  options: any;
  activeGeography: string;
  //onSetActiveIndicator: (indicator: string) => void;
  //onSetBivariateIndicator: (indicator: string) => void;
  //activeIndicator : string;
  //bivariateIndicator : string;
  _selectedId: string | null;
  context: string;
  column: number;
  updateDashboardModules: Function;
  validateCustomConfig: Function;
  //persistableSelectedId: string | null;
}

export const ChartCard: React.FC<ChartCardProps> = ({
  column, context, chartIndicator, chartIndicatorName, allData, chartId, height, width, _selectedId, updateDashboardModules, validateCustomConfig, /*persistableSelectedId, onSetActiveIndicator, onSetBivariateIndicator, activeIndicator, bivariateIndicator*/ options, activeGeography, index
}) => {

  //console.log("N092924 ---", options)
  const activeIndicator = useGlobalActiveIndicator();
  const activePage = useGlobalActivePage();
  const bivariateIndicator = useGlobalBivariateIndicator();
  const selectedId = useGlobalSelectedId();
  const customDB = useGlobalCustomDB();
  const [persistableSelectedId, setPersistableSelectedId] = useState<string | null>(selectedId);
  const [addModuleEngaged, setAddModuleEngaged] = useState<boolean>(false);
  const [key, setKey] = useState(0);
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({ width:700, height:180 });
  //const activeGeography = useGlobalActiveGeography();
  const {
      geoJsonDataPUMA, geoJsonDataTract, 
      geoJsonDataNTA, geoJsonDataBoro,
      isLoadingTopology, error, dataPoint, pointDataList, pointModules
  } = useData();
  const swarmCalibration = useGlobalSwarmCalibration();

  //console.log("J032925 options", options);
  
  const handlePinVar1 = (e: any, indicator:string) => {
    console.log('Pin 1',e, indicator);
    onSetActiveIndicator(indicator);
  };
  const handlePinVar2 = (e: any, indicator:string) => {
    console.log('Pin 2',e, indicator);
    onSetBivariateIndicator(indicator);
  };

  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        if (!entries || entries.length === 0) {
          return;
        }
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        if (containerRef.current) {
          resizeObserver.unobserve(containerRef.current);
        }
      };
    }
  }, []);

  useEffect(() => {
    console.log("J032925 options", options)
  }, [dataPoint]);

  const gridSize = 10; // 10x10 grid
  const chartSize = Math.min(dimensions.width / 2, dimensions.height / 2); // Reduce the chart size
  const [preppedData, setPreppedData] = useState<any>([]);;
  const containerRef = React.useRef<HTMLDivElement | null>(null);
  
  const fontSize = 12; // For dynamic heights --> // Math.min(12, Math.max(8, dimensions.width / 50));
  const fontSize2 = 12; // For dynamic heights --> // Math.min(10, Math.max(8, dimensions.width / 50));
  const valueWidth = 120; // Width allocated for the value text
  const labelWidth = dimensions.width - chartSize - valueWidth - 30;

  useEffect(() => {
    console.log("HH012225------------> IntersectionObserver selectedId",selectedId);
    console.log("HH012225 ------------> chartIndicator",chartIndicator);
    //setKey(prevKey => prevKey + 1); // Force re-render
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            //console.log("D060524 ------------> IntersectionObserver entry",entry);
            console.log("HH012225 ------------> IntersectionObserver selectedId",selectedId);
            console.log("HH012225 ------------> IntersectionObserver _selectedId",_selectedId);
            console.log("HH012225 ------------> IntersectionObserver persistableSelectedId",persistableSelectedId);
            if (selectedId){
              //console.log("EEE112724 ====================> selectedId",selectedId);
              onSetSelectedId(selectedId); 
              setPersistableSelectedId(selectedId); 
            }else if (_selectedId){
              //console.log("EEE112724 ====================> _selectedId",_selectedId);
              onSetSelectedId(_selectedId); 
              //setPersistableSelectedId(_selectedId); 
            }else if (persistableSelectedId){
              console.log("EEE112724 ====================> persistableSelectedId",persistableSelectedId);
              //onSetSelectedId(persistableSelectedId); 
            }
          }
        });
      },
      {
        rootMargin: '400px 0px', // Increase the root margin to load charts earlier
        threshold: 0.1, // Lower the threshold to trigger earlier
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [chartIndicator, selectedId]);
/*
Shape (inventory);
X beeswarm
X deviation from average
X bar
X Pie
X map
none
Ranked List
Count

X pyramid
X waffle 
*/
  const memoizedHistogram = useMemo(() => (
    //console.log("J092924 allData", allData),
    //console.log("J092924 allData[activeGeography]", allData[activeGeography]),
    //console.log("M092924 chartIndicator", chartIndicator),
    //console.log("J092924 options", options),
    chartIndicator && chartIndicator.length > 0 
      ? chartIndicator.map((subIndicator: any, index: number) => (
        //console.log("M092924 subIndicator", subIndicator),
        //console.log("M092924 allData[activeGeography]", allData[activeGeography]),
        //console.log("M092924 subIndicator?.variable", subIndicator?.variable),
        subIndicator && allData[activeGeography]?.dataArray[subIndicator?.variable]
          ? <div 
              ref={containerRef} 
              style={{ width: '100%', height: '100%', position: 'relative', display: 'flex', alignItems: 'flex-start', paddingRight:"16px" }}
              key={`chart-card-hist-${index}`}
              >
              <div 
                style={{ 
                  marginLeft: '0px', 
                  marginRight: '0px', 
                  fontSize:fontSize2, 
                  lineHeight: '1', 
                  width: "250px",//`calc(40% - ${(chartSize + 10)}px)` 
                }}>
                <HistogramMeta
                  dataArray={allData[activeGeography]?.dataArray[subIndicator.variable]}
                  data={allData[activeGeography]}
                  indicator={subIndicator}
                />
              </div>
              <div 
                style={{ 
                  marginLeft: '0px', 
                  marginRight: '0px', 
                  fontSize:fontSize2, 
                  lineHeight: `${fontSize2 + 2 }px`,//'1', 
                  width: `calc(100% - 250px)`, 
                  position:"relative" 
                }}>   
                <Histogram 
                  key={`hist_${subIndicator.variable}_${index}`} 
                  dataArray={allData[activeGeography]?.dataArray[subIndicator.variable]}
                  histMax={allData[activeGeography]?.meta?.max_value[subIndicator.variable]}
                  histNA={allData[activeGeography]?.meta?.na_count[subIndicator.variable]} 
                  histLength={allData[activeGeography]?.meta?.length[subIndicator.variable]} 
                  chartId={subIndicator?.variable}
                  height={'200'}
                  //width={`calc(60% + ${(chartSize + 10) / 2}px)` }
                  width={'calc(100% - 0px)'}
                  paddingLeft={'0px'}
                  paddingRight={'0px'}
                  tooltips={true}
                  /*colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}*/
                  colorClass={ options.variables[0].sort.Sort.Color}
                  data={allData[activeGeography]}
                  indicator={subIndicator}
                  options={options}
                />
              </div>
            </div>
          : null
      ))
      : null
  ), [chartIndicator, allData, activeGeography]);

  const memoizedSwarm = useMemo(() => {
    //console.log("BB111624 allData", allData);
    //console.log("A111624 chartIndicator", chartIndicator);
    console.log("J022725 options", options);
    const _filteredData = options.variables.filter((v: any) => {
      if (v.sort.Tooltip_only === "") {
        return { v };
      }
    });
    const _tooltipData = options.variables.filter((v: any) => {
      if (v.sort.Tooltip_only !== "") {
        return { v };
      }
    });

    //console.log("J112624 Swarm _filteredData", _filteredData);
    //console.log("J112624 Swarm _tooltipData", _tooltipData);
    //console.log("J112624 Swarm allData[activeGeography]", allData[activeGeography])
    return(
    (_filteredData && _filteredData.length > 0) 
      ? _filteredData.map((subIndicator: any, index: number) => (
        subIndicator && allData[activeGeography]?.dataArray[subIndicator?.variable]
          ?<div key={`swarm_${_filteredData}_${index}`} ref={containerRef} style={{ 
            width: '100%', height: 'calc(100% - 70px)', position: 'relative', display: 'flex',
             alignItems: 'flex-start', paddingRight:"0px" }}>
          <div 
            style={{ 
              marginLeft: '0px', 
              marginRight: '16px', 
              fontSize:fontSize2, 
              lineHeight: '1', 
              width: "305px",//`calc(40% - ${(chartSize + 10)}px)` 
              height:'100%',
            }}>
            <SwarmMeta
              dataArray={allData[activeGeography]?.dataArray[subIndicator.variable]}
              data={allData[activeGeography]}
              indicator={subIndicator}
            />
          </div>
            <div style={{ marginLeft: '0px', marginRight: '0px', fontSize:fontSize2, lineHeight: '1', width: `calc(100% - 305px)`, position:"relative" }}>      
            <Swarm 
              key={`swarm_${subIndicator.variable}_${index}`} 
              data={allData[activeGeography]}
              dataArray={allData[activeGeography]?.dataArray[subIndicator.variable]}
              histMax={allData[activeGeography]?.meta?.max_value[subIndicator.variable]}
              histMin={allData[activeGeography]?.meta?.min_value[subIndicator.variable]}
              histNA={allData[activeGeography]?.meta?.na_count[subIndicator.variable]} 
              histLength={allData[activeGeography]?.meta?.length[subIndicator.variable]} 
              chartId={subIndicator?.variable}
              indicator={subIndicator}
              colorClass={_filteredData[0].sort.Sort.Color}
              //colorClass={allData[activeGeography].meta["Module Color"][_filteredData[0].variable]}
              labelOnly={_tooltipData}
              options={options}
            />
            </div>
          </div>
          : null
      ))
    : null)
  }, [chartIndicator, allData, activeGeography, swarmCalibration]);

  const memoizedPie = useMemo(() => (
    //console.log("B102824 chartIndicator", chartIndicator),
    <Pie
      key={`pie_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={700}
      height={180}
      /*colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}*/
      colorClass={ options.variables[0].sort.Sort.Color}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography, height, width,*/ selectedId, key]);
  
  const memoizedList = useMemo(() => (
    <List
      key={`list_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={700}
      height={180}
      /*colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}*/
      colorClass={ options.variables[0].sort.Sort.Color}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography,height, width,*/  selectedId, key]);

  const memoizedPyramid = useMemo(() => (
    (activePage === "dashboard")
    ? <div>
        <DBStatList
          key={`dev_${chartIndicator}`}
          options={options}
          data={allData[activeGeography]}
          width={700}
          height={180}
          /*colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}*/
          colorClass={ options.variables[0].sort.Sort.Color}
        />
        <Pyramid
          key={`pyramid_${chartIndicator}`}
          options={options}
          data={allData[activeGeography]}
          width={700}
          height={250}
          /*colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}*/
          colorClass={ options.variables[0].sort.Sort.Color}
        />
      </div>
    : <Pyramid
        key={`pyramid_${chartIndicator}`}
        options={options}
        data={allData[activeGeography]}
        width={700}
        height={250}
        /*colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}*/
        colorClass={ options.variables[0].sort.Sort.Color}
      />
  ), [/*allData,*/ chartIndicator, /*activeGeography,height, width,*/  selectedId, key]);

  const memoizedStackedHistograms = useMemo(() => (
    <StackedHistograms
      key={`pyramid_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={"100%"}
      height={250}
      /*colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}*/
      colorClass={ options.variables[0].sort.Sort.Color}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography,height, width,*/  selectedId, key]);

  const memoizedStackedSwarms = useMemo(() => (
    <StackedSwarms
      key={`pyramid_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={"100%"}
      height={250}
      /*colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}*/
      colorClass={ options.variables[0].sort.Sort.Color}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography,height, width,*/  selectedId, key]);

  const memoizedDBStackedStats = useMemo(() => (
    <DBStackedStats
      key={`pyramid_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={"100%"}
      height={250}
      /*colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}*/
      colorClass={ options.variables[0].sort.Sort.Color}
      context={context}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography,height, width,*/  selectedId, key]);

  const memoizedDBOneOrTwoColStats = useMemo(() => (
    <DBOneOrTwoColStats
      key={`pyramid_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={"100%"}
      height={250}
      /*colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}*/
      colorClass={ options.variables[0].sort.Sort.Color}
      context={context}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography,height, width,*/  selectedId, key]);

  const memoizedDBOneOrTwoColStatsWaffle = useMemo(() => (
    <div>
      <DBOneOrTwoColStats
        key={`pyramid_${chartIndicator}`}
        options={options}
        data={allData[activeGeography]}
        width={"100%"}
        height={250}
        /*colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}*/
        colorClass={ options.variables[0].sort.Sort.Color}
        context={context}
      />
      <div style={{height:"40px"}}></div>
      <DBWaffle
        key={`waffle_${chartIndicator}`}
        options={options}
        data={allData[activeGeography]}
        width={700}
        height={180}
        /*colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}*/
        colorClass={ options.variables[0].sort.Sort.Color}
      />
    </div>

  ), [/*allData,*/ chartIndicator, /*activeGeography,height, width,*/  selectedId, key]);

  const memoizedMap = useMemo(() => (
    <GlobeMap
      key={`map_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={350}
      height={200}
      /*colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}*/
      colorClass={ options.variables[0].sort.Sort.Color}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography,height, width,*/  selectedId, key]);

  const memoizedDev = useMemo(() => (
    //console.log("A093024 chartIndicator", chartIndicator),
    //console.log("A093024 activeGeography", activeGeography),
    //console.log("A093024 options", options),
    <DeviationFromAverage
      key={`dev_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={700}
      height={180}
      /*colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}*/
      colorClass={ options.variables[0].sort.Sort.Color}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography,height, width,*/  selectedId, key]);

  const memoizedStatList = useMemo(() => (
    //console.log("A093024 chartIndicator", chartIndicator),
    //console.log("A093024 activeGeography", activeGeography),
    //console.log("A093024 options", options),
    <DBStatList
      key={`dev_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={700}
      height={180}
      /*colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}*/
      colorClass={ options.variables[0].sort.Sort.Color}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography,height, width,*/  selectedId, key]);

  const handleAddModule = () => {
    console.log("C011125 Add Module button clicked");
    setAddModuleEngaged(true);
    // Implement the logic to add a new module here
  };

  const handleRemoveModule = (_moduleId:any) => {
    console.log("E011525 remove Module button clicked");
    updateDashboardModules(null, _moduleId, customDB);
    // Implement the logic to add a new module here
  };

  const handleAdd = (pinned:any, moduleName:any) => {
    console.log('I011125 User pinned these indicators:', pinned);
    console.log('I011125 Module Name:', moduleName);
    updateDashboardModules(pinned, moduleName, customDB);
    setAddModuleEngaged(false);
  };

  const validateConfig = (pinned: any, moduleName: string) => {
    console.log('J011625 User pinned these indicators:', pinned);
    console.log('J011625 Module Name:', moduleName);
    return validateCustomConfig(pinned, moduleName, customDB);
    //setAddModuleEngaged(false);
  };

  const handleCancel = () => {
    console.log('I011125 User canceled');
    setAddModuleEngaged(false);
  };
  
  const memoizedAddModule = useMemo(() => (
    //console.log("A120824 chartIndicator[0].sort.Shape", chartIndicator[0].sort.Shape),
    //console.log("A102824 chartIndicator[0].sort.Sort.Icon", chartIndicator[0].sort.Sort.Icon),
    
    addModuleEngaged 
    ? <div>
       <ModuleSearch 
        allIndicators={allData[activeGeography].meta}
        onAdd={handleAdd}
        validateConfig={validateConfig} 
        onCancel={handleCancel}
      />
    </div>
    : <div 
      key={`chart-card-header-${index}`}
      style={{
        height:'30px',
        marginTop: chartIndicator[0].sort.Shape === 'beeswarm' 
          ? '10px' 
          : chartIndicator[0].sort.Shape === 'stacked swarms' 
            ? '10px' 
            : '30px',
        width: '100%',
        //paddingTop: '20px',
        borderBottom: '1px solid #ccc',
        //backgroundColor: "white", 
        overflowY: "hidden", //"scroll", 
        overflowX: "hidden", 
        //marginRight: "32px",
        //marginLeft: "32px",
        cursor:'pointer',
        marginBottom: "10px",
        display: "inline-flex",
            }}
            onClick={() => handleAddModule()}
          >
            <SvgIcon
              svgName={"plus"}
              color={null}
            />
      <div
        style={{
          fontSize: "24px",
          lineHeight: "27px",
          color: "#231f20",
          marginLeft: "15px",
          overflowY: "hidden",
          display:"flex",
        }}
      >{`${chartIndicator[0].sort.Sort["Module Title"]} `} <span style={{
        fontSize: "8px",
        lineHeight: "28px",
        paddingLeft:"5px",
        color: "red",
      }}>{/*` (${chartIndicator[0].sort.Module} - ${chartIndicator[0].sort.Sort["Current Module ID"]} - ${chartIndicator[0].sort.moduleId})`*//* ${chartIndicator[0].geo}`.split("(")[0]*/}</span><div style={{
        fontSize: "12px",
        lineHeight: "28px",
        paddingLeft:"5px",
        color: "#5a5a5a",
      }}>{chartIndicator[0].type.toLowerCase() === 'ranked list' 
        ? chartIndicator[0].sort.DISPLAY_NAME.includes("among adults aged >=18 years (%)") 
          ? "among adults aged >=18 years (%)" //chartIndicator[0].sort.DISPLAY_NAME 
          : ""
        : ""}</div></div>
    </div>
  ), [/*allData,*/ chartIndicator, addModuleEngaged, /*activeGeography,height, width,*/  selectedId, key]);

  const memoizedRankedStatList = useMemo(() => (
    //console.log("A093024 chartIndicator", chartIndicator),
    //console.log("A093024 activeGeography", activeGeography),
    //console.log("A093024 options", options),
    <DBRankedStatList
      key={`dev_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={700}
      height={180}
      /*colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}*/
      colorClass={ options.variables[0].sort.Sort.Color}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography,height, width,*/  selectedId, key]);

  const memoizedBarWaffle = useMemo(() => (
    //console.log("A093024 chartIndicator", chartIndicator),
    //console.log("A093024 activeGeography", activeGeography),
    //console.log("A093024 options", options),
    <div>
      <DBBar
        key={`bar_${chartIndicator}`}
        options={options}
        data={allData[activeGeography]}
        width={700}
        height={180}
        //colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}
        colorClass={ options.variables[0].sort.Sort.Color}
      />
      <DBWaffle
        key={`waffle_${chartIndicator}`}
        options={options}
        data={allData[activeGeography]}
        width={700}
        height={180}
        /*colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}*/
        colorClass={ options.variables[0].sort.Sort.Color}
      />
    </div>
  ), [/*allData,*/ chartIndicator, /*activeGeography,height, width,*/  selectedId, key]);

  const memoizedDevInline = useMemo(() => (
    //console.log("A093024 chartIndicator", chartIndicator),
    //console.log("A093024 activeGeography", activeGeography),
    //console.log("A093024 options", options),
    <DeviationFromAverageInline
      key={`dev_inline_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={700}
      height={180}
      /*colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}*/
      colorClass={ options.variables[0].sort.Sort.Color}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography,height, width,*/  selectedId, key]);

  const memoizedBar = useMemo(() => (
    //console.log("A093024 chartIndicator", chartIndicator),
    //console.log("A093024 activeGeography", activeGeography),
    //console.log("A093024 options", options),
    <Bar
      key={`bar_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={700}
      height={180}
      //colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}
      colorClass={ options.variables[0].sort.Sort.Color}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography,height, width,*/  selectedId, key]);

  const memoizedDBWaffle = useMemo(() => (
    <DBWaffle
      key={`waffle_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={700}
      height={180}
      /*colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}*/
      colorClass={ options.variables[0].sort.Sort.Color}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography, height, width,*/ selectedId, key]);

  const memoizedWaffle = useMemo(() => (
    <Waffle
      key={`waffle_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={700}
      height={180}
      /*colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}*/
      colorClass={ options.variables[0].sort.Sort.Color}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography, height, width,*/ selectedId, key]);

  const memoizedTime = useMemo(() => (
    <Time
      key={`time_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={700}
      height={180}
      /*colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}*/
      colorClass={ options.variables[0].sort.Sort.Color}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography, height, width,*/ selectedId, key]);

  const memoizedMiscellaneous = useMemo(() => (
    //console.log("A061924 chartIndicator", chartIndicator),
    //console.log("A061924 options", options),
    <div
      style={{
        height: "180px",
        //overflowY: "scroll",      
      }}
      key={`chart-card-misc-${index}`}
    ><div>{options.type == "" ? "Blank Module type" : options.type}</div>
      {options?.variables?.map((v: any, subIndex: number) => (
        <div
          key={`chart-card-${index}-${subIndex}`}
          style={{
            fontSize: "10px",
            color: "gray",      
          }}
          >{`${v.label} (${v.value})`}</div>
        ))}  
    </div>
  ), [/*allData,*/ chartIndicator, /*activeGeography, height, width*/]);

  const memoizedCount = useMemo(() => (
    //console.log("J032925 options", options),
    <Count
      key={`list_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={700}
      height={180}
      /*colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}*/
      colorClass={ options.variables[0].sort.Sort.Color}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography, height, width*/, dataPoint]);

  const memoizedCountOnly = useMemo(() => (
    <DBCountOnly
      key={`list_${chartIndicator}`}
      options={options}
      data={allData[activeGeography]}
      width={700}
      height={180}
      /*colorClass={allData[activeGeography].meta["Module Color"][chartIndicator[0].variable]}*/
      colorClass={ options.variables[0].sort.Sort.Color}
    />
  ), [/*allData,*/ chartIndicator, /*activeGeography, height, width*/]);

  const memoizedButtons = useMemo(() => (
    //console.log("060724 chartIndicator", chartIndicator),
    <div 
      style={{
        height:(options.type === 'histogram') ? '65px' : '30px',
        width: '100%',
      }}
      key={`chart-card-buttons-${index}`}
    >{chartIndicator[0].label}
      <Button 
        variant={chartIndicator[0].variable == activeIndicator ? "secondary" : "outline-secondary"}
        style={{
          paddingTop: "0",
          paddingBottom: "0",
          marginLeft: "10px",
        }}
        size="sm"
        onClick={((e:any) => {
          handlePinVar1(e, chartIndicator[0].variable)
        })}>Pin 1</Button>
      <Button 
        variant={chartIndicator[0].variable == bivariateIndicator ? "secondary" : "outline-secondary"}
        style={{
          paddingTop: "0",
          paddingBottom: "0",
          marginLeft: "10px",
        }}
        size="sm"
        onClick={((e:any) => {
          handlePinVar2(e, chartIndicator[0].variable)
        })}>Pin 2</Button>
    </div>
  ), [/*allData,*/ chartIndicator, activeIndicator, /*activeGeography,*/ bivariateIndicator]);

  const memoizedHeader = useMemo(() => (
    //console.log("C100424 chartIndicator", chartIndicator),
    //console.log("A102824 chartIndicator[0]", chartIndicator[0]),
    //console.log("A120824 chartIndicator[0].sort.Shape", chartIndicator[0].sort.Shape),
    //console.log("A102824 chartIndicator[0].sort.Sort.Icon", chartIndicator[0].sort.Sort.Icon),
    <div 
    key={`chart-card-header-${index}`}
    style={{
      height:'30px',
      marginTop: chartIndicator[0].sort.Shape === 'beeswarm' 
        ? '10px' 
        : chartIndicator[0].sort.Shape === 'stacked swarms' 
          ? '10px' 
          : '30px',
      width: '100%',
      //paddingTop: '20px',
      borderBottom: '1px solid #ccc',
      //backgroundColor: "white", 
      overflowY: "hidden", //"scroll", 
      overflowX: "hidden", 
      //marginRight: "32px",
      //marginLeft: "32px",
      marginBottom: "10px",
      display: "inline-flex",
    }}
    >
    {chartIndicator[0].sort.Sort.Icon === "custom" 
      ? null
      : <SvgIcon
        svgName={chartIndicator[0].sort.Sort.Icon}
        color={Colors.getColorByClass(chartIndicator[0].sort.Sort.Color)}
        />}
      <div
        style={{
          fontSize: "24px",
          lineHeight: "27px",
          color: "#231f20",
          marginLeft: chartIndicator[0].sort.Sort.Icon === "custom" ? "0px" : "15px",
          overflowY: "hidden",
          width: "100%",
          display:"flex",
        }}
      >{`${chartIndicator[0].sort.Sort["Module Title"]} - ${chartIndicator[0].sort.Sort["SubCategory_"]} `} <span style={{
        fontSize: "8px",
        lineHeight: "28px",
        paddingLeft:"5px",
        color: "red",
      }}>{/*chartIndicator[0].sort.Shape*//*` (${chartIndicator[0].sort.Module} - ${chartIndicator[0].sort.Sort["Current Module ID"]} - ${chartIndicator[0].sort.moduleId})`*//* ${chartIndicator[0].geo}`.split("(")[0]*/}</span><div style={{
        fontSize: "12px",
        lineHeight: "28px",
        paddingLeft:"5px",
        color: "#5a5a5a",
      }}>{chartIndicator[0].type.toLowerCase() === 'ranked list' || chartIndicator[0].type.toLowerCase() === 'stacked stats' 
        ? chartIndicator[0].sort.DISPLAY_NAME.includes("among adults aged >=18 years (%)") 
          ? "among adults aged >=18 years (%)" //chartIndicator[0].sort.DISPLAY_NAME 
          : chartIndicator[0].sort.DISPLAY_NAME.includes("(per 1,000 residents)")
            ? "(per 1,000 residents)"
            : ""
        : ""}</div></div>
      {/*>{chartIndicator[0].sort.DISPLAY_NAME.split("(")[0]}</div>*/}

      {/*<div
        style={{
          fontSize: "12px",
          lineHeight: "28px",
          paddingLeft:"5px",
          color: "#5a5a5a",
        }}
      >{` (${chartIndicator[0].sort.Module.split("(")[1]}`}</div>*/}
      {chartIndicator[0].sort.Sort.Icon === "custom" 
      ?  <div
            style={{
              width: "40px",
              display: 'flex',
              justifyContent: 'flex-end',
              cursor:'pointer',
            }}
            onClick={() => handleRemoveModule(chartIndicator[0].sort.moduleId)}
          >
            <SvgIcon
                svgName={"x"}
                color={null}
              />
          </div>
      : null}
    </div>
  ), [/*allData,*/ chartIndicator, activeIndicator, /*activeGeography,*/ bivariateIndicator]);

  const memoizedCategoryHeader = useMemo(() => (
    //console.log("I112624 chartIndicator", chartIndicator),
    //console.log("H102824 chartIndicator[0]", chartIndicator[0]),
    //console.log("H102824 chartIndicator[0].sort", chartIndicator[0].sort),
    //console.log("H102824 chartIndicator[0].sort.Sort.Icon", chartIndicator[0].sort.Sort.Icon),
    <div 
    key={`chart-card-cat-header-${index}`}
    style={{
      height:'42px',
      width: '100%',
      borderBottom: '1px solid #ccc',
      overflowY: 'hidden',
      overflowX: 'hidden',
      marginBottom: '10px',
      marginTop: '10px',
      display: 'flex',
      fontSize: "24px",
      color: "#FFFFFF",
      textTransform:'uppercase',
      alignItems: 'center', // Align text vertically center
      justifyContent: 'center', // Align text horizontally center
      //backgroundColor: Colors.getColorByClass(chartIndicator[0].sort.Sort.Color), // We dont want the color of the module here, whant it to correlate with the category class for color
      backgroundColor: Colors.getColorByClass(chartIndicator[0].category),
      fontWeight: "bold",
      //textDecoration: "underline",
    }}
    >
      {chartIndicator[0].category}
    </div>
  ), [/*allData,*/ chartIndicator, activeIndicator, /*activeGeography,*/ bivariateIndicator]);

  /*const memoizedStaticImage = useMemo(() => (
    console.log("J120824 chartIndicator", chartIndicator),
    console.log("J120824 options", options),
    <img
      src={require(`../../assets/dashboards/${options.category.replace(" + ","_" ).replace(" ", "_")}.png`)}
      alt="Dashboard Core Stats"
      style={{ width: '100%', height: 'auto' }}
    />
  ), [chartIndicator, activeIndicator, bivariateIndicator]);*/

  //console.log("B102824 options", options.type.toLowerCase());
  /*console.log("A071024 options", options.variables[0].sort.provenance);*/
  //console.log("A010525 index", index);
  //console.log("A010525 context", context);
  //console.log("A010525 column", column);
  //console.log("A010525 chartIndicator", chartIndicator);
  return (
    <div
      className="chart-card-container"
      key={`chart-card-${index}-${chartIndicator[0].variable}`}
      ref={ref}
      style={{
        height: "100%", //'200px',
        //paddingTop: '20px',
        //borderTop: '1px solid #ccc',
        backgroundColor: "white", 
        overflowY: "hidden", //"scroll", 
        overflowX: "hidden", 
        //marginRight: "32px",
        paddingRight: context === "dashboards" 
          ? column === 2 ? "24px" : "32px" 
          : "32px",
        marginLeft: context === "dashboards" 
          ? column === 1 ? "32px" : "0px" 
          : "32px",
        width: context === "data stories" ? '70%' : 'unset', //'100%',
        position: context === "data stories" ? 'absolute' : 'unset',
        left: context === "data stories" ? '30%' : 'unset',
      }}>
       {/* <div
          style={{
            fontSize: "10px",
            lineHeight: "19px",
            color: "gray",
            backgroundColor: "white",
            position:"absolute",
            top: "1px",
            height:"19px",
            borderBottom: "1px solid #e8e4e4",
            width: "100%",
            zIndex: "1",
          }}
        >{chartIndicatorName}</div>*/}
      {/* options.variables[0].sort.provenance && 
      (() => {
          switch (options.type.toLowerCase()) {
            case 'histogram':
              return memoizedButtons;
            case 'beeswarm':
              return memoizedButtons;
            default:
              return null;
          }
        })()
      */}
      { options.type.toLowerCase() === 'category header' //|| activePage === "dashboard" 
        ? null 
        : options.type.toLowerCase() === 'add module' 
          ? memoizedAddModule
          : memoizedHeader 
      }
      { /*activePage === "dashboard" 
      ? (() => {
        switch (options.type.toLowerCase()) {
          case 'category header':
            return memoizedStaticImage;
          default:
            return null;
        }
      })() 
      : */(() => {
          switch (options.type.toLowerCase()) {
            case 'category header':
              return memoizedCategoryHeader;
            case 'histogram':
              return memoizedHistogram;
            case 'beeswarm':
              return memoizedSwarm;
            case 'pie':
              return memoizedPie;
            case 'waffle':
              if (activePage === "dashboard") {
                return memoizedDBWaffle;
              }else{
                return memoizedWaffle;
              }
            //case 'list':
            //  return memoizedList;
            case 'ranked list':
              return memoizedList;
            case 'pyramid':
              return memoizedPyramid;
            case 'stacked histograms':
              return memoizedStackedHistograms;
              //return memoizedStackedSwarms;
            case 'stacked swarms':
              return memoizedStackedSwarms;
            case 'stacked stats':
              return memoizedDBStackedStats;
            case 'map':
              return memoizedMap;
            case 'time':
              return null; //memoizedTime;
            case 'deviation from average':
              return memoizedDev;
            case 'stat list':
              return memoizedStatList;
            case 'add module':
              return null;
            case 'ranked stat list':
              return memoizedRankedStatList;
            case 'bar waffle':
              return memoizedBarWaffle;
            case 'one or two col stats':
              return memoizedDBOneOrTwoColStats;
            case 'one or two col stats waffle':
              return memoizedDBOneOrTwoColStatsWaffle;
            case 'deviation from average inline':
              return memoizedDevInline;
            case 'bar':
              return memoizedBar;
            case 'count only':
              return memoizedCountOnly;
            case 'count':
              return memoizedCount;
            default:
              return memoizedMiscellaneous;
          }
        })()}
    </div>
  )
};
