import React, { useState, useEffect, useRef } from 'react';
import {
  useGlobalSelectedId,
  onSetActiveIndicator,
  onSetActiveDBModuleId,
  onSetActiveModuleId,
  useGlobalActivePage,
  useGlobalActivePointStatus, 
  onSetActivePointStatus,
  useGlobalActiveGeography,
  useGlobalSelectedPoint,
  onSetSelectedPoint, 
  onSetSelectedPointFeature, 
} from '../../data/StatusStore';
import { d2gRound, parseActivePointStatus, cleanString } from '../utilities/Utilities';
import Colors from '../utilities/Colors';
import CountMeta from './CountMeta';
import { useData, FlatFileCsvData } from '../../data/DataContext';
import { active } from 'd3';
import * as turf from 'turf';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';

export interface CountChartProps {
  options: any;
  data: any;
  width: number;
  height: number;
  colorClass: string;
}

const MARGIN_X = 0;
const MARGIN_Y = 15;

const Count: React.FC<CountChartProps> = ({ options, data, width, height, colorClass }) => {
  const [preppedData, setPreppedData] = useState<any>([]);
  const [preppedLabelData, setPreppedLabelData] = useState<any>({});
  const [hoveredIndicator, setHoveredIndicator] = useState<any>(null);
  //const [selectedPoint, setSelectedPoint] = useState<any>(null);
  const [parsedActivePointStatus, setParsedActivePointStatus] = useState<any>(null);
  const [tooltip, setTooltip] = useState<{ x: number; y: number; obj: any } | null>(null); // Tooltip state
  const [listHeight, setListHeight] = useState<number>(27.5); // Set a fixed height for each list
  const [globalMaxValue, setGlobalMaxValue] = useState<number>(0);
  const [toggledItemIncrement, setToggledItemIncrement] = useState<number>(0);
  const [toggledItems, setToggledItems] = useState<{ [key: string]: any }>({}); // State for toggles
  const selectedId = useGlobalSelectedId();
  const activePage = useGlobalActivePage();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState<{ width: number; height: number }>({ width, height });
  const { dataPoint, isLoadingPoint, setDataPoint, dataSorter, dataSorterOrder, pointModules, pointMeta, geoJsonDataPUMA, geoJsonDataTract, geoJsonDataNTA, geoJsonDataBoro, isLoadingTopology, error } = useData();
  const activePointStatus = useGlobalActivePointStatus();
  const activeGeography = useGlobalActiveGeography();
  const selectedPoint = useGlobalSelectedPoint();

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        if (!entries || entries.length === 0) return;
        const { width, height } = entries[0].contentRect;
        setDimensions({ width, height });
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        if (containerRef.current) {
          resizeObserver.unobserve(containerRef.current);
        }
      };
    }
  }, []);

  useEffect(() => {
    if (selectedId) {
      prepData();
    }
  }, [selectedId]);
  console.log("JJ032925 Count > toggledItems",  toggledItems);
  useEffect(() => {
    console.log("F032825 Count > toggledItems",  toggledItems);
  }, [toggledItems, toggledItemIncrement]);

  useEffect(() => {
    console.log("F032825 Count > dataPoint",  dataPoint);
    if (dataPoint) {
      const allInactive = Object.values(dataPoint).every((item: any) => item.active === false);
      console.log("All dataPoint items inactive:", allInactive);
      if (allInactive) {
        let _toggledItems = toggledItems;
        let _toggledItemsToUpdate = false;
        Object.keys(_toggledItems).forEach(key => {
          if (_toggledItems[key].active) {
            console.log(`Active item found: ${key}`);
            _toggledItems[key].active = false;
            _toggledItemsToUpdate = true;
          }
        });
        if (_toggledItemsToUpdate){
          setToggledItems(_toggledItems);
          setToggledItemIncrement(toggledItemIncrement + 1);
        }
      }
    }
  }, [dataPoint]);

  useEffect(() => {
    //console.log("CC121624 Count > preppedData, parsedActivePointStatus", preppedData, parsedActivePointStatus);
    let _toggledItems = toggledItems
    console.log("E032825 preppedData", preppedData);
    console.log("E032825 dataPoint", dataPoint);
    console.log("E032825 parsedActivePointStatus", parsedActivePointStatus);
    preppedData.forEach((d: any, i:number) => {
      let cleanKey = cleanString(d.name);
      
      console.log("EEE012825 cleanKey", cleanKey);
      console.log("EEE012825 d", d);
      console.log("EEE012825 pointMeta[d.name]", pointMeta[d.name]);
      if (parsedActivePointStatus && parsedActivePointStatus.hasOwnProperty(cleanKey)) {
       /*console.log("XXX121724 d", d);
        console.log("XXX121724 pointMeta", pointMeta);
        console.log("XXX121724 pointMeta[d.name]", pointMeta[d.name]);
        console.log("XXX121724 _toggledItems", _toggledItems);
        console.log("XXX121724 _toggledItems[d.name]", _toggledItems[d.name]);
        console.log("XXX121724 dataPoint", dataPoint);
        console.log("XXX121724 dataPoint[d.name]", dataPoint[d.name]);*/
        _toggledItems[d.name] = { 
          //..._toggledItems[d.name],
          index: i,
          //color: d.color, 
          color: pointMeta[d.name].META["OLD D2G COLOR"], 
          active: true // dataPoint[d.name].active // 
        };
      }
    });
    //console.log("CC121624 Count > _toggledItems",  _toggledItems);
    setToggledItems(_toggledItems);
    setToggledItemIncrement(toggledItemIncrement + 1);
  }, [preppedData]);

  useEffect(() => {
    if (activePointStatus) {
      setParsedActivePointStatus(parseActivePointStatus(activePointStatus));
    }
  }, [activePointStatus]);

  useEffect(() => {
    //console.log("D010925 Count > parsedActivePointStatus", parsedActivePointStatus);
    //console.log("G121724 Count > toggledItems", toggledItems);
    //console.log("G121724 Count > dataPoint", dataPoint);
    //console.log("D011025 Count > preppedData", preppedData);
    preppedData.forEach((d: any) => {
      //console.log("GG121724 Prepped Data Item:", d);
      //console.log("D011025 d", d);
      if (parsedActivePointStatus && parsedActivePointStatus.hasOwnProperty(cleanString(d.name))) {
        //console.log("D011025 Condition met for:", d.name);
        if (parsedActivePointStatus[cleanString(d.name)][0] !== "na") {
          // THis is a selected point. Make metadata for it.
          
          //console.log("D011025 d.name", d.name);
          //console.log("D011025 dataPoint[d.name]", dataPoint[d.name]);
          
          if (dataPoint[d.name].data.features){
            //this is a polygon
            //console.log("DD011025 dataPoint[d.name].data", dataPoint[d.name].data);

            //console.log("DD011025 parsedActivePointStatus[cleanString(d.name)][0]", parsedActivePointStatus[cleanString(d.name)][0]);
            //console.log("DD011025 parsedActivePointStatus[cleanString(d.name)][1]", parsedActivePointStatus[cleanString(d.name)][1]);

            dataPoint[d.name].data.features.forEach((feature: any) => {
              const centroid = turf.centroid(feature);
              const [centroidLng, centroidLat] = centroid.geometry.coordinates;
              //console.log("DDD011025 centroidLat.toFixed(8)", centroidLat.toFixed(8));
              //console.log("DDD011025 parsedActivePointStatus[cleanString(d.name)][0].toFixed(8)", parsedActivePointStatus[cleanString(d.name)][0].toFixed(8));
              if (
                centroidLat.toFixed(8) === parsedActivePointStatus[cleanString(d.name)][0].toFixed(8) //&&
                //centroidLng.toFixed(8) === parsedActivePointStatus[cleanString(d.name)][1].toFixed(8) 
              ) {
                console.log("E012525 Matching Point:", feature);
                onSetSelectedPoint({
                  pointData: feature.properties,
                  pointTitle: d.name,
                });
                onSetSelectedPointFeature(feature);
              }
            });
          }else{
            const matchingPoint = dataPoint[d.name].data.find((point: any) => {
              return Number(point.latitude) === parsedActivePointStatus[cleanString(d.name)][0] && Number(point.longitude) === parsedActivePointStatus[cleanString(d.name)][1];
            });
            console.log("E012525 Matching Point:", matchingPoint);
            onSetSelectedPoint({
              "pointData": matchingPoint ? matchingPoint : null,
              "pointTitle": d.name
            });
            onSetSelectedPointFeature(matchingPoint);
          }
        }
      }
    });
  }, [parsedActivePointStatus, preppedData]);

  useEffect(() => {
    /*console.log('GG121624 toggledItems', toggledItems);
    console.log('G121624 dataPoint', dataPoint);
    console.log('G121624 options.variables', options.variables);
    console.log("G121624 pointModules", pointModules);
    console.log('F121624 parsedActivePointStatus', parsedActivePointStatus);
    console.log('F121624 dataSorter', dataSorter);
    console.log('F121624 dataSorterOrder', dataSorterOrder);
    console.log('CCC121624 isLoadingPoint', isLoadingPoint);*/
    let _dataPoint = {...dataPoint};
    if (!isLoadingPoint){
      let _activePointStatus = ""; //activePointStatus;
      if (toggledItems && parsedActivePointStatus){
        const toggledItemsList = Object.keys(toggledItems).map(key => cleanString(key));
        console.log('Toggled Items List:', toggledItemsList);
        //let _parsedActivePointStatus = { ...parsedActivePointStatus };
        Object.keys(parsedActivePointStatus).forEach(key => {
          if (!toggledItemsList.includes(key)){
            //console.log("GG121624 key", key);
            

            _activePointStatus += `${_activePointStatus === "" ? "" : "~"}${parsedActivePointStatus[key][0]}*${parsedActivePointStatus[key][1]}*${key}`;
            /*const keyData = options.variables.filter((v: any) => {
              if (v.sort.DISPLAY_NAME === key) {
                return true;
              }
              return false;
            });*/
            const module = Object.keys(pointModules).find(k => cleanString(k) === key);

            //console.log("GG121624 module", module);
            //console.log("GG121624 pointModules[module][Object.keys(pointModules[module])[0]]", module ? pointModules[module][Object.keys(pointModules[module])[0]] : "null");
            const _dataPointFiltered = Object.keys(dataPoint).filter((k: string) => cleanString(k) === key);
            //console.log("FF121624 _dataPointFiltered", _dataPointFiltered);
            const keyData = options.variables.filter((v: any) => {
              if (v.sort.DISPLAY_NAME === key) {
                return true;
              }
              return false;
            });
            let _key = _dataPointFiltered[0];
            console.log("EE012825 _key", _key);
            console.log("EE012825 keyData", keyData);
            console.log("EE012825 options", options);
            console.log("E012825 dataSorter", dataSorter);
            console.log("EE012825 dataSorterOrder", dataSorterOrder);
            console.log("EE012825 key", key);
            console.log("EE012825 parsedActivePointStatus", parsedActivePointStatus);
            console.log("EE012825 toggledItemsList", toggledItemsList);
            console.log("EE012825 toggledItems", toggledItems);
            console.log("D012825 pointMeta", pointMeta);
            console.log("D012825 dataPoint", dataPoint);
            console.log("D012825 pointMeta[_key]", pointMeta[_key]);
            console.log("D012825 pointModules", pointModules);
            console.log("D012825 pointModules[module]", module ? pointModules[module] : "null");
            //let itemColor = module ? Colors.getColorQuintile(0,0,0,pointModules[module][Object.keys(pointModules[module])[0]][0].Sort.Color, 1) : "red";
            _dataPoint[_key] = {
              data: dataPoint[_key].data,
              //meta: pointMeta[_key],
              meta: keyData,
              //meta: module ? pointModules[module] : null,
              active: true, //toggledItems[_key]?.active,
              item: {
                active: true,
                color: pointMeta[_key].META["OLD D2G COLOR"],
                index: 1, //toggledItems[_key],
              }
            }
            //console.log("GG121624 _dataPoint", _dataPoint);
          }
        });
      }
      
      Object.keys(toggledItems).forEach(key => {
        //console.log('D121624 toggledItems', toggledItems);
        //console.log('D121624 key', key);
        //console.log('DDD121624 parsedActivePointStatus', parsedActivePointStatus);
        //console.log('DDD121624 activePointStatus', activePointStatus);
        
        //onSetActivePointStatus(`${point.latitude}*${point.longitude}*${cleanString(key)}`);
        if (parsedActivePointStatus && parsedActivePointStatus.hasOwnProperty(cleanString(key))) {
          if (!toggledItems[key].active){
            console.log('DD121624 REMOVE toggledItems[key]', toggledItems[key]);
          }else{
            _activePointStatus += `${_activePointStatus === "" ? "" : "~"}${parsedActivePointStatus[cleanString(key)][0]}*${parsedActivePointStatus[cleanString(key)][1]}*${cleanString(key)}`;
          }
        }else{
          if (toggledItems[key].active){
            console.log('DD121624 ADD toggledItems[key]', toggledItems[key]);
            let _p = parsedActivePointStatus ? parsedActivePointStatus[cleanString(key)] : null;
            _activePointStatus += `${_activePointStatus === "" ? "" : "~"}${_p ? _p[0] : "na"}*${_p ? _p[1] : "na"}*${cleanString(key)}`;
          }
        }
        const keyData = options.variables.filter((v: any) => {
          if (v.sort.DISPLAY_NAME === key) {
            return true;
          }
          return false;
        });
        //console.log('C121624 activePointStatus', activePointStatus);
        console.log('CC012825 keyData', keyData);
        //console.log('C121624 parsedActivePointStatus', parsedActivePointStatus);
        //console.log('C121624 parseActivePointStatus(activePointStatus)', parseActivePointStatus(activePointStatus));
        //console.log('E121624 dataPoint[key]', dataPoint[key]);
        //console.log('E121624 _activePointStatus', _activePointStatus);
        _dataPoint[key] = {
            data:dataPoint[key].data,
            meta: keyData,
            active: toggledItems[key]?.active,
            item: toggledItems[key],
        }
        //}
      });
      //console.log('EE121624 setDataPoint ----- _dataPoint', _dataPoint);
      setDataPoint(_dataPoint);
      onSetActivePointStatus(_activePointStatus);
      
    }
  }, [toggledItems, toggledItemIncrement]);

  const prepData = () => {
    if (selectedId) {
      const uniqueDisplayNames = new Set();
      const _filteredData = options.variables.filter((v: any) => {
        if (!v.sort.Tooltip_only && !uniqueDisplayNames.has(v.sort.DISPLAY_NAME)) {
          uniqueDisplayNames.add(v.sort.DISPLAY_NAME);
          return true;
        }
        return false;
      });
      const _tooltipData = options.variables.filter((v: any) => v.sort.Tooltip_only);
      const _globalMaxValue = Math.max(
        ...Object.values(data.dataJSON).flatMap((d: any) => {
          return _filteredData.map((v: any) => {
            const value = d[v.variable];
            return isNaN(value) ? 0 : value; // Filter out non-numeric values
          });
        })
      );
      setGlobalMaxValue(_globalMaxValue);

      // Attempting to map each data row to a color. If color property is not defined in the data,
      // you may need to adjust this logic to match your data structure. For demonstration, let's try v.sort.Color.
      let tempGeoJSON = geoJsonDataTract;
      if (activeGeography === "PUMA" || activeGeography === "CD") {
          tempGeoJSON = geoJsonDataPUMA;
      } else if (activeGeography === "Tract") {
          tempGeoJSON = geoJsonDataTract;
      } else if (activeGeography === "NTA") {
          tempGeoJSON = geoJsonDataNTA;
      } else if (activeGeography === "Boro") {
          tempGeoJSON = geoJsonDataBoro;
      }
      //console.log('BB121824 tempGeoJSON', tempGeoJSON);
      //console.log('BB121824 selectedId', selectedId);
      const selectedFeature = tempGeoJSON.features.find((feature: any) => feature.properties.GEOID20.toString() === selectedId.toString() );
      //console.log('BB121824 selectedFeature', selectedFeature);
      const _preppedData = _filteredData.map((v: any, i: number) => {
        const rawValue = data.dataJSON[selectedId][v.variable];
        const isNA = rawValue === undefined || rawValue === null || rawValue === 'NA';
        const value = !isNA && !isNaN(rawValue) ? rawValue : 'N/A';
        const deviation = !isNA && !isNaN(rawValue) ? data.meta.avg[v.variable] - rawValue : 0;
        // Add a color property. If no color is provided in data, fallback to a default color from Colors.
        /*console.log('B121724 v', v);
        console.log('BB121724 v.label', v.label);
        console.log("B121724 pointMeta", pointMeta);
        console.log("B121724 pointMeta[v.label]", pointMeta[v.label]);
        console.log("B121724 toggledItems", toggledItems);
        console.log("B121724 toggledItems[v.label]", toggledItems[v.label]);
        console.log("B121724 dataPoint", dataPoint);
        console.log("BB121724 dataPoint[v.label]", dataPoint[v.label]);*/
        let _pts = dataPoint[v.label];
        let _poly = selectedFeature;
    
        //console.log('B010925 _pts', _pts);
        //console.log('B010925 _poly', _poly);

        let pointsInside = 0;
        if (_pts.data.features){
          //this is a polygon
          let polygon: any;
          if (_poly.geometry.type === 'Polygon') {
            polygon = turf.polygon(_poly.geometry.coordinates);
          } else {
            polygon = turf.multiPolygon(_poly.geometry.coordinates);
          }
          _pts.data.features.forEach((feature: any) => {
            const centroid = turf.centroid(feature);
            const [centroidLng, centroidLat] = centroid.geometry.coordinates;
            if (booleanPointInPolygon(centroid, polygon)) {
              pointsInside++;
            }
          });
        }else{
          if (_pts && _poly) {
            let polygon:any;
            if (_poly.geometry.type === 'Polygon') {
              polygon = turf.polygon(_poly.geometry.coordinates);
            }else{
              polygon = turf.multiPolygon(_poly.geometry.coordinates);
            }
            _pts.data.forEach((point: any) => {
              const _point = turf.point([Number(point.latitude), Number(point.longitude)]);
              if (booleanPointInPolygon(_point, polygon)) {
                pointsInside++;
              }
            });
          }
        }
        //console.log('BB121824 Points inside the polygon:', pointsInside);
        //console.log('B121724 v.sort.Sort', v.sort.Sort);
        //console.log('B121724 v.sort.Sort.Color', v.sort.Sort.Color);
        //console.log('A010425 v.label', v.label);
        //console.log('A010425 pointMeta', pointMeta);
        const _itemColor = pointMeta[v.label].META["OLD D2G COLOR"] ? pointMeta[v.label].META["OLD D2G COLOR"] : "red";
        const itemColor = v.sort.Sort.Color ? Colors.getColorQuintile(0,0,0,v.sort.Sort.Color,i) : "red"; 

        return {
          name: v.sort.DISPLAY_NAME,
          deviation: isNA ? 0 : deviation,
          value,
          variable: v.variable,
          color: _itemColor, // Store the color for the dot
          i:i,
          count: pointsInside
        };
      }).sort((a: any, b: any) => (b.value !== 'N/A' ? b.value : 0) - (a.value !== 'N/A' ? a.value : 0));

      setPreppedData(_preppedData);
    }
  };

  const onClicked = (variable: string, v:any) => {
    //console.log("A020425 v", v);
    //console.log("A020425 variable", variable);
    //console.log("A020425 options", options);

    const hoveredVar = options.variables.find((v: any) => v.variable === variable);
    setHoveredIndicator(hoveredVar);
    if (activePage !== "dashboard") {
      onSetActiveModuleId(options.variables[0].sort.moduleId);
    } else {
      onSetActiveDBModuleId(options.variables[0].sort.moduleId);
    }
    onSetActiveIndicator(variable);
  };

  const toggleItem = (name: string, i:number, color:string) => {
    setToggledItems(prevState => ({
      ...prevState,
      [name]: {
        active: !prevState[name]?.active ? !prevState[name]?.active : false,
        index: i,
        color: color
      } 
    }));
  };

  const fontSize = 12;
  const fontSize2 = 12;
  const labelWidth = 350;

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%', position: 'relative', display: 'flex', alignItems: 'flex-start', paddingRight: '16px' }}>
      {/* Labels on the left */}
      <div style={{
        marginLeft: '0px',
        marginRight: '0px',
        fontSize: fontSize2,
        lineHeight: `${fontSize2 + 2}px`,
        width: `${labelWidth}px`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}>
        {preppedData.map((d: any, i: number) => (
          <div key={`info-${i}`} style={{ fontSize: fontSize2, display: 'flex', alignItems: 'center', height: `${listHeight}px`, marginRight: '15px', cursor: 'pointer' }}
            onClick={() => onClicked(d.variable,d)} // Set hovered indicator on click
          >
            
            {/* Colored dot */}
            <div 
              style={{ 
                minWidth: '12px', 
                height: '12px', 
                borderRadius: '50%', 
                backgroundColor: d.color, 
                marginRight: '8px' 
              }}
            ></div>

           {/* Toggle Switch - stylized checkbox */}
            <label style={{ position: 'relative', display: 'inline-block', minWidth: '34px', height: '16px', marginRight: '8px' }}>
              <input
                type="checkbox"
                checked={!!toggledItems[d.name]?.active}
                onChange={() => toggleItem(d.name, d.i, d.color)}
                style={{ 
                  opacity: 0, 
                  width: 0, 
                  height: 0 
                }}
              />
              <span style={{
                position: 'absolute',
                cursor: 'pointer',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: toggledItems[d.name]?.active ? d.color : '#ccc',
                borderRadius: '20px',
                transition: '.4s'
              }}></span>
              <span style={{
                position: 'absolute',
                top: '2px',
                left: toggledItems[d.name]?.active ? '19px' : '2px',
                width: '12px',
                height: '12px',
                backgroundColor: '#fff',
                borderRadius: '50%',
                boxShadow: '0 0 2px #999',
                transition: '.4s'
              }}></span>
            </label>

            {/* Numbered List */}
            <div style={{ fontWeight: 'bold', marginRight: '15px', minWidth:"20px"}}>{`${d.count}`}</div>

            {/* Name - Clicking the name could call onClicked if needed */}
            <div 
              //onClick={() => onClicked(d.variable)}
              style={{ 
                cursor: 'pointer', 
                fontWeight: 'bold',
                fontSize: '13px',
              }}
            >
              {d.name.split("(")[0]}
                <span style={{ 
                              fontSize:"12px",
                              fontWeight:"normal",
                              color:"#9E9C9C",
                          }}
                >{d.name.split("(")[1] ? `(${d.name.split("(")[1]}` : ""}</span>
            </div>
          </div>
        ))}
      </div>
      <div style={{
        marginLeft: '0px',
        marginRight: '0px',
        fontSize: fontSize2,
        lineHeight: `${fontSize2 + 2}px`,
        width: `${labelWidth}px`,
      }}>
        <CountMeta
          pointData={selectedPoint}
          setPointData={onSetSelectedPoint}
          setPointFeatureData={onSetSelectedPointFeature}
          meta={pointMeta}
        />
      </div>
    </div>
  );
};

export default Count;
