import domtoimage from 'dom-to-image';
//import { onHoverHistogram } from '../../../../data/StatusStore';

export const ExportToImage = async (
  elementIds: string[],
  fileName: string = 'data2gonyc.png',
  excludeElementId: string[] | null = null,
  includeHighlightedArea: boolean = true,
  includeDesc: boolean = true,
) => {
  try {
    const imageArray: HTMLImageElement[] = [];
    if (!includeDesc) {
      /*const descElement = document.getElementById('mainHistogramContext');
      if (descElement) {
        descElement.style.height = '30px';
      }
      const contElement = document.getElementById('mainHistogramContainer');
      if (contElement) {
        contElement.style.height = '130px';
      }
      const contElement2 = document.getElementById('mapHistContainer');
      if (contElement2) {
        contElement2.style.height = 'calc(100% - 89px)';
      }*/
    }
    // Temporarily hide the excluded element if an ID is provided
    let excludedElements: HTMLElement[] = [];
    if (excludeElementId) {
      excludeElementId.forEach((id) => {
      const element = document.getElementById(id);
      if (element) {
        excludedElements.push(element);
        element.style.visibility = 'hidden';
        element.style.height = '0px';
        element.style.opacity = '0';
      }
      });
    }

    if (!includeHighlightedArea) {
      //onHoverHistogram(null);
      const pathElement = document.querySelector('path.leaflet-interactive[stroke="#000"]');
      if (pathElement) {
        pathElement.setAttribute('stroke', '#FFFFFF');
        pathElement.setAttribute('stroke-width', '0.15');
      }
      const pathElementFill = document.querySelector('path.leaflet-interactive[fill="#b0bc22"]');
      console.log("C101624 pathElementFill", pathElementFill)
      if (pathElementFill) {
        pathElementFill.setAttribute('fill', '#FFFFFF');
      }
    }



    // Capture all elements and vector tiles
    for (const id of elementIds) {
      //const element = document.getElementsByClassName("leaflet-container")[0] as HTMLElement;
      const element = document.getElementById(id);
      if (element) {
        const dataUrl = await domtoimage.toPng(element, {
          quality: 1,
          bgcolor: 'white', // Ensure background is white
          //style: {
          //  transform: 'none', // Disable CSS transforms
          //},
        });
        const img = new Image();
        img.src = dataUrl;
        await new Promise((resolve) => (img.onload = resolve));
        imageArray.push(img);
      } else {
        console.warn(`Element with ID ${id} not found.`);
      }
    }

    // Restore the excluded element's visibility
    if (excludeElementId) {
      excludedElements.forEach((element) => {
        element.style.visibility = 'visible';
        element.style.height = 'unset';
        element.style.opacity = '1';
      });
    }
    if (!includeHighlightedArea) {
      if (!includeHighlightedArea) {
        const pathElement = document.querySelector('path.leaflet-interactive[stroke="#FFFFFF"]');
        if (pathElement) {
          pathElement.setAttribute('stroke', '#000');
          pathElement.setAttribute('stroke-width', '2');
        }
      }
    }
    if (!includeDesc) {
      /*const descElement = document.getElementById('mainHistogramContext');
      if (descElement) {
        descElement.style.height = 'calc(100% - 135px)';
      }
      const contElement = document.getElementById('mainHistogramContainer');
      if (contElement) {
        contElement.style.height = '350px';
      }
      const contElement2 = document.getElementById('mapHistContainer');
      if (contElement2) {
        contElement2.style.height = '100%';
      }*/
    }

    if (imageArray.length > 0) {
      const combinedCanvas = document.createElement('canvas');
      const ctx = combinedCanvas.getContext('2d')!;
      const totalWidth = Math.max(...imageArray.map((img) => img.width));
      const totalHeight = imageArray.reduce((sum, img) => sum + img.height, 0);

      combinedCanvas.width = totalWidth;
      combinedCanvas.height = totalHeight;

      let offsetY = 0;
      for (const img of imageArray) {
        ctx.drawImage(img, 0, offsetY);
        offsetY += img.height;
      }

      const link = document.createElement('a');
      link.download = fileName;
      link.href = combinedCanvas.toDataURL('image/png');
      link.click();
    }
  } catch (error) {
    console.error('Failed to export image:', error);
  }
};
