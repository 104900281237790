import React from 'react';

interface StoriesBarProps {
  storySelected: string;
  setStorySelected: (story: string) => void;
}

const StoriesBar: React.FC<StoriesBarProps> = ({ storySelected, setStorySelected }) => {
  const handleClick = (buttonName: string) => {
    console.log(`${buttonName} clicked`);
    setStorySelected(buttonName);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', background: '#EFEFEF', height: '35px', padding: '0 10px' }}>
      <div
        style={{
          backgroundColor: storySelected === 'Well-Being in NYC' ? 'black' : 'transparent',
          color: storySelected === 'Well-Being in NYC' ? 'white' : 'black',
          fontWeight: 'bold',
          padding: '5px 10px',
          cursor: 'pointer',
          textDecoration: 'underline',
        }}
        onClick={() => handleClick('Well-Being in NYC')}
      >
        Well-Being in NYC
      </div>
      <div
        style={{
          marginLeft: '20px',
          cursor: 'pointer',
          backgroundColor: storySelected === 'Story 2' ? 'black' : 'transparent',
          color: storySelected === 'Story 2' ? 'white' : 'black',
          fontWeight: 'bold',
          padding: '5px 10px',
        }}
        onClick={() => handleClick('Story 2')}
      >
        Story 2
      </div>
      <div
        style={{
          marginLeft: '20px',
          cursor: 'pointer',
          backgroundColor: storySelected === 'Story 3' ? 'black' : 'transparent',
          color: storySelected === 'Story 3' ? 'white' : 'black',
          fontWeight: 'bold',
          padding: '5px 10px',
        }}
        onClick={() => handleClick('Story 3')}
      >
        Story 3
      </div>
    </div>
  );
};

export default StoriesBar;
